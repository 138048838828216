import Image from 'next/image';

import { RefundGuaranteeText } from '~/components/product/RefundGuaranteeText';

import awardBadge from '~/public/award-badge.svg';

export function OverviewGuarantee({
  calloutType,
  refundableDays,
  isFree,
  useV2TermsFeaturesUI = false,
}: {
  calloutType: string;
  refundableDays: number;
  isFree: boolean;
  useV2TermsFeaturesUI?: boolean;
}) {
  if (!calloutType) return null;

  return (
    <div
      className={`flex-row items-start rounded bg-blue-500/25 py-2 px-4 ${
        useV2TermsFeaturesUI ? 'flex' : 'inline-flex w-fit'
      }`}
    >
      <Image src={awardBadge} width={22} alt="award-badge" />
      <div className="ml-2">
        <RefundGuaranteeText
          calloutType={calloutType}
          refundableDays={refundableDays}
          isFree={isFree}
        />
      </div>
    </div>
  );
}
