import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { useMemo, useState, useEffect } from 'react';
import clsx from 'clsx';
import { useMediaQuery } from 'usehooks-ts';

import { range } from '~/lib/util';
import { IPagination } from './types';
import {
  ELLIPSIS,
  ITEMS_PER_PAGE,
  MAX_ITEMS,
  MAX_ITEMS_MOBILE,
  TOTAL_PAGE_ITEMS,
  TOTAL_PAGE_ITEMS_MOBILE,
} from './constants';

function Pagination({
  currentPage,
  totalItems,
  onChangePage,
  itemsPerPage = ITEMS_PER_PAGE,
  disabled = false,
  isCenter = false,
}: Readonly<IPagination>) {
  const isScreenMobile = useMediaQuery('(max-width: 768px)');
  const [isMobile, setIsMobile] = useState(false);
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // useEffect to update isMobile state, prevent hydration issues
  useEffect(() => {
    setIsMobile(isScreenMobile);
  }, [isScreenMobile]);

  const pages = useMemo(() => {
    const maxItems = isMobile ? MAX_ITEMS_MOBILE : MAX_ITEMS;
    const totalPageItems = isMobile
      ? TOTAL_PAGE_ITEMS_MOBILE
      : TOTAL_PAGE_ITEMS;

    if (totalPages <= maxItems) {
      return range(1, totalPages);
    }

    if (currentPage < totalPageItems) {
      return [...range(1, totalPageItems), ELLIPSIS, totalPages];
    }

    if (currentPage > totalPages - totalPageItems) {
      // Limit the number of visible "cells" to 5 for mobile
      const mobileAdjustment = isMobile ? 1 : 0;

      return [
        1,
        ELLIPSIS,
        ...range(totalPages - totalPageItems + mobileAdjustment, totalPages),
      ];
    }

    if (isMobile) {
      return [1, ELLIPSIS, currentPage, ELLIPSIS, totalPages];
    }

    // from current to left
    const min = currentPage - Math.round(totalPageItems / 2) + 1;
    // from current to right
    const max = currentPage + Math.round(totalPageItems / 2) - 1;

    return [1, ELLIPSIS, ...range(min, max), ELLIPSIS, totalPages];
  }, [currentPage, totalPages, isMobile]);

  function handlePageClick(
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    page: number | string,
  ) {
    event.preventDefault();

    if (disabled || page === ELLIPSIS || page === currentPage) {
      return;
    }

    onChangePage(page as number);
  }

  function getArialLabel(page: number | string) {
    if (page === ELLIPSIS) {
      return 'ellipsis';
    }

    return page === currentPage
      ? `Current page, Page ${page}`
      : `Go to page ${page}`;
  }

  const paginationClass = clsx(
    'isolate inline-flex w-full -space-x-px overflow-auto pb-3',
    isCenter ? 'justify-center' : 'sm:justify-center',
  );

  return (
    <nav className={paginationClass} role="navigation" aria-label="Pagination">
      <a
        href={`?page=${currentPage === 1 ? currentPage : currentPage - 1}`}
        className="relative inline-flex items-center rounded-l-md p-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:hover:bg-transparent"
        aria-label="Go to previous page"
        onClick={(event) =>
          handlePageClick(
            event,
            currentPage === 1 ? currentPage : currentPage - 1,
          )
        }
      >
        <span className="sr-only" data-testid="pagination-previous">
          Previous
        </span>
        <FontAwesomeIcon
          icon={faChevronLeft}
          className="h-5 w-5"
          aria-hidden="true"
        />
      </a>
      {pages.map((page: number | string, index) => (
        <a
          href={`?page=${page}`}
          key={`${page}-${index}`}
          className={clsx(
            'relative inline-flex items-center px-4 py-2 font-semibold',
            page === currentPage
              ? 'z-10 bg-dorado text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-dorado'
              : 'text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0',
          )}
          aria-label={getArialLabel(page)}
          aria-current={page === currentPage ? 'true' : 'false'}
          onClick={(event) => handlePageClick(event, page)}
        >
          {page}
        </a>
      ))}
      <a
        href={`?page=${
          currentPage === totalPages ? totalPages : currentPage + 1
        }`}
        className="relative inline-flex items-center rounded-r-md p-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:hover:bg-transparent"
        aria-label="Go to next page"
        onClick={(event) =>
          handlePageClick(
            event,
            currentPage === totalPages ? totalPages : currentPage + 1,
          )
        }
      >
        <span className="sr-only" data-testid="pagination-next">
          Next
        </span>
        <FontAwesomeIcon
          icon={faChevronRight}
          className="h-5 w-5"
          aria-hidden="true"
        />
      </a>
    </nav>
  );
}

export default Pagination;
