export function ReviewSkeleton(): JSX.Element {
  return (
    <div
      className="grid w-full animate-pulse gap-4"
      data-testid="review-card-skeleton"
    >
      {/* User Info */}
      <div className="flex flex-col gap-y-2">
        {/* User avatar + username */}
        <div className="flex items-center gap-2">
          <div className="h-6 w-6 shrink-0 rounded-full bg-gray-200" />
          <div className="h-4 w-20 bg-gray-200" />
        </div>
        {/* verified purchaser info */}
        <div className="flex items-center justify-start">
          <div className="h-4 w-4 shrink-0 rounded-full bg-gray-200" />
          <div className="ml-1 h-4 w-20 bg-gray-200" />
          <div className="ml-3 h-4 w-7 bg-gray-200" />
        </div>
        {/* Member info */}
        <div className="flex items-center gap-x-2">
          <div className="h-4 w-40 bg-gray-200" />
          <span
            className="h-1.5 w-1.5 rounded-full bg-gravel"
            aria-hidden="true"
          />
          <div className="h-4 w-20 bg-gray-200" />
        </div>
      </div>
      {/* Review info */}
      <div className="flex flex-col gap-y-2">
        {/* Review Rating */}
        <div className="h-6 w-40 bg-gray-200" />
        <div className="flex items-center gap-x-2">
          <div className="h-4 w-10 bg-gray-200" />
          <span
            className="h-1.5 w-1.5 rounded-full bg-gravel"
            aria-hidden="true"
          />
          <div className="h-4 w-10 bg-gray-200" />
        </div>
      </div>

      {/* Review content */}
      <div className="flex flex-1 flex-col gap-y-1">
        <div className="mb-1 h-5 w-20 bg-gray-200" />
        <div className="h-4 w-full bg-gray-200" />
        <div className="h-4 w-full bg-gray-200" />
        <div className="h-4 w-full bg-gray-200" />
      </div>
      {/* Helpful button */}
      <div className="h-7 w-28 bg-gray-200" />
    </div>
  );
}
