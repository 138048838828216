import { memo, useCallback, useMemo, useState } from 'react';
import { QuestionV2 } from '~/lib/discussions/types';
import QuestionCard from './card/QuestionCard';
import QuestionSkeleton from './card/QuestionSkeleton';
import { useExperiment } from '~/lib/experiment';
import { DISCUSSIONS_SHOW_ALL_REPLIES_EXPERIMENT_NAME } from '~/lib/util/constants';

function QuestionList({
  isModerator,
  questions,
  showAllReplies = false,
}: Readonly<{
  isModerator: boolean;
  questions: Array<QuestionV2>;
  showAllReplies?: boolean;
}>): JSX.Element {
  const [deletedQuestions, setDeletedQuestions] = useState<number[]>([]);

  const filteredQuestions = useMemo(() => {
    return questions.filter(
      (question) => !deletedQuestions.includes(question.id),
    );
  }, [deletedQuestions, questions]);

  const onDelete = useCallback(
    (id: number) => {
      setDeletedQuestions([...deletedQuestions, id]);
    },
    [deletedQuestions],
  );

  const { variant: showAllVariant } = useExperiment(
    DISCUSSIONS_SHOW_ALL_REPLIES_EXPERIMENT_NAME,
  );

  if (!questions || questions.length === 0) {
    return (
      <div className="flex flex-col gap-6">
        {Array.from({ length: 5 }).map((_, index) => (
          <QuestionSkeleton key={index} />
        ))}
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-6">
      {filteredQuestions.map((question) => (
        <QuestionCard
          key={question.id}
          question={question}
          showCollapseIcon={isModerator || showAllVariant === 'enabled'}
          expandCard={showAllReplies}
          onDelete={onDelete}
        />
      ))}
    </div>
  );
}

export default memo(QuestionList);
