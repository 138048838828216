import { Form, FormProps, Link } from '@appsumo/dorado-react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import { memo, useCallback, useEffect, useRef } from 'react';
import { FieldValues } from 'react-hook-form';
import * as yup from 'yup';

import { useEventDispatch } from '~/lib/events';

const QuerySchema = yup
  .object({
    query: yup.string(),
  })
  .required();

interface Query extends yup.InferType<typeof QuerySchema> {}

interface DealCommentSearchProps {
  query?: string;
  onChange: (query: string) => void;
  label?: string;
  placeholder?: string;
  enableParamsState?: boolean;
}

export const DealCommentSearch = memo(function DealCommentSearch({
  query,
  onChange,
  label,
  placeholder = 'Keyword',
  enableParamsState = false,
}: DealCommentSearchProps) {
  const dispatchEvent = useEventDispatch();
  const formRef = useRef<FormProps<FieldValues>>();

  useEffect(() => {
    if (enableParamsState) {
      const url = new URL(window.location.href);

      if (query) {
        url.searchParams.set('search', query);
      } else {
        url.searchParams.delete('search');
      }

      window.history.replaceState({}, '', url.toString());
    }
  }, [enableParamsState, query]);

  const handleSubmit = useCallback(
    (values: Query) => {
      onChange(values.query ?? '');
    },
    [onChange],
  );

  const handleClear = useCallback(() => {
    if (formRef?.current?.setValue) {
      formRef?.current?.setValue('query', '');
    }
    onChange('');
  }, [onChange]);

  return (
    <div className="flex flex-col gap-y-4">
      <Form
        onSubmit={handleSubmit}
        resolver={yupResolver(QuerySchema)}
        ref={formRef}
      >
        <div
          className="relative"
          onFocus={() => dispatchEvent('pdp:toggleBuyButton', true)}
          onBlur={() => dispatchEvent('pdp:toggleBuyButton', false)}
        >
          <Form.Input
            inputClasses="rounded-full bg-iceberg text-black-pearl outline-none py-2 px-4 border border-grace"
            placeholder={placeholder}
            name="query"
            label={label}
            defaultValue={query}
          />
          <button
            className="absolute right-0 bottom-0 px-4 py-2 text-grace transition-colors hover:text-black"
            type="submit"
          >
            <FontAwesomeIcon icon={faSearch} />
            <span className="sr-only">Search</span>
          </button>
        </div>
      </Form>
      {query && (
        <div className="flex gap-x-2">
          <span className="italic">
            Viewing results for &ldquo;{query}&rdquo;:
          </span>
          <Link href="javascript:void(0)" styled onClick={handleClear}>
            Clear filter
          </Link>
        </div>
      )}
    </div>
  );
});
