import { useInView } from 'react-intersection-observer';
import { DealRating } from '~/components/product';
import { BuyNow } from './BuyNow';
import { CSSProperties, memo, useContext, useMemo } from 'react';
import clsx from 'clsx';

import { GlobalContext } from '~/contexts/global';
import { PDPContext } from '~/lib/product/context';
import { Deal } from '~/types/deal';
import useUser from '~/lib/user';
import ShareModal from '~/components/global/ShareModal';
import { RefundGuaranteeText } from '../RefundGuaranteeText';
import { Heading } from '@appsumo/dorado-react';
import { DealListingType } from '~/components/sku/types';
import { PricingBanner } from '../DealBanner';
import { PricingCardTimer } from './PricingCardTimer';
import { ListingTypeLogo } from '../ListingTypeLogo';
import { UpsellCallout } from '../UpsellCallout';
import { ReportListing } from '../ReportListing';
import { MobileGallery } from './MobileGallery';
import { LTDMessaging } from './LTDMessaging';

export const PricingCard = memo(function PricingCard({
  deal,
  planTypeText,
  showTimer = false,
}: {
  deal: Deal;
  planTypeText: string;
  showTimer?: boolean;
}) {
  const product = deal?.products[0];
  const { story } = product;
  const { ref, inView } = useInView({
    threshold: 0,
  });
  const { isPDPPage } = useContext(PDPContext);
  const {
    campaigns: { activeAutoCampaign },
  } = useContext(GlobalContext);

  const cardBorderColor = useMemo(() => {
    // if deal has bg_color and isn't ending soon
    if (
      deal.banner_details?.bg_color &&
      deal.banner_details?.text !== 'Ending soon'
    ) {
      return `lg:border-t-4 lg:border-t-${deal.banner_details?.bg_color}`;
    }

    return '';
  }, [deal.banner_details?.bg_color, deal.banner_details?.text]);

  const cardStyles: CSSProperties = useMemo(() => {
    if (deal.banner_details?.svg === 'campaign' && activeAutoCampaign) {
      return {
        '--appsumo-banner-top-color': `${activeAutoCampaign.campaign_config?.banner_color}`,
      } as CSSProperties;
    }
    return {};
  }, [deal.banner_details.svg, activeAutoCampaign]);

  const { user } = useUser();

  const showPaddingWithBanner =
    !!deal.banner_details?.text && deal.banner_details.text !== 'Ending soon';

  return (
    <>
      {isPDPPage && <ShareModal deal={deal} className="mb-3 max-lg:hidden" />}
      <div
        ref={ref}
        style={cardStyles}
        // eslint-disable-next-line tailwindcss/no-custom-classname
        className={clsx(
          'lg:rounded lg:border lg:border-gray-200 lg:shadow',
          cardBorderColor,
          !!activeAutoCampaign &&
            (deal.banner_details?.svg === 'campaign' ||
              deal.banner_details?.text === 'Campaign badge') &&
            'lg:appsumo-custom-banner-top',
        )}
      >
        <PricingBanner
          banner={deal.banner_details}
          wrapperClassName="hidden lg:flex"
        />
        <div
          className={clsx('lg:p-6 lg:pb-4', {
            'lg:pt-2': showPaddingWithBanner,
          })}
        >
          <div className="max-lg:hidden">
            {!deal.is_info && (
              <ListingTypeLogo
                type={deal.listing_type as DealListingType}
                className="mb-4"
              />
            )}
            <Heading.H1 className="mb-2 text-2xl">
              {deal.public_name}
            </Heading.H1>
            {!deal.is_info && (
              <>
                {planTypeText === 'Lifetime deal' && !deal?.is_free ? (
                  <LTDMessaging />
                ) : (
                  <div className="mb-3 font-header text-sm font-semibold">
                    {planTypeText}
                  </div>
                )}
                <p className="mb-4">{story.subheader}</p>
              </>
            )}
            {!(deal.is_preview || deal.is_info) && (
              <div className="flex">
                <DealRating
                  averageRating={deal.deal_review?.average_rating}
                  reviewCount={deal.deal_review?.review_count}
                  className="inline-flex"
                  href={`${deal.get_absolute_url}#reviews`}
                />
                <ReportListing deal={deal} user={user} />
              </div>
            )}
          </div>
          {isPDPPage && (
            <div className="mt-2 lg:hidden">
              <MobileGallery deal={deal} />
            </div>
          )}
          <div className="my-0 max-lg:pt-4 md:mt-4">
            <BuyNow deal={deal} inView={inView} isPricingCard />
          </div>
          <UpsellCallout deal={deal} />
        </div>
        {deal.money_back_callout_type && (
          <div className="bg-iceberg px-4 py-6 max-lg:mt-4">
            <RefundGuaranteeText
              calloutType={deal.money_back_callout_type}
              refundableDays={deal.refundable_days}
              isFree={deal.is_free}
            />
          </div>
        )}
        <ReportListing deal={deal} user={user} isMobile />
      </div>
      <PricingCardTimer showTimer={showTimer} />
    </>
  );
});
