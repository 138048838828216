import { fetchJson } from '~/lib/fetch';
import { ITEMS_PER_PAGE_ON_DEAL } from './constants';
import { DEAL_COMMENT_TYPE } from '~/lib/discussions/constants';
import type {
  DiscussionFilter,
  DiscussionSort,
  TacoRatingFilter,
} from './types';

interface SearchRequestParamsV2 {
  query: string;
  page?: number;
  skip_pages?: number;
  items_per_page?: number;
  from?: number;
  enroll?: boolean;
}

interface ListRequestParamsV2 {
  sort: 'date' | 'helpful' | 'rating' | 'latest';
  order: 'asc' | 'desc';
  moderator_filter?: 'unapproved' | 'unresolved' | 'needs_reply';
  rating?: TacoRatingFilter;
  page: number;
  skip_pages?: number;
  items_per_page?: number;
  from?: number;
  enroll?: boolean;
  exclude_ids?: string;
}

interface DiscussionUrlType {
  dealId: number;
  type: DEAL_COMMENT_TYPE.QUESTION | DEAL_COMMENT_TYPE.REVIEW;
  page?: number;
  sort?: DiscussionSort;
  filter?: DiscussionFilter;
  tacoRating?: TacoRatingFilter;
  query?: string;
  paginateOnSearch?: boolean;
  itemsPerPage?: number;
  skipPages?: number;
  enroll?: boolean;
  excludeIds?: string;
}

/**
 * Construct the URL for fetching comments from the discussions API.
 */
export function getDiscussionsUrlV2({
  dealId,
  type,
  excludeIds,
  query,
  page = 1,
  sort = 'date',
  filter = 'all',
  tacoRating = 'all',
  paginateOnSearch = false,
  itemsPerPage = ITEMS_PER_PAGE_ON_DEAL,
  skipPages = 0,
  enroll = false,
}: DiscussionUrlType) {
  if (query) {
    const requestParams: SearchRequestParamsV2 = {
      query,
    };

    if (paginateOnSearch) {
      if (skipPages > 0) {
        requestParams.skip_pages = skipPages;
      } else {
        requestParams.from = (page - 1) * itemsPerPage;
        requestParams.items_per_page = itemsPerPage;
      }
    }

    if (enroll) {
      requestParams.enroll = true;
    }

    const requestString = Object.keys(requestParams)
      .map(
        (key: string) =>
          `${key}=${encodeURIComponent(
            requestParams[key as keyof SearchRequestParamsV2] as string,
          )}`,
      )
      .join('&');
    return `/api/v2/deals/${dealId}/${
      type === 'dealreview' ? 'reviews' : 'questions'
    }/?${requestString}`;
  } else {
    const requestParams: ListRequestParamsV2 = {
      page: page,
      sort: 'date',
      order: 'desc',
    };

    if (excludeIds) {
      requestParams.exclude_ids = excludeIds;
    }

    if (enroll) {
      requestParams.enroll = true;
    }

    if (skipPages > 0) {
      requestParams.skip_pages = skipPages;
    } else {
      requestParams.from = (page - 1) * itemsPerPage;
      requestParams.items_per_page = itemsPerPage;
    }

    switch (sort) {
      case 'latest':
        requestParams['sort'] = 'latest';
        break;

      case 'newest':
        (requestParams['sort'] = 'date'), (requestParams['order'] = 'desc');
        break;

      case 'oldest':
        (requestParams['sort'] = 'date'), (requestParams['order'] = 'asc');
        break;

      case 'helpful':
        requestParams['sort'] = 'helpful';
        break;

      case 'rating:asc':
        (requestParams['sort'] = 'rating'), (requestParams['order'] = 'asc');
        break;

      case 'rating:desc':
        (requestParams['sort'] = 'rating'), (requestParams['order'] = 'desc');
        break;
    }

    switch (filter) {
      case 'unapproved':
        requestParams['moderator_filter'] = 'unapproved';
        break;

      case 'unresolved':
        requestParams['moderator_filter'] = 'unresolved';
        break;

      case 'needs_reply':
        requestParams['moderator_filter'] = 'needs_reply';
        break;
    }

    if (tacoRating !== 'all') {
      requestParams['rating'] = tacoRating;
    }

    const requestString = Object.keys(requestParams)
      .map(
        (key) =>
          `${key}=${encodeURIComponent(
            requestParams[key as keyof ListRequestParamsV2] as string,
          )}`,
      )
      .join('&');

    return `/api/v2/deals/${dealId}/${
      type === 'dealreview' ? 'reviews' : 'questions'
    }/?${requestString}`;
  }
}

/**
 * Fetch comments from the discussions API.
 */
export async function getCommentsV2(url: string) {
  return fetchJson(url).then((data) => {
    return data || {};
  });
}
