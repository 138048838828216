import {
  memo,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FieldValues } from 'react-hook-form';
import Image from 'next/image';
import { useRouter } from 'next/router';
import {
  Dropdown,
  DropdownOptionProps,
  DropdownProps,
  Form,
  Heading,
} from '@appsumo/dorado-react';
import { Transition } from '@headlessui/react';
import { yupResolver } from '@hookform/resolvers/yup';

import { ListingTypeLogo } from '~/components/product/ListingTypeLogo';
import { DealListingType } from '~/components/sku/types';
import { Button } from '~/components/ui';
import { GlobalContext } from '~/contexts/global';
import { COMING_SOON_BANNER_TEXT } from '~/lib/campaigns/constants';
import { useCart } from '~/lib/cart';
import { useEvent, useEventDispatch } from '~/lib/events';
import { currency } from '~/lib/format';
import { PDPContext } from '~/lib/product/context';
import useUser from '~/lib/user';
import { getQueryFromPath } from '~/lib/util';
import {
  ASKSUMO_COUPON,
  GA4FormContentValue,
  WAITLIST_URL,
} from '~/lib/util/constants';
import { useEmailSubscribe } from '~/lib/util/hooks';
import { UseEmailSubscribeProps } from '~/lib/util/hooks/emailSubscribe';
import { emailValidationSchema } from '~/schemas/email';
import { Deal, Plan } from '~/types/deal';
import { PlusShelf } from '~/components/product/PlusShelf';
import QuantityRemaining from './QuantityRemaining';

import asplus from '~/public/as-plus-2.svg';
import clsx from 'clsx';
import { ScreenSize } from '~/constants/global';

const InfoOrInactiveDeal = memo(function InfoOrInactiveDeal({
  deal,
  inactiveIndefinitely,
}: {
  deal: Deal;
  inactiveIndefinitely: boolean;
}) {
  const emailSubscribeProps: UseEmailSubscribeProps = {
    emailLabel: 'waitlist_email',
    url: WAITLIST_URL,
    customFormData: {
      deal_slug: deal.slug,
      content:
        deal.deal_status !== DealListingType.INFO
          ? GA4FormContentValue.signUpPdpPricingCardSoldOut
          : GA4FormContentValue.signUpInfoPdpPricingCardInfo,
    },
    errors: {
      205: 'Email already registered. Please login or use a different email and try again.',
    },
  };

  const {
    hasEmailError,
    isEmailSubmitted,
    isEmailValid,
    onFormChangeEmail,
    submitEmail,
    isProcessing,
  } = useEmailSubscribe(emailSubscribeProps);

  function onSubmit() {
    submitEmail();
  }

  const watch = (data: FieldValues) => {
    onFormChangeEmail(data);
  };

  const emailErrorMessage =
    hasEmailError || 'A valid email address is required.';
  const errorMessage = !isEmailValid || hasEmailError ? emailErrorMessage : '';

  return (
    <div className="mt-4">
      {deal.is_info && (
        /* Info deal */
        <>
          <strong>Want to see {deal.public_name} on AppSumo?</strong> Let us
          know &mdash; and make sure you never miss an amazing deal!
        </>
      )}
      {!deal.is_info && inactiveIndefinitely && (
        /* Inactive deal */
        <>
          <strong>This deal is unavailable (a bummer, we know).</strong>{' '}
          Don&apos;t miss a great deal again — drop your email to get all our
          latest tools!
        </>
      )}
      <div className="my-3">
        {!isEmailSubmitted && (
          <Form
            onSubmit={onSubmit}
            resolver={yupResolver(emailValidationSchema)}
            watch={watch}
          >
            <Form.Input name="email" error={errorMessage} placeholder="Email" />
            <br />
            <Button
              disabled={!isEmailValid || isEmailSubmitted || isProcessing}
              type="submit"
            >
              {deal.is_info ? 'Yes, bring to AppSumo' : 'Stay in the know'}
            </Button>
          </Form>
        )}

        {isEmailSubmitted && (
          <div className="text-dollar">
            <b>You&apos;re in!</b> Thanks for signing up.
          </div>
        )}
      </div>
    </div>
  );
});

const DealPricing = memo(function DealPricing({
  selectedPlan,
}: {
  selectedPlan: any | null;
}) {
  const percentSavings: number | null = useMemo(() => {
    if (!Number(selectedPlan?.price) || !Number(selectedPlan?.original_price))
      return null;

    const percentSavings =
      (1 - selectedPlan.price / selectedPlan.original_price) * 100;
    return Math.round(percentSavings);
  }, [selectedPlan]);

  const percentSavingsText = useMemo(() => {
    return percentSavings ? `-${percentSavings}%` : '';
  }, [percentSavings]);

  const selectedPriceText = useMemo(() => {
    if (!selectedPlan?.price) return '';

    return currency(selectedPlan.price);
  }, [selectedPlan]);

  const formattedOriginalPrice = useMemo(() => {
    if (!selectedPlan?.original_price) return '';

    return currency(selectedPlan.original_price);
  }, [selectedPlan]);

  return (
    <div>
      <div className="flex flex-row items-center justify-center text-2xl font-medium lg:items-start lg:justify-start">
        {percentSavings && (
          <span className="mr-2 text-brick">{percentSavingsText}</span>
        )}
        <span>{selectedPriceText}</span>
      </div>
      {percentSavings && (
        <div className="mb-0 text-center text-sm text-grace line-through lg:text-left">
          {formattedOriginalPrice}
        </div>
      )}
    </div>
  );
});

const formatPlan = (deal: any, plan: any, showPrice: boolean = false) => {
  const planName = deal.use_licensing
    ? plan.public_name
    : `${plan?.codes} Code${plan?.codes > '1' ? 's' : ''}`;
  if (showPrice) {
    return (
      <div>
        <span>
          {planName} : {currency(plan.price)}{' '}
        </span>
        <span className="line-through">{currency(plan.original_price)}</span>
      </div>
    );
  }
  return <>{planName}</>;
};

const DealWithPlans = memo(function DealWithPlans({
  deal,
  selectedPlan,
  onChange,
  showQuantityRemaining,
}: {
  deal: any;
  selectedPlan: any | null;
  onChange: (planId: number) => void;
  showQuantityRemaining: boolean;
}) {
  const { isPDPPage, showViewProductDetails } = useContext(PDPContext);

  // generate list of plans (codes or licensing) for the select box
  const options: DropdownOptionProps[] = useMemo(
    () =>
      deal.plans.map((plan: any) => ({
        value: plan.id,
        label: formatPlan(deal, plan, true),
      })) ?? [],
    [deal],
  );

  const dispatchEvent = useEventDispatch();
  const pricingCardDropdownRef = useRef<DropdownProps>(null);

  // user selected plan from pricing table dropdown, so let's update pricing card selection here
  useEvent('plan:updatePricingCard', (newPlan) => {
    // pricing table options have different text values so we have to do this
    const matchingPlan = options?.find(
      (option: DropdownOptionProps) => option?.value === newPlan?.value,
    );
    if (matchingPlan && !!pricingCardDropdownRef?.current?.setValue) {
      pricingCardDropdownRef.current.setValue(matchingPlan); // update dropdown UI
      onChange(parseInt(String(matchingPlan.value), 10)); // sets planId for pricing
    }
  });

  return (
    <div className="flex flex-col gap-y-1">
      <DealPricing selectedPlan={selectedPlan} />
      {showQuantityRemaining && <QuantityRemaining isMultiTier />}
      <div className="flex items-center justify-between">
        <label htmlFor="plan" className="font-semibold">
          Select a plan
        </label>
        {showViewProductDetails && (
          <button
            className="text-sm text-blue-600 hover:underline"
            onClick={() => {
              dispatchEvent('scroll:pricePlanSection');
            }}
          >
            {isPDPPage ? 'View plan details' : 'View product details'}
          </button>
        )}
      </div>
      <Dropdown
        ref={pricingCardDropdownRef}
        className="w-full"
        options={options}
        defaultValue={{
          value: selectedPlan?.id,
          label: (selectedPlan
            ? formatPlan(deal, selectedPlan)
            : '-- Select a plan --') as string,
        }}
        onClick={(option) => {
          onChange(parseInt(String(option.value), 10));
          dispatchEvent('plan:updatePricingTable', option);
        }}
      />
    </div>
  );
});

const DealOneTimePurchase = memo(function DealOneTimePurchase({
  deal,
  selectedPlan,
  showQuantityRemaining,
}: {
  deal: Deal;
  selectedPlan?: Plan;
  showQuantityRemaining: boolean;
}) {
  // Port over from appsumo-2
  const isSubscription = useMemo(() => {
    return deal.unique_plan_types[0]?.[0] === 'Subscription';
  }, [deal.unique_plan_types]);

  const isOneTimePurchase = useMemo(() => {
    if (selectedPlan?.plan_desc === 'One Time Purchase of') {
      return true;
    }
    return !!deal.default_price;
  }, [selectedPlan, deal.default_price]);

  const purchaseTypeText = useMemo(() => {
    if (isSubscription) {
      return 'Annual subscription of';
    } else if (isOneTimePurchase) {
      return 'One time purchase of';
    }
    return '';
  }, [isSubscription, isOneTimePurchase]);

  if (selectedPlan?.is_free) {
    return showQuantityRemaining ? <QuantityRemaining /> : null;
  }

  return (
    <div className="flex flex-col">
      {purchaseTypeText && <Heading.H6>{purchaseTypeText}</Heading.H6>}
      <DealPricing selectedPlan={selectedPlan} />
      {showQuantityRemaining && <QuantityRemaining />}
    </div>
  );
});

export const BuyNow = memo(function BuyNow({
  deal,
  inView,
  hideAdditionalInformation = false,
  isPricingCard = false,
  hideStickyBuyButtonSize = 'sm',
}: {
  deal: Deal;
  inView: boolean;
  hideAdditionalInformation?: boolean;
  isPricingCard?: boolean;
  hideStickyBuyButtonSize?: ScreenSize;
}) {
  const { showQuantityRemaining, isInactiveIndefinitely } =
    useContext(PDPContext);
  const { addPlusToCart, lineItemAdd } = useCart();
  const { user } = useUser();
  const router = useRouter();
  const [planId, setPlanId] = useState<number | null>(
    deal.default_plan.id ?? deal.plans?.[0]?.id ?? null,
  );
  const [showShelf, setShowShelf] = useState(false);
  const {
    campaigns: { activeAutoCampaign },
  } = useContext(GlobalContext);

  const queryParams = getQueryFromPath(router.asPath);
  const askSumoCoupon =
    queryParams.get('coupon') === ASKSUMO_COUPON &&
    activeAutoCampaign?.campaign_config?.allow_asksumo_discount
      ? ASKSUMO_COUPON
      : null;

  const selectedPlan: Plan | undefined = useMemo(
    () => deal.plans.find((plan: Plan) => plan.id === planId),
    [planId, deal.plans],
  );

  // is the deal early access or last call?
  const isEarlyAccessOrLastCall = useMemo(
    () => deal.buy_button.is_early_access || deal.buy_button.is_last_call,
    [deal.buy_button.is_early_access, deal.buy_button.is_last_call],
  );

  // is the deal coming soon?
  const isComingSoon: boolean = useMemo(() => {
    return (
      !isEarlyAccessOrLastCall &&
      (deal.banner_details?.text === COMING_SOON_BANNER_TEXT ||
        deal.buy_button.is_coming_soon)
    );
  }, [
    deal.banner_details?.text,
    deal.buy_button.is_coming_soon,
    isEarlyAccessOrLastCall,
  ]);
  const hasPlans: boolean = useMemo(
    () => deal.plans.length > 1,
    [deal.plans.length],
  );

  const disabledBuyButton: boolean = useMemo(() => {
    if (deal.buy_button.is_ended || isComingSoon) {
      return true;
    }

    if (hasPlans) {
      return !selectedPlan;
    }

    return false;
  }, [deal.buy_button.is_ended, hasPlans, isComingSoon, selectedPlan]);

  const buyButtonText: string = useMemo(() => {
    if (deal.buy_button.is_draft) {
      return 'Buy now (Draft)';
    }

    if (isComingSoon) {
      return 'Coming soon';
    }

    if (deal.buy_button.is_ended) {
      return 'Sold out';
    }

    if (deal.buy_button.is_get_now) {
      return selectedPlan?.is_free ? 'Get now for FREE' : 'Get now';
    }

    return selectedPlan?.is_free ? 'Get now for FREE' : 'Buy now';
  }, [
    deal.buy_button.is_draft,
    deal.buy_button.is_ended,
    deal.buy_button.is_get_now,
    selectedPlan,
    isComingSoon,
  ]);

  const onBuyButtonClick = useCallback(async () => {
    await lineItemAdd(
      deal.id,
      planId,
      askSumoCoupon,
      deal.use_licensing ? 1 : selectedPlan?.codes,
      false,
    );
  }, [
    deal.id,
    deal.use_licensing,
    planId,
    askSumoCoupon,
    selectedPlan?.codes,
    lineItemAdd,
  ]);

  const addToCartAndNavigateToCart = useCallback(async () => {
    if (disabledBuyButton) return;
    await onBuyButtonClick();
    router.push('/cart');
  }, [disabledBuyButton, onBuyButtonClick, router]);

  useEvent('clicked:bToBuy', () => {
    if (isPricingCard) addToCartAndNavigateToCart(); // prevent race condition from multiple <BuyNow />
  });
  useEvent('pdp:showPlusShelf', () => setShowShelf(true));

  const addPlusAndDealToCart = useCallback(async () => {
    await addPlusToCart();
    await onBuyButtonClick();
  }, [addPlusToCart, onBuyButtonClick]);

  // buy button with the appropriate text if it can be bought
  const buyButton: JSX.Element | null = useMemo(() => {
    if (deal.is_info || isInactiveIndefinitely) {
      return null;
    }

    const plusBuyText = selectedPlan?.is_free
      ? 'Get for FREE with'
      : 'Buy now with';

    if (isEarlyAccessOrLastCall) {
      return user && user.has_plus ? (
        /* Plus user can buy now */
        <Button onClick={() => onBuyButtonClick()}>
          <div className="inline-flex items-center font-header">
            {plusBuyText}
            <div>
              <Image
                src={asplus}
                height={14}
                alt="Buy now with AppSumo Plus"
                className="ml-[3px]"
              />
            </div>
          </div>
        </Button>
      ) : (
        /* Extended access and no plus needs to join */
        <Button
          className="w-full text-center"
          onClick={() => setShowShelf(true)}
        >
          <div className="inline-flex items-center font-header">
            {plusBuyText}
            <div>
              <Image
                src={asplus}
                height={14}
                alt="Join AppSumo Plus to buy now"
                className="ml-[3px]"
              />
            </div>
          </div>
        </Button>
      );
    }

    /* Normal deal state can be bought */
    return (
      <Button
        onClick={() => onBuyButtonClick()}
        className="w-full"
        disabled={disabledBuyButton}
      >
        {buyButtonText}
      </Button>
    );
  }, [
    deal.is_info,
    selectedPlan,
    isEarlyAccessOrLastCall,
    isInactiveIndefinitely,
    user,
    onBuyButtonClick,
    disabledBuyButton,
    buyButtonText,
  ]);

  const selectedPlanPricing = useMemo(() => {
    const price = Number(selectedPlan?.price);
    const originalPrice = Number(selectedPlan?.original_price);
    return (
      <>
        {formatPlan(deal, selectedPlan)}: {currency(price)}{' '}
        {originalPrice > 0 && (
          <span className="text-slate line-through">
            {currency(originalPrice)}
          </span>
        )}
      </>
    );
  }, [deal, selectedPlan]);

  const hideBuyButtonClass = useMemo(() => {
    switch (hideStickyBuyButtonSize) {
      case 'xs':
        return 'xs:hidden';
      case 'sm':
        return 'sm:hidden';
      case 'md':
        return 'md:hidden';
      case 'lg':
        return 'lg:hidden';
      case 'xl':
        return 'xl:hidden';
      case '2xl':
        return '2xl:hidden';
      default:
        return '';
    }
  }, [hideStickyBuyButtonSize]);

  if (deal.is_info || isInactiveIndefinitely) {
    return (
      <InfoOrInactiveDeal
        deal={deal}
        inactiveIndefinitely={!!isInactiveIndefinitely}
      />
    );
  }

  return (
    <>
      <div className="flex flex-col gap-y-2 max-lg:text-center md:gap-y-4">
        {!hideAdditionalInformation && !deal.is_info && (
          <ListingTypeLogo
            type={deal.listing_type as DealListingType}
            className="md:hidden"
          />
        )}
        {(deal.is_info || isInactiveIndefinitely) && (
          <InfoOrInactiveDeal
            deal={deal}
            inactiveIndefinitely={!!isInactiveIndefinitely}
          />
        )}
        {(isComingSoon ||
          (!deal.buy_button.is_ended && !isInactiveIndefinitely)) && (
          <>
            {!hideAdditionalInformation && hasPlans && (
              <DealWithPlans
                deal={deal}
                selectedPlan={selectedPlan}
                onChange={(planId) => setPlanId(planId)}
                showQuantityRemaining={!!showQuantityRemaining}
              />
            )}

            {!hideAdditionalInformation && !hasPlans && (
              <DealOneTimePurchase
                deal={deal}
                selectedPlan={selectedPlan}
                showQuantityRemaining={!!showQuantityRemaining}
              />
            )}

            {buyButton}
          </>
        )}
      </div>
      {/* when user scrolls down show a fixed mobile buy bar */}
      {buyButton && (
        <Transition
          show={!inView}
          enter="transition-opacity ease-in duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-out duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className={clsx(
            'fixed bottom-0 left-0 z-9 flex w-full items-center gap-x-4 bg-white p-4 shadow-inner',
            hideBuyButtonClass,
          )}
        >
          {!selectedPlan?.is_free && (
            <div className="shrink grow text-center font-medium">
              {selectedPlanPricing}
            </div>
          )}
          <div className="grow">
            {isEarlyAccessOrLastCall && !user?.has_plus ? (
              <Button
                onClick={() => setShowShelf(true)}
                className="block w-full whitespace-nowrap text-center"
              >
                {buyButtonText}
              </Button>
            ) : (
              <Button
                onClick={() => onBuyButtonClick()}
                className="w-full whitespace-nowrap"
                disabled={disabledBuyButton}
              >
                {buyButtonText}
              </Button>
            )}
          </div>
        </Transition>
      )}

      <PlusShelf
        open={showShelf}
        onClose={() => setShowShelf(false)}
        continueWithPlus={() => addPlusAndDealToCart()}
        dealSlug={deal.slug}
        portalSelector="appsumo-portal-root"
      />
    </>
  );
});
