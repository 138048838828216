import { Link } from '@appsumo/dorado-react';
import dayjs from 'dayjs';
import Image, { ImageProps } from 'next/image';
import { memo, useMemo } from 'react';

import { getAvatarProps } from '~/lib/discussions/utils';
import { IDiscussionUserInfoProps } from '~/lib/discussions/types';
import VerifiedPuchasedBadge from '~/public/verified-purchased-badge.png';

function DiscussionUserInfo({
  username,
  avatar,
  isVerified,
  isPlus,
  isFounder,
  commentDate,
  isReply,
  memberSinceDate,
  dealsBought,
  userNamePadding = false,
}: Readonly<IDiscussionUserInfoProps>) {
  const { alt: defaultAlt, ...defaultAvatarProps }: ImageProps = useMemo(
    () => getAvatarProps(avatar, username, 50),
    [avatar, username],
  );
  const { alt: smallAlt, ...smallAvatarProps }: ImageProps = useMemo(
    () => getAvatarProps(avatar, username, 24),
    [avatar, username],
  );

  return (
    <div className="w-full" data-testid="discussion-user-info">
      {isFounder && (
        <div className="mb-2">
          <span className="rounded border-[0.5px] border-bolt p-1 text-xs font-semibold text-bolt">
            Founder
          </span>
        </div>
      )}
      <div className="flex items-center gap-2">
        {!isReply && (
          <Link
            className="hidden shrink-0 lg:flex"
            href={`/profile/${username}/`}
            target="_blank"
          >
            <Image
              className="h-[50px] w-[50px] shrink-0 rounded-full object-cover"
              alt={defaultAlt}
              {...defaultAvatarProps}
            />
          </Link>
        )}
        {/* Truncate username needs an specific width to work,
         so we set the parent to be full width minus the avatar image width */}
        <div className="w-[calc(100%_-_50px)] space-y-0.5">
          <div className={`flex items-center ${userNamePadding ? 'pr-5' : ''}`}>
            <Link
              href={`/profile/${username}/`}
              target="_blank"
              className="flex truncate font-bold text-black-pearl"
            >
              <Image
                className={`mr-2 flex h-[24px] w-[24px] shrink-0 rounded-full object-cover${
                  !isReply ? ' lg:hidden' : ''
                }`}
                alt={smallAlt}
                {...smallAvatarProps}
              />
              {username}
            </Link>
            {isPlus && (
              <span
                data-testid="plus-badge"
                className="ml-2 shrink-0 rounded border-[0.5px] border-ready-red p-1 text-xs leading-none tracking-wider text-ready-red"
              >
                PLUS
              </span>
            )}
          </div>
          {(isVerified || commentDate) && (
            <div className="flex flex-col sm:flex-row sm:items-center sm:gap-2">
              {isVerified && (
                <div className="flex shrink-0 items-center gap-1">
                  <Image
                    src={VerifiedPuchasedBadge}
                    alt="Verified Purchaser badge"
                    width={16}
                    height={16}
                  />
                  <p className="text-sm text-grace">Verified purchaser</p>
                </div>
              )}

              {commentDate && (
                <>
                  <span
                    className="h-1.5 w-1.5 shrink-0 rounded-full bg-gravel max-sm:hidden"
                    aria-hidden="true"
                  />
                  <span className="shrink-0 text-sm text-grace">
                    Posted: {dayjs(commentDate).format('MMM D, YYYY')}
                  </span>
                </>
              )}
            </div>
          )}
          {(memberSinceDate || dealsBought) && (
            <div className="mt-2 flex flex-wrap items-center gap-x-2 gap-y-1 text-sm text-grace">
              <span>
                Member since: {dayjs(memberSinceDate).format('MMM YYYY')}
              </span>
              <span
                className="h-1.5 w-1.5 rounded-full bg-gravel"
                aria-hidden="true"
              />
              <span>Deals bought: {dealsBought}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default memo(DiscussionUserInfo);
