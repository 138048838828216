import { Button } from '@appsumo/dorado-react';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { DealCommentsContext } from '~/contexts/deal-comments';
import { useUserStatus } from '~/hooks/userStatus';
import {
  DEAL_COMMENT_TYPE,
  DISCUSSION,
  DISCUSSION_SECTION_ID,
  DISCUSSION_TACO_RATING_CHANGE_EVENT_NAME,
  filterOptions,
  ITEMS_PER_PAGE_ON_AGGREGATE,
  ITEMS_PER_PAGE_ON_DEAL,
  TACO_RATING_FILTERS,
} from '~/lib/discussions/constants';
import { getCommentsV2, getDiscussionsUrlV2 } from '~/lib/discussions/fetch';
import {
  CommentV2,
  DiscussionComment,
  DiscussionFilter,
  DiscussionSort,
  DiscussionSortOption,
  QuestionV2,
  ReviewV2,
  TacoRatingFilter,
} from '~/lib/discussions/types';
import useDiscussionInfinitePagination from '~/lib/discussions/useDiscussionInfinitePagination';
import { useEvent } from '~/lib/events';
import { PDPContext } from '~/lib/product/context';
import useUser from '~/lib/user';
import { getHashFromPath, getQueryFromPath } from '~/lib/util';
import { isAggregatePage, isPDP } from '~/lib/util/constants';

import QuestionList from '~/components/discussions/QuestionList';
import { ReviewList } from '~/components/discussions/ReviewList';
import Pagination from '~/components/ui/Pagination';
import CommentFilterPaginationInfo from './Comments/CommentFilterPaginationInfo';
import CommentFilters from './Comments/CommentFilters';
import CommentModeratorInfo from './Comments/CommentModeratorInfo';
import CommentUserInformation from './Comments/CommentUserInformation';
import EmptyCommentsCard from './Comments/EmptyCommentsCard';
import MoreCommentsCTA from './Comments/MoreCommentsCTA';

interface DealCommentsProps {
  fallbackData?: DiscussionComment;
  type: DEAL_COMMENT_TYPE;
  sortOptions: DiscussionSortOption[];
  total: number;
  updateTotal: (total: number) => void;
  query?: string;
  enableParamsState?: boolean;
  enableManualPagination?: boolean;
  paginationHook?: Function;
  excludeIds?: string;
  isSkipComments?: boolean;
}

export const DealComments = memo(function DealComments({
  fallbackData,
  type,
  sortOptions,
  updateTotal,
  query,
  enableParamsState = false,
  enableManualPagination = false,
  paginationHook = useDiscussionInfinitePagination,
  excludeIds,
  isSkipComments,
}: Readonly<DealCommentsProps>) {
  /*
   * Internal states
   */
  const [isLoadingInitialData, setIsLoadingInitialData] = useState(true);
  const [filterBy, setFilterBy] = useState<DiscussionFilter>(
    filterOptions[0].value,
  );
  const [sortBy, setSortBy] = useState<DiscussionSort>(sortOptions[0].value);
  const [tacoRating, setTacoRating] = useState<TacoRatingFilter>(
    TACO_RATING_FILTERS[0].value,
  );
  const [showReplies, setShowReplies] = useState(true);

  const prevPageRef = useRef<number>(1);

  /*
   * Hooks
   */
  const { deal, isInactiveIndefinitely } = useContext(PDPContext);
  const { user } = useUser();
  const { userStatus } = useUserStatus(deal.id);
  const { asPath, push } = useRouter();

  const isPDPPage = isPDP(asPath);

  useEvent(
    DISCUSSION_TACO_RATING_CHANGE_EVENT_NAME,
    (rating: TacoRatingFilter) => {
      setTacoRating(rating);

      const el = document.getElementById(DISCUSSION_SECTION_ID);
      const scrollY = el?.offsetTop || 0;

      if (typeof window !== 'undefined') {
        window.scrollTo({ top: scrollY, behavior: 'smooth' });
      }
    },
  );

  // load comments using given hook
  const { data, size, setSize, error, isLoadingMore, isFinishedPaginating } =
    paginationHook({
      dealId: deal.id,
      type,
      sortBy,
      filterBy,
      tacoRating,
      query,
      excludeIds,
      paginateOnSearch: true,
      discussionUrlFn: getDiscussionsUrlV2,
      discussionFetcher: getCommentsV2,
      fallbackData,
    });

  const { total: commentsTotal } = useMemo(
    () => data?.meta || {},
    [data?.meta],
  );

  const isSearching = useMemo(() => !!query, [query]);

  // scroll up when changing page
  const comments: CommentV2[] = useMemo(() => {
    if (
      enableManualPagination &&
      typeof window !== 'undefined' &&
      prevPageRef.current !== size
    ) {
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        prevPageRef.current = size;
      }, 50);
    }

    return data?.comments || [];
  }, [enableManualPagination, size, data]);

  const isEmptyComments = useMemo(
    () => comments.length === 0 && !isLoadingMore,
    [comments.length, isLoadingMore],
  );

  const allowCreateItem = useMemo(() => {
    // unauthenticated users can't do anything
    if (!user) {
      return false;
    }

    // need to purchase to write a review
    if (type === DEAL_COMMENT_TYPE.REVIEW) {
      return !!userStatus?.user_has_purchased;
    }

    return true;
  }, [type, userStatus, user]);

  const isPartner = useMemo(() => {
    return deal.partner_user_ids.includes(user?.id ?? 0);
  }, [deal.partner_user_ids, user?.id]);

  const disableCreateCommentCTA = useMemo(() => {
    return (
      !allowCreateItem ||
      !user?.email_verified ||
      !!user?.comment_blacklist ||
      (userStatus?.user_has_reviewed && type === DEAL_COMMENT_TYPE.REVIEW)
    );
  }, [
    allowCreateItem,
    user?.email_verified,
    type,
    user?.comment_blacklist,
    userStatus?.user_has_reviewed,
  ]);

  const showFilters = useMemo(
    () =>
      !!commentsTotal ||
      filterBy !== filterOptions[0].value ||
      isLoadingMore ||
      tacoRating !== TACO_RATING_FILTERS[0].value,
    [commentsTotal, filterBy, isLoadingMore, tacoRating],
  );

  const itemsPerPage = useMemo(() => {
    return isAggregatePage(asPath.split('?')[0])
      ? ITEMS_PER_PAGE_ON_AGGREGATE
      : ITEMS_PER_PAGE_ON_DEAL;
  }, [asPath]);

  const showResults = useMemo(() => {
    return isLoadingMore || (!!comments.length && !isLoadingMore);
  }, [isLoadingMore, comments.length]);

  const showScrollPaginationCTA = useMemo(() => {
    return (
      !enableManualPagination &&
      !isLoadingMore &&
      !isSearching &&
      !isFinishedPaginating
    );
  }, [
    enableManualPagination,
    isLoadingMore,
    isSearching,
    isFinishedPaginating,
  ]);

  const showManualPagination = useMemo(() => {
    return (
      enableManualPagination &&
      commentsTotal > itemsPerPage &&
      comments.length > 0
    );
  }, [enableManualPagination, commentsTotal, itemsPerPage, comments.length]);

  const userIsInvalid = useMemo(() => {
    if (!user?.id || (!user?.email_verified && !user?.is_staff)) {
      return true;
    }

    return (
      type === DEAL_COMMENT_TYPE.REVIEW &&
      !userStatus?.user_has_purchased &&
      !(deal.is_info || isInactiveIndefinitely)
    );
  }, [
    deal.is_info,
    isInactiveIndefinitely,
    type,
    user,
    userStatus?.user_has_purchased,
  ]);

  const contextData = useMemo(
    () => ({
      items: comments,
      itemsCount: comments.length,
      commentsTotal,
      size,
      itemsPerPage,
      type,
      enableManualPagination,
      query,
      showReplies,
      toggleShowReplies: () => setShowReplies(!showReplies),
    }),
    [
      comments,
      commentsTotal,
      enableManualPagination,
      itemsPerPage,
      query,
      showReplies,
      size,
      type,
    ],
  );

  const excludeIdsStr = useMemo(() => {
    return isSkipComments ? `${comments.map((c) => c.id).join(',')}` : '';
  }, [comments, isSkipComments]);

  /*
   * Callbacks
   */
  const onCreateDiscussion = useCallback(() => {
    const commentSlug =
      type === DEAL_COMMENT_TYPE.QUESTION
        ? DISCUSSION.QUESTIONS
        : DISCUSSION.REVIEWS;

    push(`/products/${deal.slug}/${commentSlug}/new/`);
  }, [deal.slug, type, push]);

  const onChangePage = useCallback(
    (page: number) => {
      prevPageRef.current = size;
      setSize(page);
    },
    [setSize, size],
  );

  /*
   * useEffect changes
   */
  useEffect(() => {
    if ((isLoadingInitialData && data?.length && !isLoadingMore) || !!error) {
      setIsLoadingInitialData(false);
      return;
    }

    if ((isLoadingInitialData && data?.length && !error) || isEmptyComments) {
      setIsLoadingInitialData(false);
    }
  }, [data, error, isEmptyComments, isLoadingInitialData, isLoadingMore]);

  // update total comments when searching
  useEffect(() => {
    if (isSearching) {
      updateTotal(comments.length);
    }
  }, [comments, isSearching, updateTotal]);

  // redirect if param rating is present
  useEffect(() => {
    const hash = getHashFromPath(asPath);
    const params = getQueryFromPath(asPath);
    const rating = Number(params.get('rating'));

    if (
      type === DEAL_COMMENT_TYPE.REVIEW &&
      hash === DISCUSSION.REVIEWS.toString() &&
      allowCreateItem &&
      rating >= 1 &&
      rating <= 5
    ) {
      push(`/products/${deal.slug}/reviews/new/?rating=${rating}`);
    }
  }, [allowCreateItem, asPath, deal.slug, push, type]);

  // restart pagination when changing filters, sort or query
  useEffect(() => {
    if (enableManualPagination) {
      setSize(1);
    }
  }, [query, enableManualPagination, filterBy, setSize, tacoRating]);

  return (
    <DealCommentsContext.Provider value={contextData}>
      {showFilters && (
        <CommentFilters
          type={type}
          sortOptions={sortOptions}
          sortBy={sortBy}
          filterBy={filterBy}
          tacoRating={tacoRating}
          enableParamsState={enableParamsState}
          onChangeSort={(sort) => setSortBy(sort)}
          onChangeFilter={(filter) => setFilterBy(filter)}
          onChangeRating={(rating) => setTacoRating(rating)}
          isLoadingMore={isLoadingMore}
        />
      )}

      {/* Information box based on user info. We display this on the sidebar for aggregate and indexable */}
      {userIsInvalid && (
        <div className={isPDPPage ? '' : 'flex lg:hidden'}>
          <CommentUserInformation type={type} isPDPPage={isPDPPage} />
        </div>
      )}

      {/* Partner & Moderator information */}
      {(isPartner || userStatus?.user_moderator) && (
        <CommentModeratorInfo type={type} />
      )}

      {/* Pagination information */}
      {comments.length > 0 && (
        <div
          className={clsx('text-sm', {
            '-mt-4': !showFilters && (isPartner || userStatus?.staff_moderator),
          })}
        >
          <CommentFilterPaginationInfo />
        </div>
      )}

      {/* CTA new discussion  */}
      {!userIsInvalid && !isEmptyComments && (
        <div>
          <Button
            onClick={() => onCreateDiscussion()}
            disabled={disableCreateCommentCTA}
            secondary
            className="w-full lg:w-fit"
          >
            {type === 'dealcomment' ? 'Ask a question' : 'Write a review'}
          </Button>
        </div>
      )}

      {/* No results fallback */}
      {isEmptyComments && !isLoadingMore && (
        <EmptyCommentsCard
          type={type}
          onClick={() => onCreateDiscussion()}
          disabledCTA={disableCreateCommentCTA}
          isSearching={isSearching}
        />
      )}

      {/* Results */}
      {showResults && (
        <div className="flex flex-col gap-y-5 sm:gap-y-10">
          {type === DEAL_COMMENT_TYPE.QUESTION && (
            <QuestionList
              isModerator={!!userStatus?.user_moderator}
              questions={isLoadingMore ? [] : (comments as QuestionV2[])}
              showAllReplies={showReplies}
            />
          )}

          {type === DEAL_COMMENT_TYPE.REVIEW && (
            <ReviewList
              isModerator={!!userStatus?.user_moderator}
              reviews={isLoadingMore ? [] : (comments as ReviewV2[])}
              showAllReplies={showReplies}
            />
          )}
        </div>
      )}

      {/* Scroll Pagination  */}
      {showScrollPaginationCTA && (
        <div className="my-5 flex items-center justify-center">
          <MoreCommentsCTA
            commentType={type}
            query={query}
            sortBy={sortBy}
            filterBy={filterBy}
            tacoRating={tacoRating}
            defaultSort={sortOptions[0].value}
            defaultFilter={filterOptions[0].value}
            defaultTacoRating={TACO_RATING_FILTERS[0].value}
            excludeIds={excludeIdsStr}
          />
        </div>
      )}

      {/* Manual pagination  */}
      {showManualPagination && (
        <div className="text-center">
          <Pagination
            itemsPerPage={itemsPerPage}
            currentPage={size}
            onChangePage={onChangePage}
            totalItems={commentsTotal}
            disabled={isLoadingMore}
          />
        </div>
      )}
    </DealCommentsContext.Provider>
  );
});
