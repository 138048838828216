import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import Image from 'next/image';
import { Button } from '@appsumo/dorado-react';

import closeIcon from '~/public/toaster/close.png';

import { currency } from '~/lib/format';
import useUser from '~/lib/user';
import { LOGIN_URL } from '~/lib/util/constants';

import { Shelf } from '~/components/common/Shelf';
import PlusPerksList from '~/components/global/PlusPerksList';

export interface PlusShelfProps {
  open: boolean;
  onClose: () => void;
  continueWithPlus: () => void;
  dealSlug?: string;
  portalSelector?: string;
}

function PlusShelfContent({
  continueWithPlus,
  onClose,
  dealSlug,
  showLogin = false,
}: {
  continueWithPlus: () => void;
  onClose: () => void;
  dealSlug?: string;
  showLogin?: boolean;
}) {
  const plusDealRef = dealSlug ? `plus-page-${dealSlug}` : undefined;

  return (
    <>
      <div className="h-screen bg-gradient-to-b from-[#FFE5E0] via-white p-6">
        <div
          className="flex items-start justify-end gap-8 self-stretch"
          data-testid="plus-shelf-close-button"
        >
          <Image
            src={closeIcon}
            alt="Close"
            height={24}
            width={24}
            className="cursor-pointer"
            onClick={onClose}
          />
        </div>
        <div
          className="mb-6 mt-2 text-center text-2xl font-extrabold"
          data-testid="plus-shelf-title"
        >
          Enjoy member-only perks that will help your business scale faster.
        </div>
        <div className="mb-6">
          <p className="font-semibold">It&apos;s all about the perks</p>
          <PlusPerksList refString={plusDealRef} />
        </div>
        {/* Plus cost */}
        <div className="text-center">
          <span className="text-2xl font-medium" data-testid="plus-shelf-price">
            {currency(99)}
          </span>
          /annual
        </div>
        {/* Join plus button */}
        <Button className="mt-4 w-full" onClick={continueWithPlus}>
          Continue with Plus membership
        </Button>
      </div>
      {showLogin && (
        <div
          className="border-t border-t-gravel py-4 px-2 text-center font-sans"
          data-testid="plus-shelf-login"
        >
          <span className="appsumo-style-links">
            Already have a Plus membership?&nbsp;
            <a href={`${LOGIN_URL}?next=/products/${dealSlug}/`}>Log in here</a>
          </span>
        </div>
      )}
    </>
  );
}

export const PlusShelf = React.memo(
  ({
    open,
    onClose,
    continueWithPlus,
    dealSlug,
    portalSelector,
  }: Readonly<PlusShelfProps>) => {
    const ref = useRef<Element>();
    const [mounted, setMounted] = useState(false);
    const { user } = useUser();

    const selector = portalSelector || 'headlessui-portal-root';

    useEffect(() => {
      ref.current = document.getElementById(selector)!;
      setMounted(true);
    }, [selector]);

    return mounted
      ? createPortal(
          <Shelf open={open} onClose={onClose}>
            <PlusShelfContent
              continueWithPlus={() => {
                continueWithPlus();
                onClose();
              }}
              onClose={onClose}
              dealSlug={dealSlug}
              showLogin={!user}
            />
          </Shelf>,
          ref.current!,
        )
      : null;
  },
);

PlusShelf.displayName = 'PlusShelf';
