import { ReviewCard } from '~/components/discussions/card/ReviewCard';
import { ReviewV2 } from '~/lib/discussions/types';
import { ReviewSkeleton } from './card/ReviewSkeleton';

import { useMemo, useState } from 'react';
import { useExperiment } from '~/lib/experiment';
import { DISCUSSIONS_SHOW_ALL_REPLIES_EXPERIMENT_NAME } from '~/lib/util/constants';

export function ReviewList({
  isModerator,
  reviews,
  showAllReplies = false,
}: Readonly<{
  isModerator: boolean;
  reviews: Array<ReviewV2>;
  showAllReplies?: boolean;
}>): JSX.Element {
  const [deletedReviews, setDeletedReviews] = useState<number[]>([]);

  const filteredReviews = useMemo(() => {
    return reviews.filter((review) => !deletedReviews.includes(review.id));
  }, [deletedReviews, reviews]);

  const { variant: showAllVariant } = useExperiment(
    DISCUSSIONS_SHOW_ALL_REPLIES_EXPERIMENT_NAME,
  );

  if (!reviews || reviews.length === 0) {
    return (
      <div className="flex flex-col gap-6">
        {Array.from({ length: 5 }).map((_, index) => (
          <ReviewSkeleton key={index} />
        ))}
      </div>
    );
  }

  const onDelete = (id: number) => {
    setDeletedReviews([...deletedReviews, id]);
  };

  return (
    <div className="flex flex-col gap-6">
      {filteredReviews.map((review) => (
        <ReviewCard
          key={review.id}
          review={review}
          showCollapseIcon={isModerator || showAllVariant === 'enabled'}
          expandCard={showAllReplies}
          onDelete={onDelete}
        />
      ))}
    </div>
  );
}
