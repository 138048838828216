import { Button } from '@appsumo/dorado-react';
import { memo } from 'react';
import InformationBox from '~/components/ui/InformationBox';

function VerifyToComment({
  actionText,
  isPDPPage,
}: Readonly<{ actionText: string; isPDPPage?: boolean }>) {
  return (
    <InformationBox
      text={`Verify your email to ${actionText}`}
      helpText="Email verification helps us maintain a trustworthy review system."
      className={!isPDPPage ? 'w-full' : 'w-fit'}
    >
      <Button href="/account/email/" tertiary>
        Verify
      </Button>
    </InformationBox>
  );
}

export default memo(VerifyToComment);
