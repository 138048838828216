import useSWR, { KeyedMutator } from 'swr';
import { UserDiscussionStatus } from '~/types/user';

type UserStatusResponse = {
  userStatus: UserDiscussionStatus;
  mutateUserStatus: KeyedMutator<any>;
};

export function useUserStatus(dealId: number): UserStatusResponse {
  const { data: userStatus, mutate: mutateUserStatus } = useSWR(
    `/api/v2/deals/${dealId}/user_status/`,
  );

  return { userStatus, mutateUserStatus };
}
