import { faCheckCircle, faReply } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRouter } from 'next/router';
import { memo, useCallback, useContext, useMemo, useState } from 'react';
import { useMediaQuery } from 'usehooks-ts';

import ToggleText from '~/components/ui/ToggleText';
import { DealCommentsContext } from '~/contexts/deal-comments';
import { useUserStatus } from '~/hooks/userStatus';
import {
  DISCUSSION_TOGGLE_TEXT_MAX_LENGTH,
  MAX_LEVEL_REPLY_ALLOWED,
  QUESTION_EVENT_TYPE_DELETE,
  QUESTION_EVENT_TYPE_REPLY,
  QUESTION_THREAD_ADD_OR_REMOVE_EVENT_NAME,
} from '~/lib/discussions/constants';
import {
  CommentV2,
  DISCUSSION_STATUS_APPROVED,
  DISCUSSION_STATUS_REJECTED,
  DISCUSSION_STATUS_UNMODERATED,
  DiscussionStatus,
  IQuestionCommentProps,
  QuestionV2,
} from '~/lib/discussions/types';
import { mapToQuestionComment } from '~/lib/discussions/utils';
import { useEventDispatch } from '~/lib/events';
import { PDPContext } from '~/lib/product/context';
import useUser from '~/lib/user';
import { isIndexablePage } from '~/lib/util/constants';
import { CommentV2Type } from '~/static/discussions/enums';
import { Deal } from '~/types/deal';
import { UserObjectProps } from '~/types/user';
import DiscussionActions from './DiscussionActions';
import DiscussionOptions from './DiscussionOptions';
import DiscussionTag from './DiscussionTag';
import DiscussionUserInfo from './DiscussionUserInfo';
import QuestionCommentInput from './QuestionCommentInput';

function QuestionComment({
  id,
  text,
  level = 0,
  status,
  comments = [],
  userInfo,
  isNew = false,
  questionId,
  deletedCallback = () => {},
}: Readonly<IQuestionCommentProps>) {
  const dispatchEvent = useEventDispatch();
  const [showReplyInput, setShowReplyInput] = useState(false);

  const [commentStatus, setCommentStatus] = useState<DiscussionStatus>(status);
  const [commentChildren, setCommentChildren] =
    useState<IQuestionCommentProps[]>(comments);
  const [deletedCommentChildren, setDeletedCommentChildren] = useState<
    number[]
  >([]);

  const { query } = useContext(DealCommentsContext);

  const filteredCommentChildren = useMemo(() => {
    return commentChildren.filter(
      (comment) => !deletedCommentChildren.includes(comment.id),
    );
  }, [commentChildren, deletedCommentChildren]);

  const { user } = useUser();
  const { deal }: { deal: Deal } = useContext(PDPContext);
  const { userStatus } = useUserStatus(deal.id);
  const { asPath } = useRouter();
  const isDesktop = useMediaQuery('(min-width: 768px)');

  const isPartner = useMemo(() => {
    return deal?.partner_user_ids?.includes(user?.id ?? 0);
  }, [deal?.partner_user_ids, user?.id]);

  // only for unmoderated comments and owner, partner and staff
  const showDiscussionTag = useMemo(() => {
    return (
      commentStatus === DISCUSSION_STATUS_UNMODERATED &&
      (user?.id === userInfo.id || userStatus?.staff_moderator || isPartner)
    );
  }, [
    isPartner,
    commentStatus,
    user?.id,
    userInfo.id,
    userStatus?.staff_moderator,
  ]);

  const showStatusModerator = useMemo(() => {
    // rejected visible for owner, partner and staff
    if (commentStatus === DISCUSSION_STATUS_REJECTED) {
      return (
        userInfo.id === user?.id || isPartner || userStatus?.staff_moderator
      );
    }

    // approved and unmoderators for partner and staff
    // unmoderated comments will display action buttons
    return userStatus?.staff_moderator || isPartner;
  }, [
    isPartner,
    commentStatus,
    user?.id,
    userInfo.id,
    userStatus?.staff_moderator,
  ]);

  const showReplyButton = useMemo(() => {
    return (
      !showReplyInput &&
      user?.is_authenticated &&
      commentStatus !== DISCUSSION_STATUS_REJECTED &&
      level < MAX_LEVEL_REPLY_ALLOWED
    );
  }, [commentStatus, level, showReplyInput, user?.is_authenticated]);

  const addReply = useCallback(
    (comment: QuestionV2) => {
      const mappedComment = mapToQuestionComment(
        {
          ...comment,
          status:
            isPartner || !!userStatus?.staff_moderator
              ? DISCUSSION_STATUS_APPROVED
              : undefined,
        } as QuestionV2,
        questionId,
        user as UserObjectProps,
        deal?.partner_user_ids ?? [],
        true, // is new comment
      );

      setCommentChildren([...comments, mappedComment]);
      dispatchEvent(QUESTION_THREAD_ADD_OR_REMOVE_EVENT_NAME, {
        type: QUESTION_EVENT_TYPE_REPLY,
        questionId,
      });
    },
    [
      comments,
      deal?.partner_user_ids,
      dispatchEvent,
      isPartner,
      questionId,
      user,
      userStatus?.staff_moderator,
    ],
  );

  const onDeleted = useCallback(
    (id: number) => {
      setDeletedCommentChildren([...deletedCommentChildren, id]);

      dispatchEvent(QUESTION_THREAD_ADD_OR_REMOVE_EVENT_NAME, {
        type: QUESTION_EVENT_TYPE_DELETE,
        questionId,
      });
    },
    [deletedCommentChildren, dispatchEvent, questionId],
  );

  const onChangeStatus = useCallback((status: DiscussionStatus) => {
    setCommentStatus(status);
  }, []);

  return (
    <div
      id={id.toString()}
      data-testid="question-comment"
      className="space-y-4"
    >
      {isNew && !isPartner && !userStatus?.staff_moderator && (
        <div
          data-testid="comment-success-message"
          className="flex gap-2 rounded-sm bg-money-moss p-4 px-2 text-sm text-white"
        >
          <FontAwesomeIcon icon={faCheckCircle} className="pt-1 text-lg" />
          <p>
            <strong>Thank you for submitting!</strong> To keep our community as
            helpful as possible, we moderate all comments and reviews.
          </p>
        </div>
      )}

      <div className="space-y-2 border-l-2 border-sundog pl-3">
        {showDiscussionTag && (
          <DiscussionTag
            isOwner={user?.id === userInfo.id}
            status={commentStatus?.toLowerCase() as DiscussionStatus}
          />
        )}

        <div className="flex gap-4">
          <DiscussionUserInfo {...userInfo} isReply={level > 0} />

          {user?.id === userInfo.id && (
            <div className="shrink-0">
              <DiscussionOptions
                deleteUrl={`/api/v2/deals/${deal.id}/questions/${id}/`}
                discussionType={
                  level === MAX_LEVEL_REPLY_ALLOWED
                    ? CommentV2Type.Reply
                    : CommentV2Type.Comment
                }
                deletedCallback={deletedCallback}
              />
            </div>
          )}
        </div>

        <ToggleText
          initExpanded={isDesktop || isIndexablePage(asPath.split('?')[0])}
          text={text}
          maxLength={
            isDesktop || isIndexablePage(asPath.split('?')[0])
              ? text.length + 1
              : DISCUSSION_TOGGLE_TEXT_MAX_LENGTH
          }
          highlight={query}
        />

        {showReplyButton && (
          <button
            data-testid="reply-button"
            type="button"
            className="text-sm font-medium text-bolt hover:text-bolt-light-20"
            onClick={() => setShowReplyInput(true)}
          >
            <FontAwesomeIcon icon={faReply} className="mr-1" />
            Reply
          </button>
        )}

        {showReplyInput && (
          <QuestionCommentInput
            dealId={deal.id}
            comment={{ id }}
            placeholder="Add a reply..."
            onCancel={() => setShowReplyInput(false)}
            onSuccess={addReply}
            enableAutoFocus
          />
        )}

        {showStatusModerator && (
          <DiscussionActions
            readOnly={
              !userStatus?.staff_moderator &&
              (!isPartner ||
                (isPartner && commentStatus !== DISCUSSION_STATUS_UNMODERATED))
            }
            canUndo={!!userStatus?.staff_moderator}
            canDecline={!!userStatus?.staff_moderator}
            status={commentStatus as DiscussionStatus}
            comment={{ id: id, deal_id: deal.id } as CommentV2}
            onChangeStatus={onChangeStatus}
          />
        )}
      </div>

      {level < MAX_LEVEL_REPLY_ALLOWED &&
        (filteredCommentChildren?.length ?? 0) > 0 && (
          <div className="space-y-4 pl-4">
            {filteredCommentChildren?.map((comment: IQuestionCommentProps) => (
              <QuestionComment
                key={comment.id}
                deletedCallback={() => onDeleted(comment.id)}
                {...comment}
                questionId={questionId}
              />
            ))}
          </div>
        )}
    </div>
  );
}

export default memo(QuestionComment);
