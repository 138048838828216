import { useEffect } from 'react';
import { Transition } from '@headlessui/react';

import { useLayout } from '~/layouts';

export interface ShelfProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

/**
 * Shelf
 * @param open - boolean if the shelf is open
 * @param onClose - function to close the shelf
 * @param children - children to render in the shelf
 * @returns
 */
export function Shelf({ open, onClose, children }: ShelfProps) {
  const { setCanScrollBody } = useLayout();

  useEffect(() => {
    setCanScrollBody(open !== true);
  }, [open, setCanScrollBody]);

  return (
    <Transition show={open}>
      <Transition.Child
        enter="transition-[right] duration-300 ease-in-out"
        enterFrom="-right-[387px]"
        enterTo="right-0"
        leave="transition-[right] duration-300 ease-in-out"
        leaveFrom="right-0"
        leaveTo="-right-[387px]"
        className="fixed inset-y-0 z-50 flex h-screen w-[387px] flex-col overflow-y-scroll bg-white text-black-pearl shadow max-sm:left-10 max-sm:w-full max-sm:pr-10"
      >
        {children}
      </Transition.Child>
      <Transition.Child
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-50"
        leave="transition-opacity duration-75"
        leaveFrom="opacity-50"
        leaveTo="opacity-0"
        className="fixed inset-0 z-[49] h-screen w-screen bg-black"
        onClick={onClose}
      ></Transition.Child>
    </Transition>
  );
}
