import { yupResolver } from '@hookform/resolvers/yup';
import { memo, useRef } from 'react';

import { Button, Form, Link } from '@appsumo/dorado-react';
import { useDiscussionStats } from '~/hooks/useDiscussionStats';
import {
  DEAL_COMMENT_TYPE,
  REPLY_MAX_LENGTH,
} from '~/lib/discussions/constants';
import { CommentAnswerType, CommentV2 } from '~/lib/discussions/types';
import { useEventDispatch } from '~/lib/events';
import { useSubmitReply } from '~/lib/util/hooks/submitReply';
import { YupReplySchema } from '~/types/question-review';

interface SubmitReplyProps {
  comment?: any;
  type: 'dealcomment' | 'dealreview';
  dealId: number;
  answerType: CommentAnswerType;
  mutateComment?: Function;
  onSuccess?: (comment: CommentV2) => void;
}

export default memo(function SubmitReply({
  type,
  comment,
  dealId,
  mutateComment,
  onSuccess,
  answerType,
}: Readonly<SubmitReplyProps>): JSX.Element {
  const dispatchEvent = useEventDispatch();
  const formRef = useRef<HTMLFormElement>(null);
  const { mutateDiscussionStats } = useDiscussionStats(
    comment.deal_id,
    type as DEAL_COMMENT_TYPE,
  );
  const { handleSubmit, isLoading } = useSubmitReply({
    type,
    mutateComment,
    mutateReviewStats: mutateDiscussionStats,
    comment,
    dealId,
    formRef,
    answerType,
    successCallback: onSuccess,
  });

  return (
    <div className="w-full" data-testid="review-submit-reply">
      <Form
        resolver={yupResolver(YupReplySchema)}
        onSubmit={handleSubmit}
        ref={formRef}
      >
        <div
          onFocus={() => dispatchEvent('pdp:toggleBuyButton', true)}
          onBlur={() => dispatchEvent('pdp:toggleBuyButton', false)}
        >
          <Form.TextArea
            // @ts-ignore -- TODO: add this type to dorado-react
            rows={5}
            name="comment"
            label={
              type === DEAL_COMMENT_TYPE.QUESTION ? 'Your answer' : 'Your reply'
            }
            maxLength={REPLY_MAX_LENGTH}
          />
        </div>

        <Button
          type="submit"
          className="my-4 w-full"
          primary
          disabled={isLoading}
        >
          {type === DEAL_COMMENT_TYPE.QUESTION
            ? 'Submit answer'
            : 'Submit reply'}
        </Button>

        <div className="text-sm">
          By submitting, I agree to&nbsp;
          <Link href="/community-guidelines/" styled>
            AppSumo&apos;s Community Guidelines.
          </Link>
        </div>
      </Form>
    </div>
  );
});
