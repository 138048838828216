import {
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { memo, useCallback, useMemo } from 'react';

import StatusAction from '~/components/ui/StatusAction';
import { useDiscussionStats } from '~/hooks/useDiscussionStats';
import { DEAL_COMMENT_TYPE, DISCUSSION } from '~/lib/discussions/constants';
import {
  CommentV2,
  DISCUSSION_STATUS_APPROVED,
  DISCUSSION_STATUS_REJECTED,
  DISCUSSION_STATUS_UNMODERATED,
  DiscussionStatus,
  DiscussionType,
} from '~/lib/discussions/types';
import { useCommentV2 } from '~/lib/discussions/useCommentV2';

interface DiscussionActionsProps {
  status: DiscussionStatus;
  comment: CommentV2;
  mutateComment?: () => void;
  readOnly?: boolean;
  canDecline?: boolean;
  canUndo?: boolean;
  onChangeStatus: (status: DiscussionStatus) => void;
  type?: DiscussionType;
}

function DiscussionActions({
  status,
  comment,
  mutateComment,
  readOnly = false,
  canDecline = false,
  canUndo = false,
  onChangeStatus,
  type,
}: Readonly<DiscussionActionsProps>) {
  const { mutateDiscussionStats } = useDiscussionStats(
    comment.deal_id,
    type === DISCUSSION.QUESTIONS
      ? DEAL_COMMENT_TYPE.QUESTION
      : DEAL_COMMENT_TYPE.REVIEW,
  );
  const { approve, unapprove, deny, undeny } = useCommentV2(
    comment,
    mutateComment ?? (() => {}),
    mutateDiscussionStats,
  );

  const approveLabel = useMemo(() => {
    // We might need to refactor this component and use `user.staff_moderator`
    // instead of `canUndo`. That would require some changes on reviews
    // and comments card components.
    return type === DISCUSSION.QUESTIONS && !canUndo
      ? 'Approve & answer'
      : 'Approve';
  }, [canUndo, type]);

  const onApprove = useCallback(async () => {
    if (await approve()) {
      onChangeStatus(DISCUSSION_STATUS_APPROVED);
    }
  }, [approve, onChangeStatus]);

  const onUnapprove = useCallback(async () => {
    if (await unapprove()) {
      onChangeStatus(DISCUSSION_STATUS_UNMODERATED);
    }
  }, [unapprove, onChangeStatus]);

  const onDeny = useCallback(async () => {
    if (await deny()) {
      onChangeStatus(DISCUSSION_STATUS_REJECTED);
    }
  }, [deny, onChangeStatus]);

  const onUndeny = useCallback(async () => {
    if (await undeny()) {
      onChangeStatus(DISCUSSION_STATUS_UNMODERATED);
    }
  }, [undeny, onChangeStatus]);

  // Display status as readonly mode, without action or undo
  if (readOnly) {
    return (
      <div className="w-full">
        <StatusAction
          label={
            status === DISCUSSION_STATUS_APPROVED ? 'Approved' : 'Declined'
          }
          variant={status === DISCUSSION_STATUS_APPROVED ? 'dollar' : 'brick'}
          faIcon={
            status === DISCUSSION_STATUS_APPROVED
              ? faCheckCircle
              : faTimesCircle
          }
        />
      </div>
    );
  }

  return (
    <div className="flex w-full items-center gap-4">
      {status !== DISCUSSION_STATUS_REJECTED && (
        <div className="grow">
          <StatusAction
            label={
              status === DISCUSSION_STATUS_APPROVED ? 'Approved' : approveLabel
            }
            variant="dollar"
            undoLabel="Undo"
            faIcon={faCheckCircle}
            enableAction={status === DISCUSSION_STATUS_UNMODERATED}
            onActionClick={onApprove}
            enableUndo={status === DISCUSSION_STATUS_APPROVED && canUndo}
            onUndoClick={onUnapprove}
          />
        </div>
      )}
      {status !== DISCUSSION_STATUS_APPROVED && canDecline && (
        <div className="grow">
          <StatusAction
            label={
              status === DISCUSSION_STATUS_REJECTED ? 'Declined' : 'Decline'
            }
            variant="brick"
            undoLabel="Undo"
            faIcon={faTimesCircle}
            enableAction={
              status === DISCUSSION_STATUS_UNMODERATED && canDecline
            }
            onActionClick={onDeny}
            enableUndo={status === DISCUSSION_STATUS_REJECTED && canUndo}
            onUndoClick={onUndeny}
          />
        </div>
      )}
    </div>
  );
}

export default memo(DiscussionActions);
