import { Link } from '@appsumo/dorado-react';
import { memo, useContext, useMemo } from 'react';

import { DEAL_COMMENT_TYPE } from '~/lib/discussions/constants';
import {
  DiscussionFilter,
  DiscussionSort,
  TacoRatingFilter,
} from '~/lib/discussions/types';
import { PDPContext } from '~/lib/product/context';
import { Deal } from '~/types/deal';
import { EXCLUDE_ID_KEY } from '~/constants/discussions';

interface MoreCommentsCTAProps {
  commentType: DEAL_COMMENT_TYPE;
  query?: string;
  sortBy: DiscussionSort;
  filterBy: DiscussionFilter;
  tacoRating: TacoRatingFilter;
  defaultSort: DiscussionSort;
  defaultFilter: DiscussionFilter;
  defaultTacoRating: TacoRatingFilter;
  excludeIds: string;
}

function MoreCommentsCTA({
  commentType,
  query,
  sortBy,
  filterBy,
  tacoRating,
  defaultSort,
  defaultFilter,
  defaultTacoRating,
  excludeIds,
}: Readonly<MoreCommentsCTAProps>) {
  const {
    deal: { slug: dealSlug },
  }: { deal: Deal } = useContext(PDPContext);

  const textType = useMemo(
    () => (commentType === DEAL_COMMENT_TYPE.REVIEW ? 'reviews' : 'questions'),
    [commentType],
  );

  const params = useMemo(() => {
    const params = new URLSearchParams();
    let isExcludeIds = true; // Only set exclude_ids if there is no filtering

    if (query) {
      params.set('query', query);
      isExcludeIds = false;
    }

    // not sending default sort
    if (sortBy !== defaultSort) {
      params.set('sort', sortBy);
      isExcludeIds = false;
    }

    // not sending default filter
    if (filterBy !== defaultFilter) {
      params.set('filter', filterBy);
      isExcludeIds = false;
    }

    // not sending default taco rating
    if (tacoRating !== defaultTacoRating) {
      params.set('taco_rating', tacoRating);
      isExcludeIds = false;
    }

    if (isExcludeIds && excludeIds) {
      params.set(EXCLUDE_ID_KEY, excludeIds);
    }

    params.set('ref', 'pdp');

    return params.toString();
  }, [
    defaultFilter,
    defaultSort,
    filterBy,
    query,
    sortBy,
    excludeIds,
    defaultTacoRating,
    tacoRating,
  ]);

  return (
    <Link
      className="mt-2 rounded-full bg-yellow-400 px-4 py-2 text-xs font-medium"
      href={`/products/${dealSlug}/${textType}?${params}`}
      target="_blank"
    >
      See all {textType}
    </Link>
  );
}

export default memo(MoreCommentsCTA);
