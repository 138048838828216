function QuestionSkeleton(): JSX.Element {
  return (
    <div
      data-testid="questions-card-skeleton"
      className="rounded border border-gravel p-4 shadow-pdp"
    >
      <div
        className="grid w-full animate-pulse gap-4"
        data-testid="review-card-skeleton"
      >
        {/* Tag status */}
        <div className="h-4 w-20 bg-gray-200" />

        <div className="flex flex-col gap-y-2">
          {/* Question */}
          <div className="flex flex-1 flex-col gap-y-1">
            {Array.from({ length: Math.random() * 5 + 1 }).map((_, i) => (
              <div key={i} className="h-4 w-full bg-gray-200" />
            ))}
          </div>

          {/* User info */}
          <div className="flex items-center gap-x-2">
            <div className="h-2 w-10 bg-gray-200" />
            <span
              className="h-1.5 w-1.5 rounded-full bg-gravel"
              aria-hidden="true"
            />
            <div className="h-2 w-10 bg-gray-200" />
          </div>
        </div>

        {/* Comments & Helpful button */}
        <div className="flex justify-between">
          <div className="h-7 w-28 bg-gray-200" />
          <div className="h-7 w-28 bg-gray-200" />
        </div>
      </div>
    </div>
  );
}

export default QuestionSkeleton;
