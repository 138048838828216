import { RefObject, useCallback, useState } from 'react';

import { useUserStatus } from '~/hooks/userStatus';
import { CommentAnswerType } from '~/lib/discussions/types';
import { useEventDispatch } from '~/lib/events';
import { fetchJson } from '~/lib/fetch';
import { QuestionOrReviewSchema } from '~/types/question-review';

interface ReplyModalProps {
  comment?: any;
  type: 'dealcomment' | 'dealreview';
  dealId: number;
  mutateComment?: Function;
  mutateReviewStats?: Function;
  successCallback?: Function;
  formRef?: RefObject<HTMLFormElement>;
  answerType?: CommentAnswerType;
}

export function useSubmitReply({
  type,
  comment,
  dealId,
  mutateComment = () => {},
  mutateReviewStats = () => {},
  successCallback,
  formRef,
  answerType = null,
}: ReplyModalProps) {
  const dispatchEvent = useEventDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { mutateUserStatus } = useUserStatus(dealId);
  const [errors, setErrors] = useState({});

  const handleSubmit = useCallback(
    (values: QuestionOrReviewSchema) => {
      const subPath = type === 'dealcomment' ? 'questions' : 'reviews';
      let url;

      const post: {
        comment: string;
        followup: boolean;
        title?: string;
        rating?: number;
        answer_type?: string;
      } = {
        comment: values.comment,
        followup: !!values.notify,
      };

      if (answerType) {
        post.answer_type = answerType;
      }

      if (values.title) {
        post.title = values.title;
      }

      if (!comment) {
        url = `/api/v2/deals/${dealId}/${subPath}/`;
        if (type === 'dealreview') {
          post.rating = values.rating;
        }
      } else {
        url = `/api/v2/deals/${dealId}/${subPath}/${comment?.id}/reply/`;
      }

      const urlEncodedPost = Object.entries(post)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join('&');

      setIsLoading(true);
      setErrors({});

      fetchJson(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: urlEncodedPost,
      })
        .then(async (data) => {
          await Promise.all([
            mutateComment(),
            mutateReviewStats(),
            mutateUserStatus(),
          ]);

          if (successCallback) {
            successCallback(data);
          }

          setIsLoading(false);
        })
        .catch((err) => {
          err.response
            ?.json()
            .then((data: { [key: string]: Array<string> | String }) => {
              setErrors(data);

              for (const [key, value] of Object.entries(data)) {
                formRef?.current?.setError(key, {
                  message:
                    typeof value === 'string'
                      ? value
                      : value?.[0] || 'Something went wrong',
                });
              }
            });

          setIsLoading(false);
        })
        .finally(() => dispatchEvent('pdp:toggleBuyButton', false));
    },
    [
      type,
      answerType,
      comment,
      dealId,
      mutateComment,
      mutateReviewStats,
      mutateUserStatus,
      successCallback,
      formRef,
      dispatchEvent,
    ],
  );

  return { handleSubmit, isLoading, errors };
}
