import { memo, useContext, useEffect, useState } from 'react';
import { useUserStatus } from '~/hooks/userStatus';
import { DEAL_COMMENT_TYPE } from '~/lib/discussions/constants';
import { PDPContext } from '~/lib/product/context';
import useUser from '~/lib/user';
import BuyToComment from './BuyToComment';
import LoginToComment from './LoginToComment';
import VerifyToComment from './VerifyToComment';

function CommentUserInformation({
  type,
  isPDPPage,
}: Readonly<{ type: DEAL_COMMENT_TYPE; isPDPPage?: boolean }>) {
  const { deal, isInactiveIndefinitely } = useContext(PDPContext);
  const { user } = useUser();
  const { userStatus } = useUserStatus(deal.id);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!isMounted) {
    return null;
  }

  const commentActionText =
    type === DEAL_COMMENT_TYPE.QUESTION ? 'ask a question' : 'leave a review';

  if (!user?.id) {
    return (
      <LoginToComment
        dealSlug={deal.slug}
        actionText={commentActionText}
        type={type}
        isPDPPage={isPDPPage}
      />
    );
  }

  if (!user?.email_verified && !user?.is_staff) {
    return (
      <VerifyToComment actionText={commentActionText} isPDPPage={isPDPPage} />
    );
  }

  if (
    type === DEAL_COMMENT_TYPE.REVIEW &&
    !userStatus?.user_has_purchased &&
    !(deal.is_info || isInactiveIndefinitely)
  ) {
    return <BuyToComment actionText={commentActionText} deal={deal} />;
  }

  return null;
}

export default memo(CommentUserInformation);
