import { memo, useContext, useMemo } from 'react';
import { Countdown } from '~/components/common/Countdown';

import { PDPContext } from '~/lib/product/context';

export const PricingCardTimer = memo(function PricingCardTimer({
  showTimer,
}: {
  showTimer: boolean;
}) {
  const { deal } = useContext(PDPContext);

  const endDate = useMemo(() => {
    if (deal?.dates?.end_date) return new Date(deal?.dates?.end_date);
    return null;
  }, [deal?.dates?.end_date]);

  if (!endDate || !showTimer) return null;

  return (
    <div className="mx-auto mt-2 hidden w-fit rounded-lg bg-brick py-2 px-3 text-center lg:block">
      <span className="text-white">Deal ends in</span>
      <Countdown deadline={endDate} className="text-white" />
    </div>
  );
});
