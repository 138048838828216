import { memo } from 'react';
import { Breadcrumbs, Breadcrumb } from '~/components/ui';

export const DealBreadcrumbs = memo(function DealBreadcrumbs({
  deal,
  className = '',
}: {
  deal: any;
  className?: string;
}) {
  const { attributes } = deal;
  const group = attributes.find((attr: any) => attr.slug === 'group');
  const category = attributes.find((attr: any) => attr.slug === 'category');
  const subcategory = attributes.find(
    (attr: any) => attr.slug === 'subcategory',
  );
  const items: Breadcrumb[] = [];

  if (group) {
    items.push({
      href: `/${group.values[0].slug}/`,
      label: group.values[0].name,
    });

    if (category) {
      items.push({
        href: `/${group.values[0].slug}/${category.values[0].slug}/`,
        label: category.values[0].name,
      });

      if (subcategory) {
        items.push({
          href: `/${group.values[0].slug}/${category.values[0].slug}/${subcategory.values[0].slug}/`,
          label: subcategory.values[0].name,
        });
      }
    }
  }

  items.push({ label: deal.public_name });

  return <Breadcrumbs items={items} truncateAfter={2} className={className} />;
});
