import { memo } from 'react';
import clsx from 'clsx';
import { Link } from '@appsumo/dorado-react';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Deal } from '~/types/deal';
import { UserObjectProps } from '~/types/user';

export const ReportListing = memo(function ReportListing({
  deal,
  user,
  isMobile = false,
}: {
  deal: Deal;
  user?: UserObjectProps;
  isMobile?: boolean;
}) {
  const isReportable = deal.is_marketplace && !deal.is_info && !!user?.email;
  if (!isReportable) return null;

  const containerClasses = isMobile ? 'mt-4 flex lg:hidden' : 'ml-auto';

  return (
    <div className={containerClasses}>
      <Link
        href={`https://report-offer.paperform.co/?deal_id=${deal.id}&deal_slug=${deal.slug}&email=${user?.email}`}
        target="_blank"
        className={clsx('text-sm', { 'mx-auto': isMobile })}
        styled
      >
        Report listing
        <FontAwesomeIcon
          icon={faExternalLinkAlt}
          height="16"
          className="ml-1"
        />
      </Link>
    </div>
  );
});
