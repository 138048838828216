import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowUpFromBracket,
  faCopy,
  faEnvelope,
  faLink,
} from '@fortawesome/free-solid-svg-icons';
import {
  faFacebook,
  faLinkedin,
  faXTwitter,
} from '@fortawesome/free-brands-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Modal, useModal } from '@appsumo/dorado-react';

import { useSocialShare } from '~/hooks/share';
import { ShareType } from '~/lib/util/helpers';
import { Deal } from '~/types/deal';

type SocialIconType = {
  name: string;
  icon: IconProp;
  color?: string;
};

export type UTMParamsConfig = {
  email: string;
  facebook: string;
  linkedin: string;
  twitter: string;
  text: string;
};

export const getSocialButtonConfig = (useCopyIcon: boolean) => {
  return [
    {
      name: 'email',
      icon: faEnvelope as IconProp,
      color: '#2F4B4C',
    },
    {
      name: 'facebook',
      icon: faFacebook as IconProp,
      color: '#4267B2',
    },
    {
      name: 'linkedin',
      icon: faLinkedin as IconProp,
      color: '#0072B1',
    },
    {
      name: 'twitter',
      icon: faXTwitter as IconProp,
      color: '#000000',
    },
    ...(useCopyIcon
      ? [
          {
            name: 'copy',
            icon: faLink as IconProp,
            color: '#2F4B4C',
          },
        ]
      : []),
  ];
};

// Component Functions

function SocialButton({
  index,
  icon,
  openSocialWindow,
  copyToClipboard,
}: {
  index: number;
  icon: SocialIconType;
  openSocialWindow: (socialName: string) => void;
  copyToClipboard: () => void;
}) {
  return (
    <button
      key={icon.name}
      className={`${
        index === 0 ? '' : 'ml-3'
      } rounded-full bg-gray-200 p-3 leading-3`}
      onClick={() => {
        icon.name === 'copy' ? copyToClipboard() : openSocialWindow(icon.name);
      }}
    >
      <FontAwesomeIcon
        className="h-7 w-7"
        icon={icon.icon}
        width="28"
        height="28"
        color={icon.color}
      />
    </button>
  );
}

export function ModalContent({
  shareableLinkWithParams,
  labelText,
  copyToClipboard,
  socialShare,
  useCopyIcon = false,
}: {
  shareableLinkWithParams: string;
  labelText: string;
  copyToClipboard: () => void;
  socialShare: (socialName: string) => void;
  useCopyIcon?: boolean;
}) {
  const socialButtonConfig = getSocialButtonConfig(useCopyIcon);

  return (
    <div className="w-full">
      {socialButtonConfig.map((icon, index) => (
        <SocialButton
          key={icon.name}
          index={index}
          icon={icon}
          openSocialWindow={() => socialShare(icon.name)}
          copyToClipboard={copyToClipboard}
        />
      ))}
      {!useCopyIcon && (
        <div className="relative mt-4 flex w-full grow flex-col">
          <label
            htmlFor="shareable-link"
            className="mb-2 font-header font-semibold text-midnight"
          >
            {labelText}
          </label>
          <input
            type="text"
            name="shareable-link"
            className="rounded-[4px] border border-gravel bg-iceberg py-2 px-4 pr-8 text-midnight outline-none"
            readOnly
            value={shareableLinkWithParams}
          />
          <button
            className="absolute bottom-0 right-0 m-1 w-fit"
            onClick={() => copyToClipboard()}
          >
            <FontAwesomeIcon
              icon={faCopy}
              width="24"
              height="24"
              className="m-1"
            />
          </button>
        </div>
      )}
    </div>
  );
}

export default function ShareModal({
  deal,
  buttonText = 'Share',
  showButtonText = true,
  title = '',
  labelText = 'Or copy link',
  className = '',
}: {
  deal: Deal;
  buttonText?: string;
  showButtonText?: boolean;
  title?: string;
  labelText?: string;
  className?: string;
}) {
  const { shareableLinkWithParams, openSocialWindow, clipboardCopy } =
    useSocialShare(deal.id, deal.slug, deal.public_name, ShareType.PDP);

  const modalTitle = title || deal.public_name;

  const [showModal, hideModal] = useModal(
    () => (
      <Modal
        hideModal={hideModal}
        title={modalTitle}
        className="absolute top-32 w-[500px] max-w-[90%] md:pb-8"
      >
        <ModalContent
          shareableLinkWithParams={shareableLinkWithParams}
          labelText={labelText}
          copyToClipboard={clipboardCopy}
          socialShare={openSocialWindow}
        />
      </Modal>
    ),
    [modalTitle, shareableLinkWithParams, labelText],
  );

  return (
    <div className={`flex items-center justify-end ${className}`}>
      <button className="flex items-center" onClick={() => showModal()}>
        <FontAwesomeIcon
          icon={faArrowUpFromBracket}
          width="16"
          height="16"
          className={showButtonText ? 'mr-2' : ''}
        />
        {showButtonText && buttonText}
      </button>
    </div>
  );
}
