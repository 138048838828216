import Paragraphs from '@render-props/paragraphs';
import { memo, useMemo, useState } from 'react';
import ReactDOMServer from 'react-dom/server';

import { DEFAULT_TOGGLE_TEXT_LENGTH } from '~/constants/ui';
import { firstSentence, highlightText } from '~/lib/util';

export interface IToggleText {
  text: string;
  highlight?: string;
  maxLength?: number;
  expandLabel?: string;
  collapseLabel?: string;
  className?: string;
  initExpanded?: boolean;
}

function ToggleText({
  text,
  highlight,
  className,
  maxLength = DEFAULT_TOGGLE_TEXT_LENGTH,
  expandLabel = 'Read more',
  collapseLabel = 'Read less',
  initExpanded = false,
}: Readonly<IToggleText>): JSX.Element {
  const [isExpanded, setIsExpanded] = useState(initExpanded);

  const truncatedText = useMemo(
    () =>
      firstSentence({
        text,
        minLength: maxLength,
        maxLength,
        showEllipsis: true,
      }),
    [maxLength, text],
  );

  const paragraphs = useMemo(
    () => (
      <Paragraphs text={isExpanded ? text : truncatedText}>
        {({ key, text }: { key: number; text: string }) => (
          <p key={key} className="break-words [word-break:break-word]">
            {text}
          </p>
        )}
      </Paragraphs>
    ),
    [isExpanded, text, truncatedText],
  );

  const htmlText = useMemo(() => {
    if (!highlight) {
      return '';
    }

    // paragraphs is a JSX.Element, that will convert \n to <br />
    return highlightText(ReactDOMServer.renderToString(paragraphs), highlight);
  }, [paragraphs, highlight]);

  return (
    <div className={className}>
      <div data-testid="toggle-text" className={!htmlText ? 'space-y-4' : ''}>
        {htmlText && (
          <div
            className="space-y-4"
            dangerouslySetInnerHTML={{ __html: htmlText }}
          />
        )}

        {!highlight && paragraphs}
      </div>
      {text.length > truncatedText.length && (
        <button
          data-testid="toggle-cta"
          type="button"
          className="text-base font-normal text-bolt hover:underline"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? collapseLabel : expandLabel}
        </button>
      )}
    </div>
  );
}

export default memo(ToggleText);
