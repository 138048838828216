import { Deal } from '~/types/deal';
import { ESBROWSE_URL } from '~/lib/browse/constants';

// ported from appsumo-2
export const getZoneConditions = (deal: Deal) => {
  const conditions = {
    is_soldout: deal.buy_button?.is_ended === true,
    is_private: deal.deal_status === 'Private',
    is_public: deal.deal_status === 'Public',
    is_ended: deal.deal_status === 'Ended',
    is_info: deal.deal_status === 'Info',
    is_legacy: deal.deal_status === 'Legacy',
  };

  if (deal.participation) {
    deal.participation.forEach((participates) => {
      // @ts-ignore
      conditions[`participates_${participates}`] = true;
    });
  }

  const internalProductTags = deal.products?.[0]?.internal_product_tags || [];
  if (internalProductTags) {
    internalProductTags.forEach((tag) => {
      // @ts-ignore
      conditions[`internal_${tag}`] = true;
    });
  }

  return conditions;
};

// ported from appsumo-2
//
// takes an array or object of conditions and turns into a comma-separated string
// ['is_soldout'] => ['is_soldout']
// { is_soldout: true, is_info: false } => ['is_soldout']
export const formatConditions = (conditions: any): string => {
  let ret: string[] = [];

  if (Array.isArray(conditions)) {
    ret = [...conditions];
  } else if (
    conditions &&
    conditions.constructor === Object.prototype.constructor
  ) {
    Object.keys(conditions).forEach((condition) => {
      const v = !!conditions[condition];

      if (v) {
        ret.push(condition);
      }
    });
  }

  return ret.join(',');
};

// ported from appsumo-2
export const generateApiUrl = (collection: any, user: any) => {
  if (!collection) return null;

  const col = collection;
  if (['countdown', 'email_capture', 'explore'].includes(col.component_type)) {
    return null;
  }
  const userId = (col.per_user_cache && user?.id) || 'a';
  const urlParams = new URLSearchParams(col.query_parameters);
  const queryargs = [
    `per_page=${col.component_product_num || 12}`,
    `__cache=${userId}`,
  ];
  if (col.maximum_results) {
    queryargs.push(`maximum_results=${col.maximum_results}`);
  }
  urlParams.forEach((value, key) => {
    if (
      [
        'per_page',
        'status',
        'sort',
        'marketplace',
        'page',
        'query',
        'tags',
        'type',
      ].includes(key)
    ) {
      queryargs.push(`${key}=${value}`);
    } else {
      queryargs.push(`profile{${key}}=${value}`);
    }
  });
  let url = `${ESBROWSE_URL}?${queryargs.join('&')}`;
  if (collection.deal_profile?.slug) {
    url += `&profile=${collection.deal_profile.slug}`;
  }
  return url;
};
