import { memo } from 'react';
import Image, { StaticImageData } from 'next/image';
import { Link } from '@appsumo/dorado-react';
import clsx from 'clsx';

import { Deal } from '~/types/deal';

import UpsellPlusIcon from '~/public/upsell-plus-icon.png';
import UpsellEarningIcon from '~/public/earning-icon.png';
import UpsellExposureIcon from '~/public/exposure-icon.png';
import UpsellSpreadWordIcon from '~/public/spread-word-icon.png';

// These props must match Icon choices in Deals model. See deals/models.py
const UPSELL_ICON: Record<string, StaticImageData> = {
  appsumo_plus: UpsellPlusIcon,
  earning: UpsellEarningIcon,
  exposure: UpsellExposureIcon,
  spread_word: UpsellSpreadWordIcon,
};

// https://tailwindcss.com/docs/content-configuration#dynamic-class-names
const BG_CLASSES: Record<string, string> = {
  '#fff5d9': 'bg-[#fff5d9]',
};

export const UpsellCallout = memo(function UpsellCallout({
  deal,
}: {
  deal: Deal;
}) {
  if (!deal?.upsell_callout) return null;

  const {
    absolute_url: url,
    upsell_text: text,
    upsell_icon: icon,
    upsell_background: color,
  } = deal.upsell_callout;

  const iconSrc = UPSELL_ICON[icon] || UPSELL_ICON.appsumo_plus;
  const calloutHtml = <div dangerouslySetInnerHTML={{ __html: text }} />;

  return (
    <div
      // eslint-disable-next-line tailwindcss/no-custom-classname
      className={clsx(
        'mt-4 flex w-full items-center rounded p-2 text-sm',
        color ? BG_CLASSES[color] : 'bg-gray-100',
      )}
    >
      <Image
        src={iconSrc}
        alt="offer icon"
        width={40}
        height={40}
        className="mr-2 w-10 overflow-visible"
      />

      {url ? (
        <Link href={`${url}?ref=pdp-ups-callout`}>{calloutHtml}</Link>
      ) : (
        <>{calloutHtml}</>
      )}
    </div>
  );
});
