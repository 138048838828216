import Image from 'next/image';
import { DownloadsAndReviews, Link } from '@appsumo/dorado-react';
import { DealSkuCardType } from '~/components/sku/types';
import { currencyWithCents } from '~/lib/format';

function BestFor({ attr }: { attr: string[] }) {
  if (!attr?.[0]) return null;
  const value = attr[0];
  const slug = value
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');

  return (
    <div className="bg-iceberg p-4 py-2">
      Best for&nbsp;
      <Link key={slug} href={`/collections/best-for/${slug}`} styled>
        {value}
      </Link>
    </div>
  );
}

export function GridTile({ deal }: { deal: DealSkuCardType }) {
  if (!deal) return <></>;
  const bestForAttr = deal.attributes?.best_for || [];
  const avgRating = deal.deal_review?.average_rating
    ? Number(deal.deal_review.average_rating)
    : 0;

  return (
    <div className="inline-flex flex-1 flex-col justify-between rounded border border-sundog shadow-md">
      <div className="p-4 pb-2">
        <p>{deal.card_description}</p>
        <div className="mt-1 flex">
          <Image
            src={deal.media_url}
            alt="deal thumbnail"
            width={64}
            height={36}
            className="my-auto mr-2 inline-block rounded"
          />
          <div className="my-auto inline-block">
            <Link
              href={deal.get_absolute_url}
              styled
              className="block font-bold line-clamp-1"
            >
              {deal.public_name}
            </Link>
            <div>
              <div className="inline-block border-r border-sundog pr-2">
                <span className="font-medium text-black">
                  {deal.is_free ? 'FREE' : currencyWithCents(deal.price)}
                </span>
                {deal.original_price > 0 && (
                  <>
                    &nbsp;
                    <span className="text-grace line-through">
                      {currencyWithCents(deal.original_price)}
                    </span>
                  </>
                )}
              </div>
              <div className="inline-block pl-2">
                <DownloadsAndReviews
                  averageRating={avgRating}
                  reviewCount={deal.deal_review?.review_count}
                  href={`${deal.get_absolute_url}#reviews`}
                  freebieDownloadsTotal={deal.freebie_downloads_total}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <BestFor attr={bestForAttr} />
    </div>
  );
}
