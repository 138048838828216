import { useMemo } from 'react';
import { toast } from 'react-hot-toast';

import useUser from '~/lib/user';
import { getUtmParamsForSocialShare, openExternalWindow } from '~/lib/util';
import {
  FACEBOOK_SHARE_URL,
  LINKEDIN_SHARE_URL,
  TWITTER_SHARE_URL,
} from '~/lib/util/constants';
import { ShareType } from '~/lib/util/helpers';
import useCopyToClipboard from '~/lib/util/hooks/copyToClipboard';

type SocialLinkTypes = {
  [key: string]: string;
  email: string;
  facebook: string;
  linkedin: string;
  twitter: string;
};

function getTwitterText(title: string, subheader?: string) {
  if (!subheader) {
    return encodeURIComponent(
      `This lifetime deal on ${title} is so good I had to tell you about it.`,
    );
  }

  return encodeURIComponent(
    `${subheader}. No monthly payments—ever. Check it out here:`,
  );
}

function getSocialNetworkURL({
  email,
  facebook,
  twitter,
  linkedin,
  shareableText,
  title,
  subheader,
}: {
  email: string;
  facebook: string;
  twitter: string;
  linkedin: string;
  shareableText: string;
  title: string;
  subheader?: string;
}) {
  // Additional social network parameters (i.e: utm parameters) to append
  // to the shareableText value.
  const params = {
    email: [
      `mailto:?body=${encodeURIComponent(shareableText + email)}`,
      `subject=${encodeURIComponent(`Share ${title}`)}`,
    ],
    facebook: [
      `u=${encodeURIComponent(shareableText + facebook)}`,
      `quote=${encodeURIComponent(shareableText + facebook)}`,
    ],
    twitter: [
      `url=${encodeURIComponent(shareableText + twitter)}`,
      `text=${getTwitterText(title, subheader)}`,
    ],
    linkedin: [`url=${encodeURIComponent(shareableText + linkedin)}`],
  };
  return {
    email: `${params.email.join('&')}`,
    facebook: `${FACEBOOK_SHARE_URL}${params.facebook.join('&')}`,
    twitter: `${TWITTER_SHARE_URL}${params.twitter.join('&')} `,
    linkedin: `${LINKEDIN_SHARE_URL}${params.linkedin.join('&')} `,
  };
}

export const useSocialShare = (
  dealId: number,
  dealSlug: string,
  publicName: string,
  shareType: ShareType,
  content?: string,
) => {
  const { user } = useUser();
  const [, copyToClipboard] = useCopyToClipboard();

  const pdpUrl = dealSlug ? `/products/${dealSlug}/` : '';

  const socialParams = useMemo(() => {
    return getUtmParamsForSocialShare({
      data: { slug: dealSlug, id: dealId },
      userId: user?.id,
      shareType: shareType,
      content,
    });
  }, [dealId, dealSlug, user?.id, shareType, content]);

  const shareableLink =
    typeof window === 'undefined' ? '' : `${window.location.origin}${pdpUrl}`;
  const shareableLinkWithParams = `${shareableLink}${socialParams.text || ''}`;

  const socialLinks: SocialLinkTypes = useMemo(() => {
    return getSocialNetworkURL({
      email: socialParams.email,
      facebook: socialParams.facebook,
      twitter: socialParams.twitter,
      linkedin: socialParams.linkedin,
      shareableText: shareableLink,
      title: publicName,
    });
  }, [socialParams, shareableLink, publicName]);

  const openSocialWindow = (socialName: string) => {
    const windowName =
      socialName !== 'email'
        ? `share-modal-${Math.round(Math.random() * 10000)}`
        : '_self';
    openExternalWindow({
      url: socialLinks[socialName],
      windowName,
      popup: socialName !== 'email',
    });
  };

  const clipboardCopy = async () => {
    const copied = await copyToClipboard(shareableLinkWithParams);
    if (copied) {
      toast.success('Link copied to clipboard!', { id: 'clipboard' });
    } else {
      toast.error('Failed to copy link to clipboard.', { id: 'clipboard' });
    }
  };

  return { shareableLinkWithParams, openSocialWindow, clipboardCopy };
};
