import { useRouter } from 'next/router';
import { memo } from 'react';
import InformationBox from '~/components/ui/InformationBox';
import { useEvent } from '~/lib/events';
import { isPDP } from '~/lib/util/constants';
import { Deal } from '~/types/deal';
import { BuyNow } from '../PricingCard/BuyNow';

interface BuyToCommentProps {
  deal: Deal;
  actionText: string;
}

function BuyToComment({ deal, actionText }: Readonly<BuyToCommentProps>) {
  const { asPath, push } = useRouter();
  const isPDPPage = isPDP(asPath);

  useEvent('cart:added', () => {
    if (!isPDPPage) {
      // we have a 'cart:added' handler on PDP, let's not duplicate here
      push('/cart/');
    }
  });

  return (
    <InformationBox
      text={`Buy now to ${actionText}`}
      helpText="Only verified purchasers can review products on AppSumo."
      className={!isPDPPage ? 'w-full' : 'w-fit'}
    >
      {/* InView = true to not display sticky banner on mobile */}
      {/* Only show the buy button on PDP, for Agg pages we have buy button on pricing card anyway */}
      {isPDPPage && <BuyNow deal={deal} inView hideAdditionalInformation />}
    </InformationBox>
  );
}

export default memo(BuyToComment);
