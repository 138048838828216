import { GridTile } from './GridTile';
import { DealSkuCardType } from '~/components/sku/types';

export function Grid({ deals }: { deals: DealSkuCardType[] }) {
  if (!deals?.length) return null;

  return (
    <div className="flex w-fit flex-col justify-between gap-6 md:flex-row">
      {deals.map((deal) => (
        <GridTile key={deal.id} deal={deal} />
      ))}
    </div>
  );
}
