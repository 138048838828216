import { createContext } from 'react';
import { DEAL_COMMENT_TYPE } from '~/lib/discussions/constants';
import { CommentV2 } from '~/lib/discussions/types';

interface DealCommentsContextProps {
  items: CommentV2[];
  itemsCount: number;
  commentsTotal: number;
  size: number;
  itemsPerPage: number;
  type: DEAL_COMMENT_TYPE;
  enableManualPagination: boolean;
  query?: string;
  showReplies: boolean;
  toggleShowReplies: () => void;
}

export const DealCommentsContext = createContext<DealCommentsContextProps>(
  {} as DealCommentsContextProps,
);
