import {
  faCommentDots,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { memo, useContext, useMemo } from 'react';
import { useDiscussionStats } from '~/hooks/useDiscussionStats';
import { useUserStatus } from '~/hooks/userStatus';
import { DEAL_COMMENT_TYPE } from '~/lib/discussions/constants';
import { PDPContext } from '~/lib/product/context';
import { Deal } from '~/types/deal';

function CommentModeratorInfo({ type }: Readonly<{ type: DEAL_COMMENT_TYPE }>) {
  const {
    deal: { id: dealId },
  }: { deal: Deal } = useContext(PDPContext);
  const { userStatus } = useUserStatus(dealId);
  const { discussionStats } = useDiscussionStats(dealId, type);

  const { needs_moderation: needsModeration, needs_reply: needsReply } =
    useMemo(() => discussionStats || {}, [discussionStats]);

  return (
    <div className="flex">
      {!!needsReply && (
        <div>
          <FontAwesomeIcon
            icon={faCommentDots}
            className="mr-1 text-base text-brick"
          />
          <span className="text-sm">
            {needsReply} needs{' '}
            {type === DEAL_COMMENT_TYPE.REVIEW ? 'reply' : 'answer'}
          </span>
        </div>
      )}

      {!!needsReply && userStatus?.staff_moderator && !!needsModeration && (
        <span className="mx-2 text-slate">|</span>
      )}

      {userStatus?.staff_moderator && !!needsModeration && (
        <div>
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            className="mr-1 text-base text-dorado"
          />
          <span className="text-sm">{needsModeration} needs moderation</span>
        </div>
      )}
    </div>
  );
}

export default memo(CommentModeratorInfo);
