import { Dropdown, Form } from '@appsumo/dorado-react';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { FieldValues } from 'react-hook-form';
import * as yup from 'yup';
import { useUserStatus } from '~/hooks/userStatus';
import {
  DEAL_COMMENT_TYPE,
  filterOptions,
  getFilterOptions,
  TACO_RATING_FILTERS,
} from '~/lib/discussions/constants';
import {
  DiscussionFilter,
  DiscussionFilterOption,
  DiscussionSort,
  DiscussionSortOption,
  TacoRatingFilter,
  TacoRatingFilterOption,
} from '~/lib/discussions/types';
import { PDPContext } from '~/lib/product/context';
import { getQueryFromPath } from '~/lib/util';

interface CommentFiltersProps {
  type: DEAL_COMMENT_TYPE;
  sortOptions: DiscussionSortOption[];
  sortBy: DiscussionSort;
  filterBy: DiscussionFilter;
  tacoRating: TacoRatingFilter;
  onChangeSort: (sort: DiscussionSort) => void;
  onChangeFilter: (filter: DiscussionFilter) => void;
  onChangeRating: (rating: TacoRatingFilter) => void;
  enableParamsState?: boolean;
  isLoadingMore: boolean;
}

const formSchema = yup.object().shape({
  sort: yup.string().required(),
} as FieldValues);

function CommentFilters({
  type,
  sortOptions,
  onChangeSort,
  onChangeFilter,
  onChangeRating,
  sortBy,
  filterBy,
  tacoRating,
  isLoadingMore,
  enableParamsState = false,
}: Readonly<CommentFiltersProps>) {
  const {
    deal: { id: dealId },
  } = useContext(PDPContext);
  const { userStatus } = useUserStatus(dealId);
  const { asPath } = useRouter();
  const formRef = useRef<HTMLFormElement>(null);
  const tacoRef = useRef<HTMLFormElement>(null);

  // Set initial sort and filter from URL params
  useEffect(() => {
    const params = getQueryFromPath(asPath);

    if (params.has('sort')) {
      onChangeSort(params.get('sort') as DiscussionSort);
      formRef?.current?.setValue(
        'sort',
        sortOptions.find((sort) => sort.value === params.get('sort')),
      );
    }

    if (params.has('filter')) {
      onChangeFilter(params.get('filter') as DiscussionFilter);
    }

    if (params.has('taco_rating')) {
      onChangeRating(params.get('taco_rating') as TacoRatingFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // no dependecies, we only want to run this once

  // Update URL params when sort or filter changes
  useEffect(() => {
    if (enableParamsState) {
      const url = new URL(window.location.href);

      if (filterBy !== filterOptions[0].value) {
        url.searchParams.set('filter', filterBy);
      } else {
        url.searchParams.delete('filter');
      }

      if (sortBy !== sortOptions[0].value) {
        url.searchParams.set('sort', sortBy);
      } else {
        url.searchParams.delete('sort');
      }

      if (tacoRating !== TACO_RATING_FILTERS[0].value) {
        url.searchParams.set('taco_rating', tacoRating);
      } else {
        url.searchParams.delete('taco_rating');
      }

      window.history.replaceState({}, '', url.toString());
    }
  }, [sortBy, filterBy, tacoRating, enableParamsState, sortOptions]);

  const dynamicFilterOptions: DiscussionFilterOption[] = useMemo(() => {
    if (userStatus?.user_moderator) {
      return getFilterOptions(type);
    }

    return filterOptions;
  }, [userStatus, type]);

  const currentFilter = useMemo(() => {
    const filter = dynamicFilterOptions?.find(
      (f: DiscussionFilterOption) => f.value === filterBy,
    ) as DiscussionFilterOption;

    return filter?.label || '';
  }, [dynamicFilterOptions, filterBy]);

  const currentSort = useMemo(() => {
    const sort = sortOptions.find(
      (f: DiscussionSortOption) => f.value === sortBy,
    ) as DiscussionSortOption;

    return sort?.label || '';
  }, [sortBy, sortOptions]);

  // current taco rating
  const currentRatingFilter: string = useMemo(() => {
    const rating = TACO_RATING_FILTERS.find(
      (t: TacoRatingFilterOption) => t.value === tacoRating,
    ) as TacoRatingFilterOption;

    tacoRef?.current?.setValue(rating);
    return rating?.label || '';
  }, [tacoRating]);

  // set sort based on form.dropdown internal change
  const onFormChange = useCallback(
    (data: FieldValues, name: string, type: string) => {
      if (name === 'sort' && type === 'change') {
        onChangeSort(data.sort.value as DiscussionSort);
      }
    },
    [onChangeSort],
  );

  const showFilterOptions = useMemo(() => {
    return currentFilter && userStatus?.user_moderator;
  }, [currentFilter, userStatus]);

  const showTacoFilterOptions = useMemo(() => {
    return currentRatingFilter && type === DEAL_COMMENT_TYPE.REVIEW;
  }, [currentRatingFilter, type]);

  const showAllDropdowns = useMemo(() => {
    return showTacoFilterOptions && showFilterOptions;
  }, [showFilterOptions, showTacoFilterOptions]);

  return (
    <div
      className={`flex gap-4 ${
        showAllDropdowns ? 'flex-col md:flex-row' : 'flex-row'
      }`}
    >
      <div
        className={clsx(
          'space-y-2',
          showAllDropdowns ? 'w-full md:w-1/3' : 'w-1/2',
        )}
      >
        <Form
          ref={formRef}
          onSubmit={() => {}}
          resolver={yupResolver(formSchema)}
          watch={onFormChange}
        >
          <label className="text-sm font-semibold" htmlFor="filter_by">
            Sort by:
          </label>
          <Form.Dropdown
            name="sort"
            defaultValue={{ label: currentSort, value: sortBy }}
            options={sortOptions}
            className="mt-2 w-full text-xs"
            disabled={isLoadingMore}
          />
        </Form>
      </div>

      <div
        className={`flex flex-row gap-4 ${
          showAllDropdowns ? 'md:w-2/3' : 'w-1/2'
        }`}
      >
        {showFilterOptions && (
          <div
            className={clsx(
              'space-y-2',
              showTacoFilterOptions ? 'w-1/2' : 'w-full',
            )}
          >
            <label className="text-sm font-semibold" htmlFor="filter_by">
              Filter by:
            </label>
            <Dropdown
              defaultValue={{ label: currentFilter, value: filterBy }}
              options={dynamicFilterOptions}
              onClick={(option) =>
                onChangeFilter(option.value as DiscussionFilter)
              }
              disabled={isLoadingMore}
              className="mr-4 w-full text-xs"
            />
          </div>
        )}

        {showTacoFilterOptions && (
          <div
            className={clsx(
              'space-y-2',
              showFilterOptions ? 'w-1/2' : 'w-full',
            )}
          >
            <label className="text-sm font-semibold" htmlFor="taco_rating">
              Taco rating:
            </label>
            <Dropdown
              ref={tacoRef}
              defaultValue={{ label: currentRatingFilter, value: tacoRating }}
              options={TACO_RATING_FILTERS}
              onClick={(option) =>
                onChangeRating(option.value as TacoRatingFilter)
              }
              disabled={isLoadingMore}
              className="mr-4 w-full text-xs"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default memo(CommentFilters);
