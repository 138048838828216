import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { ICreatorReplyProps } from '~/components/discussions/card/CreatorReply';
import {
  ANSWER_TYPE_PARTNER,
  ANSWER_TYPE_STAFF,
  CommentAnswerType,
  CommentV2,
  DISCUSSION_STATUS_APPROVED,
  DISCUSSION_STATUS_REJECTED,
  DISCUSSION_STATUS_UNMODERATED,
  DiscussionStatus,
} from '~/lib/discussions/types';
import { useCommentV2 } from '~/lib/discussions/useCommentV2';
import { PDPContext } from '~/lib/product/context';
import { LOGIN_URL } from '~/lib/util/constants';
import { CommentV2Type } from '~/static/discussions/enums';

function useCommentCard(
  item: CommentV2,
  type: CommentV2Type,
  partnersIds: Array<number>,
  expandCard: boolean = false,
  showCollapseIcon: boolean = false,
) {
  const [comment, setComment] = useState<CommentV2>(item);
  const [stateHelpfulCount, setStateHelpfulCount] = useState(comment.up_votes);
  const [stateHasUserUpvoted, setStateHasUserUpvoted] = useState(
    comment.user_vote === 1,
  );
  const { userStatus, user } = useContext(PDPContext);

  const [isCollapsed, setIsCollapsed] = useState(
    !expandCard && showCollapseIcon,
  );

  useEffect(() => {
    if (!showCollapseIcon) {
      return;
    }

    setIsCollapsed(!expandCard);
  }, [expandCard, showCollapseIcon]);

  const { like, dislike, disableVoteBtn } = useCommentV2(comment);

  const userStates = useMemo(() => {
    return {
      isPartner: partnersIds.includes(user?.id ?? 0),
      isOwner: comment.user.id === user?.id,
    };
  }, [partnersIds, user?.id, comment?.user?.id]);

  const onUpvote = useCallback(() => {
    setStateHelpfulCount(stateHelpfulCount + 1);
    setStateHasUserUpvoted(true);

    like();
  }, [like, stateHelpfulCount]);

  const onDownvote = useCallback(() => {
    setStateHelpfulCount(stateHelpfulCount - 1);
    setStateHasUserUpvoted(false);

    dislike();
  }, [dislike, stateHelpfulCount]);

  const toggleVote = useCallback(() => {
    // force user to log in in order to vote
    if (!user?.is_authenticated) {
      window.location.href = `${LOGIN_URL}?next=${comment.display_path}`;
      return;
    }

    if (stateHasUserUpvoted) {
      onDownvote();
    } else {
      onUpvote();
    }
  }, [
    comment.display_path,
    onDownvote,
    onUpvote,
    stateHasUserUpvoted,
    user?.is_authenticated,
  ]);

  const setCollapseValue = useCallback(() => {
    setIsCollapsed(!isCollapsed);
  }, [isCollapsed]);

  const creatorReply: ICreatorReplyProps | null = useMemo(() => {
    let creatorComment: CommentV2 | undefined = undefined;
    const status = comment.status?.toLowerCase();

    // If the status is approved we get first reply from partner if any
    if (status === DISCUSSION_STATUS_APPROVED) {
      creatorComment = comment.children?.find(
        (child: CommentV2) =>
          child.answer_type === ANSWER_TYPE_PARTNER &&
          partnersIds.includes(child.user.id),
      );
    }

    // If the status is rejected we get first reply from staff if any
    if (status === DISCUSSION_STATUS_REJECTED) {
      creatorComment = comment.children?.find(
        (child) => child.answer_type === ANSWER_TYPE_STAFF,
      );
    }

    // - If Approved visible for everybody
    // - If Declined visible only for staff, moderators and owner (not other sumolings)
    const isCommentAuthor = user?.id === comment.user.id;
    const isPartner = partnersIds.includes(user?.id ?? 0);

    const isVisible =
      status === DISCUSSION_STATUS_APPROVED ||
      (status === DISCUSSION_STATUS_REJECTED &&
        (userStatus?.staff_moderator || isCommentAuthor || isPartner));

    if (!isVisible || !creatorComment) {
      return null;
    }

    return {
      id: creatorComment.id,
      avatarUrl: creatorComment.user.avatar,
      creatorUsername: creatorComment.user.username,
      replyDate: creatorComment.created,
      replyText:
        type === CommentV2Type.Question
          ? `A: ${creatorComment.comment}`
          : creatorComment.comment,
      status: comment.status?.toLowerCase() as DiscussionStatus,
      deleteReplyUrl: `/api/v2/deals/${comment.deal_id}/${type}s/${creatorComment.id}/`,
      allowOptions: user?.id === creatorComment.user.id,
    };
  }, [
    comment.status,
    comment.children,
    comment.user.id,
    comment.deal_id,
    userStatus?.staff_moderator,
    user?.id,
    type,
    partnersIds,
  ]);

  // Used at the top of the card to show the discussion tag related to filters
  const showDiscussionTag = useMemo(() => {
    const status = comment.status?.toLowerCase();

    if (status === DISCUSSION_STATUS_UNMODERATED) {
      return !!(
        userStates.isOwner ||
        userStates.isPartner ||
        userStatus?.staff_moderator
      );
    }

    return !!(
      status === DISCUSSION_STATUS_APPROVED &&
      !creatorReply &&
      (userStates.isPartner || userStatus?.staff_moderator)
    );
  }, [
    comment.status,
    creatorReply,
    userStates.isPartner,
    userStates.isOwner,
    userStatus?.staff_moderator,
  ]);

  // Used to show the status of the comment
  const showStatusModerator = useMemo(() => {
    const status = comment.status?.toLowerCase();

    // partners can only see unmoderated on Questions
    if (status === DISCUSSION_STATUS_UNMODERATED) {
      return !!(
        userStatus?.staff_moderator ||
        (userStates.isPartner && type === CommentV2Type.Question)
      );
    }

    if (status === DISCUSSION_STATUS_REJECTED) {
      return !!(
        userStates.isOwner ||
        userStates.isPartner ||
        userStatus?.staff_moderator
      );
    }

    return !!(
      status === DISCUSSION_STATUS_APPROVED &&
      (userStatus?.staff_moderator || (userStates.isPartner && !creatorReply))
    );
  }, [
    comment.status,
    creatorReply,
    userStates.isPartner,
    userStates.isOwner,
    type,
    userStatus?.staff_moderator,
  ]);

  const showSubmitReply = useMemo(() => {
    const value =
      // Not visible if there's already a reply
      !creatorReply &&
      // If approved only visible for moderators
      ((userStates.isPartner &&
        comment.status?.toLowerCase() === DISCUSSION_STATUS_APPROVED) ||
        // If declined only visible for staff
        (userStatus?.staff_moderator &&
          comment.status?.toLowerCase() === DISCUSSION_STATUS_REJECTED));

    return value;
  }, [
    creatorReply,
    userStates.isPartner,
    comment.status,
    userStatus?.staff_moderator,
  ]);

  const appendChild = (child: CommentV2) => {
    // API returns comment without user
    const childWithUser = {
      ...child,
      user: {
        id: user?.id,
        avatar: user?.avatar,
        username: user?.username,
        has_plus: user?.has_plus,
      },
    } as CommentV2;

    setComment({
      ...comment,
      children: [...(comment?.children ?? []), childWithUser],
    });
  };

  const setStatus = (status: DiscussionStatus) => {
    setComment({ ...comment, status });
  };

  const removePartnerStaffReply = () => {
    setComment({
      ...comment,
      children: comment?.children?.filter(
        (child) => child.id !== creatorReply?.id,
      ),
    });
  };

  const answerType: CommentAnswerType = useMemo(() => {
    if (userStates.isPartner) {
      return ANSWER_TYPE_PARTNER;
    }

    if (userStatus?.staff_moderator) {
      return ANSWER_TYPE_STAFF;
    }

    return null;
  }, [userStates.isPartner, userStatus?.staff_moderator]);

  return {
    comment,
    isPartner: userStates.isPartner,
    isOwner: userStates.isOwner,
    stateHelpfulCount,
    stateHasUserUpvoted,
    toggleVote,
    disableVoteBtn,
    creatorReply,
    showDiscussionTag,
    isCollapsed,
    setCollapseValue,
    showStatusModerator,
    showSubmitReply,
    appendChild,
    setStatus,
    removePartnerStaffReply,
    answerType,
  };
}

export default useCommentCard;
