import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { memo } from 'react';

export interface IHelpfulButtonProps {
  helpfulCount: number;
  onToggleVote: () => void;
  isDisabled: boolean;
  hasUserUpvoted: boolean;
}

/**
 * Helpful Button
 * @param {number} helpfulCount - The number of helpful votes
 * @param {function} onUpvote - The function to call when the user upvotes
 * @param {function} onDownvote - The function to call when the user downvotes
 * @param {boolean} hasUserUpvoted - Whether the user has upvoted
 *
 * @returns - The Helpful Button component
 */
export default memo(function HelpfulButton({
  helpfulCount,
  onToggleVote,
  isDisabled,
  hasUserUpvoted,
}: Readonly<IHelpfulButtonProps>): JSX.Element {
  return (
    <div
      className="flex items-center font-sans text-sm text-grace"
      data-testid="discussion-helpful-buttons"
    >
      <span id="helpful-title" className="mr-1">
        Helpful?
      </span>
      <div className="flex rounded-2xl border border-sundog px-2 py-1">
        <button
          aria-labelledby="helpful-title"
          type="button"
          className="flex h-5 items-center"
          onClick={onToggleVote}
          disabled={isDisabled}
          data-testid="helpful-upvote-button"
        >
          <FontAwesomeIcon
            icon={faArrowUp}
            className={`text-base ${
              hasUserUpvoted ? 'text-dollar' : 'text-slate'
            }`}
          />
          <span className="ml-2" data-testid="helpful-count">
            {helpfulCount > 0 ? helpfulCount : 0}
          </span>
        </button>
      </div>
    </div>
  );
});
