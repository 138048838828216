import { useState } from 'react';
import { CommentV2 as CommentV2Type, ReviewV2 } from '~/lib/discussions/types';
import { fetchJson } from '~/lib/fetch';
import {
  ApprovalStatus,
  ModerationStatus,
  UserStatus,
} from '~/static/discussions/enums';

export function useCommentV2(
  comment: CommentV2Type,
  mutateComment: () => void = () => {},
  mutateReviewsStats: () => void = () => {},
) {
  const [disableVoteBtn, setDisableVoteBtn] = useState(false);
  const { id, user } = comment;
  const commentType =
    typeof (comment as ReviewV2).rating !== 'undefined'
      ? 'reviews'
      : 'questions';

  const vote = async function (direction = 1) {
    setDisableVoteBtn(true);
    try {
      const args = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        include: 'credentials',
        body: JSON.stringify({ direction }),
      };
      await fetchJson(
        `/api/v2/deals/${comment.deal_id}/${commentType}/${id}/vote/`,
        args,
      );
      mutateComment();
      mutateReviewsStats();
    } catch (error) {
      console.error(error, { success: false });
    }
    setDisableVoteBtn(false);
  };

  const like = async function () {
    vote();
  };

  const dislike = async function () {
    vote(-1);
  };

  const setApprovalStatus = async function (
    value: ApprovalStatus,
  ): Promise<boolean> {
    const approved = value === ApprovalStatus.Unapproved ? null : value;

    try {
      const args = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        include: 'credentials',
        body: JSON.stringify({ approved }),
      };
      await fetchJson(
        `/api/v2/deals/${comment.deal_id}/${commentType}/${id}/approve/`,
        args,
      );
      mutateComment();
      mutateReviewsStats();

      return true;
    } catch (error) {
      console.error(error, { value: ApprovalStatus[value], success: false });
      return false;
    }
  };

  // TODO: add whitelist
  const setGlobalUserStatus = async function (
    type: UserStatus,
    value: boolean = true,
  ): Promise<void> {
    const action = value ? '1' : '0';

    try {
      await fetchJson(
        `/comments/${type}_author_ajx/?user_id=${user.id}&${type}_action=${action}`,
      );
      mutateComment();
    } catch (e) {
      console.error({ type: type, value: value, success: false });
    }
  };

  const setModerationStatus = async function (
    value: ModerationStatus,
  ): Promise<void> {
    const resolved = value;

    const args = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      include: 'credentials',
      body: JSON.stringify({ resolved }),
    };

    try {
      await fetchJson(
        `/api/v2/deals/${comment.deal_id}/${commentType}/${id}/resolve/`,
        args,
      );
      mutateComment();
    } catch (e) {
      console.error({ action: ModerationStatus[value], success: false });
    }
  };

  const approve = async function (): Promise<boolean> {
    return await setApprovalStatus(ApprovalStatus.Approved);
  };

  const unapprove = async function (): Promise<boolean> {
    return await setApprovalStatus(ApprovalStatus.Unapproved);
  };

  const deny = async function (): Promise<boolean> {
    return await setApprovalStatus(ApprovalStatus.Denied);
  };

  const undeny = unapprove;

  const approveAllPosts = async function (): Promise<void> {
    await setGlobalUserStatus(UserStatus.Whitelist);
  };

  const disableApproveAllPosts = async function (): Promise<void> {
    await setGlobalUserStatus(UserStatus.Whitelist, false);
  };

  const blockUser = async function (): Promise<void> {
    await setGlobalUserStatus(UserStatus.Blacklist);
  };

  const unblockUser = async function (): Promise<void> {
    await setGlobalUserStatus(UserStatus.Blacklist, false);
  };

  const resolve = async function (): Promise<void> {
    await setModerationStatus(ModerationStatus.Resolved);
  };

  const unresolve = async function (): Promise<void> {
    await setModerationStatus(ModerationStatus.Unresolved);
  };

  // todo: helper for pinned? -- not necessarily needed on q/r page

  return {
    approve,
    unapprove,
    approveAllPosts,
    disableApproveAllPosts,
    blockUser,
    unblockUser,
    deny,
    undeny,
    resolve,
    unresolve,
    like,
    dislike,
    vote,
    disableVoteBtn,
  };
}
