import { Button, Modal } from '@appsumo/dorado-react';
import { useState } from 'react';

import { fetchJson } from '~/lib/fetch';

function DiscussionDeleteModal({
  title,
  body,
  deleteButtonText,
  cancelCallback,
  successCallback,
  errorCallback,
  deleteUrl,
}: Readonly<{
  title: string;
  body: string;
  deleteButtonText: string;
  cancelCallback: Function;
  successCallback: Function;
  errorCallback: Function;
  deleteUrl: string;
}>) {
  const [isDeleting, setIsDeleting] = useState(false);

  async function onSubmitDelete() {
    setIsDeleting(true);

    try {
      await fetchJson(deleteUrl, { method: 'DELETE' });
      successCallback();
    } catch (error) {
      console.error('Deleting discussion ', deleteUrl, error);
      errorCallback();
    } finally {
      setIsDeleting(false);
    }
  }

  function onCancel() {
    if (isDeleting) {
      return;
    }

    cancelCallback();
  }

  return (
    <Modal
      title={title}
      hideModal={cancelCallback}
      className="p-6 md:max-w-[363px]"
    >
      <p>{body}</p>

      <div
        data-testid="discussion-delete-content"
        className="mt-4 flex flex-col gap-4 md:flex-row md:items-center md:justify-end"
      >
        <Button tertiary disabled={isDeleting} onClick={onCancel}>
          Cancel
        </Button>
        <button
          type="button"
          className="rounded-full border-2 border-transparent bg-brick px-6 py-2 font-header font-bold text-white hover:border-coral hover:bg-coral focus-visible:border-black-pearl focus-visible:bg-coral focus-visible:outline-none active:border-coral active:bg-ready-red"
          onClick={onSubmitDelete}
          disabled={isDeleting}
        >
          {isDeleting ? 'Deleting...' : deleteButtonText}
        </button>
      </div>
    </Modal>
  );
}

export default DiscussionDeleteModal;
