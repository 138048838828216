import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Image, { StaticImageData } from 'next/image';

const tagBgVariants = {
  'lemon-light': 'bg-lemon-light-40',
  'dollar-light': 'bg-dollar/10',
  'brick-light': 'bg-brick/10',
};

const tagColorVariants = {
  'lemon-light': 'text-dorado',
  'dollar-light': 'text-dollar',
  'brick-light': 'text-brick',
};

type TagVariant = 'lemon-light' | 'dollar-light' | 'brick-light';

export interface TagProps {
  label: string;
  faIcon?: IconProp;
  icon?: string | StaticImageData;
  variant?: TagVariant;
}

function Tag({
  label,
  faIcon,
  icon,
  variant = 'lemon-light',
}: Readonly<TagProps>) {
  return (
    <span
      className={`inline-flex items-center justify-center gap-1 rounded px-2 py-1 text-sm font-medium ${tagBgVariants[variant]}`}
    >
      {icon && <Image src={icon} alt="" width={21} height={21} />}
      {!icon && faIcon && (
        <FontAwesomeIcon
          icon={faIcon}
          className={`text-base ${tagColorVariants[variant]}`}
        />
      )}
      {label}
    </span>
  );
}

export default Tag;
