import { memo } from 'react';
import InformationBox from '~/components/ui/InformationBox';
import { DEAL_COMMENT_TYPE } from '~/lib/discussions/constants';
import { LOGIN_URL } from '~/lib/util/constants';

interface LoginToCommentProps {
  dealSlug: string;
  actionText: string;
  type: DEAL_COMMENT_TYPE;
  isPDPPage?: boolean;
}

function LoginToComment({
  dealSlug,
  actionText,
  type,
  isPDPPage,
}: Readonly<LoginToCommentProps>) {
  if (typeof window === 'undefined') {
    return null;
  }

  let next = window.location.pathname;

  if (type === DEAL_COMMENT_TYPE.REVIEW) {
    next = `/products/${dealSlug}/reviews/new/`;
  }

  return (
    <InformationBox
      text={`Log in to ${actionText}`}
      helpText="Only logged-in purchasers can review products on AppSumo."
      className={!isPDPPage ? 'w-full' : 'w-fit'}
    >
      <a
        data-testid="login-to-comment-link"
        href={`${LOGIN_URL}?next=${next}`}
        className="rounded-full border-2 border-deep-ocean px-6 py-2 font-header font-bold text-deep-ocean hover:bg-skys-the-limit focus-visible:border-black-pearl focus-visible:bg-skys-the-limit focus-visible:text-midnight focus-visible:outline-none active:border-deep-ocean active:bg-bolt active:text-white"
      >
        Log in
      </a>
    </InformationBox>
  );
}

export default memo(LoginToComment);
