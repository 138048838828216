import useSWR, { KeyedMutator, mutate } from 'swr';
import { DEAL_COMMENT_TYPE } from '~/lib/discussions/constants';
import { ReviewStatsResponse } from '~/types/question-review';

type SWRResponse = {
  discussionStats: ReviewStatsResponse;
  mutateDiscussionStats: KeyedMutator<any>;
};

const getUrl = (dealId: number, type: DEAL_COMMENT_TYPE) =>
  `/api/v2/deals/${dealId}/${
    type === DEAL_COMMENT_TYPE.REVIEW ? 'review_stats' : 'question_stats'
  }`;

export function useDiscussionStats(
  dealId: number,
  type: DEAL_COMMENT_TYPE,
): SWRResponse {
  const { data: discussionStats, mutate: mutateDiscussionStats } = useSWR(
    getUrl(dealId, type),
  );

  return { discussionStats, mutateDiscussionStats };
}

export function mutateDiscussionStats(dealId: number, type: DEAL_COMMENT_TYPE) {
  return mutate(getUrl(dealId, type));
}
