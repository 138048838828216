import { memo, useContext, useMemo } from 'react';
import { DealCommentsContext } from '~/contexts/deal-comments';
import { useUserStatus } from '~/hooks/userStatus';
import { DEAL_COMMENT_TYPE } from '~/lib/discussions/constants';
import { useExperiment } from '~/lib/experiment';
import { PDPContext } from '~/lib/product/context';
import { DISCUSSIONS_SHOW_ALL_REPLIES_EXPERIMENT_NAME } from '~/lib/util/constants';

function CommentFilterPaginationInfo() {
  const {
    itemsCount,
    commentsTotal,
    size: currentPage,
    itemsPerPage,
    type,
    enableManualPagination,
    query,
    showReplies,
    toggleShowReplies,
  } = useContext(DealCommentsContext);

  const {
    deal: { id: dealId },
  } = useContext(PDPContext);
  const { userStatus } = useUserStatus(dealId);
  const { variant: showAllVariant } = useExperiment(
    DISCUSSIONS_SHOW_ALL_REPLIES_EXPERIMENT_NAME,
  );

  const commentTypeLabel = useMemo(
    () => (type === DEAL_COMMENT_TYPE.QUESTION ? 'questions' : 'reviews'),
    [type],
  );

  const manualPaginationInfo = useMemo(() => {
    return (
      <div data-testid="manual-pagination-info" className="shrink-0">
        Showing <span>{(currentPage - 1) * itemsPerPage + 1}</span>
        {' - '}
        <span>{Math.min(currentPage * itemsPerPage, commentsTotal || 0)} </span>
        of <span>{commentsTotal}</span> {commentTypeLabel}
      </div>
    );
  }, [commentsTotal, itemsPerPage, currentPage, commentTypeLabel]);

  const scrollPaginationInfo = useMemo(() => {
    return (
      <div data-testid="scroll-pagination-info" className="shrink-0">
        Showing <span>{itemsCount}</span> of{' '}
        <span>{query ? itemsCount : commentsTotal}</span> {commentTypeLabel}
      </div>
    );
  }, [commentsTotal, itemsCount, query, commentTypeLabel]);

  return (
    <div className="flex items-center gap-x-2">
      {enableManualPagination ? manualPaginationInfo : scrollPaginationInfo}

      {(showAllVariant === 'enabled' || !!userStatus?.user_moderator) && (
        <div className="flex items-center gap-2">
          <span
            className="h-1.5 w-1.5 shrink-0 rounded-full bg-gravel"
            aria-hidden="true"
          />
          <button
            data-testid="show-all-replies-button"
            type="button"
            onClick={toggleShowReplies}
            className="flex shrink-0 justify-between text-sm text-blue-600"
          >
            {showReplies ? 'Hide' : 'Show'} all replies
          </button>
        </div>
      )}
    </div>
  );
}

export default memo(CommentFilterPaginationInfo);
