import { useEffect, useState } from 'react';
import useSWRInfinite from 'swr/infinite';
import { BareFetcher } from 'swr';

import { DEAL_COMMENT_TYPE } from './constants';
import { DiscussionFilter, DiscussionSort, TacoRatingFilter } from './types';
import { replaceURLParam } from '../url';

function useDiscussionInfinitePagination({
  dealId,
  type,
  sortBy,
  filterBy,
  tacoRating,
  query,
  paginateOnSearch,
  discussionUrlFn,
  discussionFetcher,
  fallbackData,
}: {
  dealId: number;
  type: DEAL_COMMENT_TYPE;
  sortBy: DiscussionSort;
  filterBy: string;
  tacoRating: TacoRatingFilter;
  query: string | undefined;
  paginateOnSearch: boolean;
  discussionUrlFn: Function;
  discussionFetcher: Function;
  fallbackData: any[];
}) {
  const [isFirstRender, setIsFirstRender] = useState(true);

  const { data, size, setSize, error, mutate, isValidating } = useSWRInfinite(
    (pageIndex, previousPageData) => {
      // construct URL for next page of data
      const url = discussionUrlFn({
        dealId,
        type,
        page: pageIndex + 1,
        sort: sortBy,
        tacoRating,
        filter: filterBy as DiscussionFilter,
        query,
        paginateOnSearch,
      });

      // first page has no search after
      if (pageIndex === 0) {
        return url;
      }

      const lastItem = previousPageData?.[previousPageData?.length - 1];

      if (lastItem?.search_after) {
        const searchAfter = btoa(lastItem.search_after.join(','));
        return replaceURLParam(
          url,
          'search_after',
          encodeURIComponent(searchAfter),
        );
      }

      return url;
    },
    {
      fallbackData: query ? [] : fallbackData, // only use fallback data when not searching
      revalidateFirstPage: false,
      fetcher: discussionFetcher as BareFetcher<any>,
    },
  );

  // we want to fetch again if the sort or filter changes, but not on the first render
  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
      return;
    }

    if (sortBy || filterBy || tacoRating) {
      mutate([], true);
      setSize(1);
    }
  }, [sortBy, filterBy, tacoRating, isFirstRender, mutate, setSize]);

  const isFinishedPaginating =
    size > 0 && data && data[data.length - 1]?.length === 0;

  return {
    data: data ? data[0] : {},
    size,
    setSize,
    error,
    mutate,
    isFinishedPaginating,
    isLoadingMore: isValidating,
  };
}

export default useDiscussionInfinitePagination;
