import { Button } from '@appsumo/dorado-react';
import { memo, useContext, useMemo } from 'react';
import { DEAL_COMMENT_TYPE } from '~/lib/discussions/constants';
import { PDPContext } from '~/lib/product/context';
import { Deal } from '~/types/deal';

interface EmptyCommentsCardProps {
  type: string;
  onClick: () => void;
  disabledCTA: boolean;
  isSearching: boolean;
}

function EmptyCommentsCard({
  type,
  onClick,
  disabledCTA,
  isSearching,
}: Readonly<EmptyCommentsCardProps>) {
  const { deal }: { deal: Deal } = useContext(PDPContext);

  const header = useMemo(() => {
    if (isSearching) {
      return type === DEAL_COMMENT_TYPE.REVIEW
        ? 'Hmm, no one has mentioned that... yet'
        : "A question so good, it hasn't been asked... yet";
    }

    return type === DEAL_COMMENT_TYPE.REVIEW
      ? 'No one has reviewed this product... yet'
      : 'No one has asked a question... yet';
  }, [isSearching, type]);

  const description = useMemo(() => {
    if (isSearching) {
      return type === DEAL_COMMENT_TYPE.REVIEW
        ? 'Write a review now. (Purchase required.)'
        : `Ask the ${deal.public_name} team now to get answers.`;
    }

    return type === DEAL_COMMENT_TYPE.REVIEW
      ? 'Be the first to write a review. (Purchase required.)'
      : `Be the first to ask a question.  Get a reply from the ${deal.public_name} team.`;
  }, [deal.public_name, isSearching, type]);

  const buttonText = useMemo(() => {
    return type === DEAL_COMMENT_TYPE.REVIEW
      ? 'Write a review'
      : 'Ask a question';
  }, [type]);

  return (
    <div className="flex flex-col gap-4 rounded border border-gravel p-6 shadow-md">
      <h4 className="font-header text-3xl font-bold">{header}</h4>
      <p>{description}</p>
      <div>
        <Button disabled={disabledCTA} onClick={onClick} secondary>
          {buttonText}
        </Button>
      </div>
    </div>
  );
}

export default memo(EmptyCommentsCard);
