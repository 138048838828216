import {
  memo,
  useCallback,
  useState,
  MouseEvent,
  useEffect,
  useRef,
  useContext,
} from 'react';
import {
  useEventListener,
  useIsomorphicLayoutEffect,
  useWindowSize,
} from 'usehooks-ts';
import { clsx } from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

import { Link } from '~/components/global/Link';
import { useLayout } from '~/layouts';
import { DiscussionType } from '~/lib/discussions/types';
import { DISCUSSION } from '~/lib/discussions/constants';
import { PDPContext } from '~/lib/product/context';
import { DealBanner } from './DealBanner';
import { useRouter } from 'next/router';
import { getHashFromPath } from '~/lib/util';

const debounce = (fn: (...args: any[]) => void, delay: number) => {
  let timerId: any = null;
  return (...args: any[]) => {
    clearTimeout(timerId);
    timerId = setTimeout(() => fn(...args), delay);
  };
};

interface DealNavigationProps {
  sections: any[];
  onChange?: (id: string) => void;
  selectedTab: DiscussionType;
  setIsCommentsSection: (isCommentsSection: boolean) => void;
}

export const DealNavigation = memo(function DealNavigation({
  sections,
  onChange,
  selectedTab,
  setIsCommentsSection,
}: DealNavigationProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [current, setCurrent] = useState('');
  const [prevTop, setPrevTop] = useState(0);
  const [isBrowserScrolling, setIsBrowserScrolling] = useState(true);
  const navContainer = useRef<HTMLDivElement>(null);
  const itemRefs = useRef(new Map());
  const buttonWidth = 32;
  const { width } = useWindowSize();

  const { deal, showTimer } = useContext(PDPContext);

  // states for the left and right arrows
  const [isLeftArrowVisible, setIsLeftArrowVisible] = useState(false);
  const [isRightArrowVisible, setIsRightArrowVisible] = useState(true);

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const { setHideHeader } = useLayout();

  const highlightSection = useCallback(
    (id: string) => {
      const discussionsTabs = [
        DISCUSSION.QUESTIONS as string,
        DISCUSSION.REVIEWS as string,
      ];

      if (discussionsTabs.includes(id) && onChange) {
        onChange(id);
      }

      if (id && id !== current) {
        setCurrent(id);

        if (![...discussionsTabs, 'from-the-founders'].includes(id)) {
          setIsCommentsSection(false);
        } else if (id === 'from-the-founders') {
          setIsCommentsSection(true);
        }

        if (!isFirstLoad) {
          history.replaceState(history.state, '', `#${id}`);
        }
      } else if (!id && current) {
        setCurrent('');

        if (!isFirstLoad) {
          history.replaceState(
            history.state,
            '',
            window.location.pathname + window.location.search,
          );
        }
      }

      setIsBrowserScrolling(true);
    },
    [onChange, current, isFirstLoad, setIsCommentsSection],
  );

  const highlightAppropriateArea = useCallback(() => {
    const scrollY = window.pageYOffset;
    const entry = sections.find((section) => {
      const elementMatches = section.elements.find((el: HTMLElement) => {
        const sectionHeight = el.offsetHeight;
        const sectionTop = el.offsetTop;

        // This prevents from defaulting to questions when scrolling when reviews is selected
        if (
          (section.id === DISCUSSION.QUESTIONS &&
            selectedTab === DISCUSSION.REVIEWS) ||
          (section.id === DISCUSSION.REVIEWS &&
            selectedTab === DISCUSSION.QUESTIONS)
        ) {
          return false;
        }

        return (
          scrollY + 200 > sectionTop &&
          scrollY < sectionTop + sectionHeight - 68
        );
      });

      return !!elementMatches;
    });

    highlightSection(entry?.id);
  }, [highlightSection, sections, selectedTab]);

  useIsomorphicLayoutEffect(() => {
    // highlightAppropriateArea();
  }, [sections, highlightAppropriateArea]);

  const onScroll = debounce(() => {
    const isScrollingDown = window.scrollY > prevTop;
    setIsOpen(window.scrollY >= 80);
    setHideHeader(window.scrollY >= 200 && isScrollingDown);

    if (isBrowserScrolling) {
      setIsBrowserScrolling(false);
      return;
    }

    highlightAppropriateArea();
    setPrevTop(window.scrollY);
  }, 10);

  useEventListener('scroll', onScroll);

  const scrollTo = useCallback(
    (id: string, event?: MouseEvent<HTMLAnchorElement>) => {
      event?.preventDefault();

      const el = document.getElementById(id);
      const navbarHeight = 68;

      if (el) {
        highlightSection(id);
        window.scrollTo({ top: el?.offsetTop - navbarHeight });
      }
    },
    [highlightSection],
  );

  const { asPath } = useRouter();

  useEffect(() => {
    if (isFirstLoad) {
      const hash = getHashFromPath(asPath);

      if (hash && sections.some((section) => section.id === hash)) {
        scrollTo(hash);
      }

      setIsFirstLoad(false);
    }
  }, [asPath, sections, scrollTo, isFirstLoad]);

  useEffect(() => {
    setCurrent(selectedTab);
  }, [selectedTab]);

  useEffect(() => {
    const selectedElement = itemRefs.current.get(current);
    if (selectedElement && navContainer.current) {
      navContainer.current.scrollLeft =
        selectedElement.offsetLeft - navContainer.current.offsetLeft;
    }
  }, [current]);

  useEffect(() => {
    const { current } = navContainer;

    const checkArrowsVisibility = () => {
      if (!current) return;

      if (!sections || !sections.length) return;

      const firstItem = itemRefs.current.get(sections[0].id);
      const lastItem = itemRefs.current.get(sections[sections.length - 1].id);

      setIsLeftArrowVisible(firstItem.offsetLeft < current.scrollLeft + 16);
      setIsRightArrowVisible(
        lastItem.offsetLeft + lastItem.offsetWidth >
          current.scrollLeft + current.offsetWidth + 16,
      );
    };

    // initial check
    checkArrowsVisibility();

    // add event listener
    current?.addEventListener('scroll', checkArrowsVisibility);

    // clean up
    return () => {
      current?.removeEventListener('scroll', checkArrowsVisibility);
    };
  }, [sections, width]);

  const scrollToLeftItem = () => {
    if (!navContainer.current) return;
    const currentScrollPosition = navContainer.current.scrollLeft;
    for (const section of sections.slice().reverse()) {
      const item = itemRefs.current.get(section.id);
      if (item.offsetLeft < currentScrollPosition) {
        navContainer.current.scrollTo({
          left: item.offsetLeft - buttonWidth,
          behavior: 'smooth',
        });
        break;
      }
    }
  };

  const scrollToRightItem = () => {
    if (!navContainer.current) return;
    const currentScrollPosition = navContainer.current.scrollLeft;
    const containerRightPosition =
      currentScrollPosition + navContainer.current.offsetWidth;
    for (const section of sections) {
      const item = itemRefs.current.get(section.id);
      const itemRightPosition = item.offsetLeft + item.offsetWidth;
      if (itemRightPosition > containerRightPosition) {
        navContainer.current.scrollTo({
          left: item.offsetLeft - buttonWidth,
          behavior: 'smooth',
        });
        break;
      }
    }
  };

  const isEndingSoonBanner = deal.banner_details?.text === 'Ending soon';

  return (
    <div
      id="pdp-sections"
      className={clsx('fixed inset-x-0 z-9 w-full bg-white shadow-pdp', {
        open: isOpen,
      })}
    >
      {/* Desktop ending soon banner */}
      {isEndingSoonBanner && (
        <DealBanner
          banner={deal.banner_details}
          // eslint-disable-next-line tailwindcss/no-custom-classname
          wrapperClassName={clsx(
            'hidden px-2 py-1 md:flex',
            `bg-${deal.banner_details.bg_color}`,
            deal.banner_details.text_color,
          )}
          showCampaignBanner={false}
          showEndingSoon={true}
          showTimer={showTimer}
        />
      )}
      <div className="w-full overflow-hidden">
        <div className="relative mx-auto max-w-7xl px-4 md:px-8">
          {isLeftArrowVisible && (
            <button
              className="absolute left-0 h-full bg-white p-2 opacity-90"
              onClick={scrollToLeftItem}
            >
              <span className="sr-only">Arrow left</span>
              <FontAwesomeIcon icon={faAngleLeft} className="h-4 w-4" />
            </button>
          )}
          {isRightArrowVisible && (
            <button
              className="absolute right-0 h-full bg-white p-2 opacity-90"
              onClick={scrollToRightItem}
            >
              <span className="sr-only">Arrow right</span>
              <FontAwesomeIcon icon={faAngleRight} className="h-4 w-4" />
            </button>
          )}
          <div
            className="appsumo-no-scrollbar overflow-x-scroll"
            ref={navContainer}
          >
            <ul className="flex gap-x-8 whitespace-nowrap">
              {sections.map((section) => (
                <li
                  key={section.id}
                  ref={(el) => itemRefs.current.set(section.id, el)}
                >
                  <Link
                    href={`#${section.id}`}
                    onClick={(e) => scrollTo(section.id, e)}
                    className={`${
                      section.id === current
                        ? 'border-b-4 border-blue-500 text-gray-900'
                        : 'text-gray-800'
                    } block whitespace-nowrap py-4 font-header text-xl font-semibold`}
                  >
                    {section.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
});
