import { memo, useContext } from 'react';

import { FOMO_QUANTITY_REMAINING } from '~/constants/product';
import { useLimitedQuantity } from '~/hooks/limitedQuantity';
import { PDPContext } from '~/lib/product/context';

export default memo(function QuantityRemaining({
  isMultiTier,
}: {
  isMultiTier?: boolean;
}) {
  const { deal } = useContext(PDPContext);
  const { quantityRemaining, percentClaimed } = useLimitedQuantity(deal);

  if (percentClaimed < 0) return null;

  return (
    <div
      className={`mx-auto mt-1 mb-3 w-fit text-sm lg:mx-0 ${
        isMultiTier ? '' : ' md:mb-0'
      }`}
    >
      <div className="mb-2 h-1 overflow-hidden rounded-full bg-gravel">
        <div
          className="h-full rounded-full bg-brick"
          style={{
            width: `${percentClaimed}%`,
          }}
        />
      </div>
      <div className="flex items-center gap-2">
        <div className="inline-block rounded bg-brick py-1 px-2 font-bold text-white">
          Limited availability
        </div>
        {quantityRemaining < FOMO_QUANTITY_REMAINING ? (
          <span>
            <span className="font-bold">{quantityRemaining}</span>{' '}
            {deal.uses_codes ? 'code' : 'license'}
            {quantityRemaining !== 1 ? 's' : ''} remaining
          </span>
        ) : (
          <span>
            <span className="font-bold">{`${percentClaimed}%`}</span>{' '}
            {'claimed'}
          </span>
        )}
      </div>
    </div>
  );
});
