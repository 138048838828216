import { memo, useRef } from 'react';
import type { RefObject } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Heading } from '@appsumo/dorado-react';
import { useEvent } from '~/lib/events';
import { OverviewGuarantee } from '~/components/product/OverviewGuarantee';
import { SmartCollectionZone } from '~/components/smartcollections/components/SmartCollectionZone';
import { ZoneType } from '~/components/smartcollections/components/types';
import CoreFeatures from '~/components/product/PlansAndFeatures/CoreFeatures';

type DealTerm = {
  id: number;
  text: string;
};

interface DealFeaturesProps {
  terms: DealTerm[];
  commonFeatures: DealTerm[];
  moneyBackCalloutType: string;
  refundableDays: number;
  isFree: boolean;
  useV2TermsFeaturesUI: boolean;
}

function PlansAndFeaturesList({
  title,
  terms,
}: {
  title: string;
  terms: DealTerm[];
}) {
  return (
    <div>
      <Heading.H3 className="mb-4 font-header font-semibold">
        {title}
      </Heading.H3>
      <ul className="flex flex-col gap-y-2">
        {terms.map((term) => (
          <li key={term.id} className="flex gap-x-2 leading-6">
            <FontAwesomeIcon icon={faCheck} className="mt-1 text-dollar" />
            <span dangerouslySetInnerHTML={{ __html: term.text }} />
          </li>
        ))}
      </ul>
    </div>
  );
}

export const DealFeatures = memo(function DealFeatures({
  terms,
  commonFeatures,
  moneyBackCalloutType,
  refundableDays,
  isFree,
  useV2TermsFeaturesUI,
}: DealFeaturesProps) {
  const dealTerms = PlansAndFeaturesList({
    title: 'Deal terms & conditions',
    terms,
  });

  const scrollIntoView = (elementRef: RefObject<HTMLDivElement>) => {
    const stickyTabsHeight =
      document.getElementById('pdp-sections')?.clientHeight || 0;

    if (elementRef?.current) {
      window.scrollTo({
        behavior: 'smooth',
        top:
          elementRef.current.getBoundingClientRect().top -
          document.body.getBoundingClientRect().top -
          stickyTabsHeight,
      });
    }
  };

  const pricePlanSection = useRef<HTMLDivElement>(null);
  useEvent('scroll:pricePlanSection', () => {
    scrollIntoView(pricePlanSection);
  });

  return (
    <section
      id="pricePlans"
      className={`appsumo-style-links pt-8${!useV2TermsFeaturesUI && ' pb-8'}`}
      ref={pricePlanSection}
    >
      <SmartCollectionZone zone={ZoneType.BEFORE_TERMS} />
      <Heading.H2 className="mb-2 font-header font-bold">
        Plans &amp; features
      </Heading.H2>
      {useV2TermsFeaturesUI ? (
        <CoreFeatures features={commonFeatures} />
      ) : (
        <div className="flex flex-col gap-y-3">
          {dealTerms}
          <OverviewGuarantee
            calloutType={moneyBackCalloutType}
            refundableDays={refundableDays}
            isFree={isFree}
          />
          {commonFeatures?.length > 0 &&
            PlansAndFeaturesList({
              title: 'Features included in all plans',
              terms: commonFeatures,
            })}
        </div>
      )}
    </section>
  );
});
