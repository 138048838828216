import { memo, useContext } from 'react';

import useSWR from 'swr';

import clsx from 'clsx';
import { Heading } from '@appsumo/dorado-react';
import { Grid } from './Grid';
import { PDPContext } from '~/lib/product/context';

import {
  generateApiUrl,
  getZoneConditions,
  formatConditions,
} from '~/lib/smartcollections/utils';
import { ZoneType } from '~/components/smartcollections/components/types';
import ProductCarousel from '~/components/smartcollections/components/ProductCarousel';

import useUser from '~/lib/user';

export const SmartCollectionZone = memo(function SmartCollectionZone({
  zone,
  className = '',
}: {
  zone: ZoneType;
  className?: string;
}) {
  const { deal, scZoneVariant } = useContext(PDPContext);
  const { user } = useUser();
  const conditions = getZoneConditions(deal);
  const formattedConditions = formatConditions(conditions);
  const isZoneEnabled = scZoneVariant === 'enabled';
  const querySWR = isZoneEnabled && deal.buy_button?.is_ended;

  const { data: collectionZones } = useSWR(
    querySWR &&
      `/api/smartcollections/zone/${zone}/?format=json&template_slug=${deal.slug}&conditions=${formattedConditions}`,
  );

  const isGridComponent = collectionZones?.component_type === 'grid';

  if (isGridComponent) {
    collectionZones.component_product_num = 3;
  }

  const collectionApiUrl = generateApiUrl(collectionZones, user);
  const { data: collectionDealData } = useSWR(querySWR && collectionApiUrl);

  if (
    !isZoneEnabled ||
    !deal.buy_button?.is_ended ||
    !collectionZones?.id ||
    !collectionDealData?.deals?.length
  )
    return null;

  return (
    <div
      className={clsx(className, 'my-4 md:my-16', {
        '!mt-0': [ZoneType.PDP_TOP, ZoneType.BEFORE_TERMS].includes(zone),
      })}
    >
      {isGridComponent ? (
        <>
          <Heading.H3 className="mb-4 !text-2xl">
            <span className="text-dollar">Explore</span>&nbsp;
            <span className="text-midnight">related deals</span>
          </Heading.H3>
          <Grid deals={collectionDealData.deals} />
        </>
      ) : (
        <ProductCarousel
          id={collectionZones.id}
          deals={collectionDealData.deals}
          href={`/collections/${collectionZones.slug}`}
          isCrossSell
        />
      )}
    </div>
  );
});
