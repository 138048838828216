import { memo, useCallback, useContext, useMemo, useState } from 'react';

import InformationBox from '~/components/ui/InformationBox';
import { useUserStatus } from '~/hooks/userStatus';
import {
  QUESTION_EVENT_TYPE_DELETE,
  QUESTION_EVENT_TYPE_REPLY,
  QUESTION_THREAD_ADD_OR_REMOVE_EVENT_NAME,
} from '~/lib/discussions/constants';
import {
  DISCUSSION_STATUS_APPROVED,
  IQuestionCommentProps,
  IQuestionThreadProps,
  QuestionV2,
} from '~/lib/discussions/types';
import { useEventDispatch } from '~/lib/events';
import { PDPContext } from '~/lib/product/context';
import useUser from '~/lib/user';
import { LOGIN_URL } from '~/lib/util/constants';
import { Deal } from '~/types/deal';
import QuestionComment from './QuestionComment';
import QuestionCommentInput from './QuestionCommentInput';

function QuestionThread({
  questionId,
  questionUrl,
  comments = [],
  onReply = () => {},
}: Readonly<IQuestionThreadProps>) {
  const [deletedComments, setDeletedComments] = useState<number[]>([]);
  const dispatchEvent = useEventDispatch();

  const filteredComments = useMemo(() => {
    return comments.filter((comment) => !deletedComments.includes(comment.id));
  }, [comments, deletedComments]);

  const { user } = useUser();
  const { deal }: { deal: Deal } = useContext(PDPContext);
  const { userStatus } = useUserStatus(deal.id);

  const isPartner = useMemo(() => {
    return deal?.partner_user_ids?.includes(user?.id ?? 0);
  }, [deal?.partner_user_ids, user?.id]);

  const onSuccessCallback = useCallback(
    (comment: QuestionV2) => {
      onReply({
        ...comment,
        status:
          isPartner || !!userStatus?.staff_moderator
            ? DISCUSSION_STATUS_APPROVED
            : undefined,
      } as QuestionV2);

      dispatchEvent(QUESTION_THREAD_ADD_OR_REMOVE_EVENT_NAME, {
        type: QUESTION_EVENT_TYPE_REPLY,
        questionId,
      });
    },
    [
      onReply,
      isPartner,
      userStatus?.staff_moderator,
      dispatchEvent,
      questionId,
    ],
  );

  const onDeleteCallback = useCallback(
    (id: number) => {
      setDeletedComments([...deletedComments, id]);

      dispatchEvent(QUESTION_THREAD_ADD_OR_REMOVE_EVENT_NAME, {
        type: QUESTION_EVENT_TYPE_DELETE,
        questionId,
      });
    },
    [deletedComments, dispatchEvent, questionId],
  );

  return (
    <div
      data-id={questionId}
      data-testid="question-thread"
      className="w-full space-y-4 pt-4"
    >
      {user?.is_authenticated && (
        <QuestionCommentInput
          comment={{ id: questionId }}
          dealId={deal.id}
          placeholder="Add a comment..."
          onSuccess={onSuccessCallback}
        />
      )}

      {!user?.is_authenticated && (
        <InformationBox
          text="Log in to join the conversation"
          helpText="Only logged in users can ask and respond to questions"
          className="max-md:justify-center"
        >
          <a
            href={`${LOGIN_URL}?next=${questionUrl}`}
            className="rounded-full border-2 border-deep-ocean px-6 py-2 font-header font-bold text-deep-ocean hover:bg-skys-the-limit focus-visible:border-black-pearl focus-visible:bg-skys-the-limit focus-visible:text-midnight focus-visible:outline-none active:border-deep-ocean active:bg-bolt active:text-white"
          >
            Log in
          </a>
        </InformationBox>
      )}

      {filteredComments?.map((comment: IQuestionCommentProps) => (
        <QuestionComment
          key={comment.id}
          deletedCallback={() => onDeleteCallback(comment.id)}
          {...comment}
          questionId={questionId}
        />
      ))}
    </div>
  );
}

export default memo(QuestionThread);
