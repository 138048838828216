import { memo, useMemo } from 'react';

enum CalloutType {
  MONEY_BACK = 'money-back',
  REDEEM_BY = 'redeem-by',
  ANNUAL_FREE = 'annual-free',
  MONTH_FREE = 'month-free',
}

export const RefundGuaranteeText = memo(function RefundGuaranteeText({
  calloutType,
  refundableDays,
  isFree,
}: {
  calloutType: string;
  refundableDays: number;
  isFree: boolean;
}) {
  const isMoneyBackGuarantee = useMemo(
    () => calloutType === CalloutType.MONEY_BACK,
    [calloutType],
  );
  const hasFreeIntervalPlan = useMemo(
    () => calloutType.includes('-free'),
    [calloutType],
  );
  const isRedeemBy = useMemo(
    () => calloutType === CalloutType.REDEEM_BY,
    [calloutType],
  );

  const intervalText = useMemo(() => {
    switch (calloutType) {
      case CalloutType.ANNUAL_FREE:
        return 'Get 1 year of access.';
      case CalloutType.MONTH_FREE:
        return 'Get 1 month of access.';
      default:
        return 'Get access now.';
    }
  }, [calloutType]);

  const refundTime = useMemo(() => {
    if (refundableDays === 60) {
      return '2 months';
    } else if (refundableDays === 30) {
      return '1 month';
    } else {
      return `${refundableDays} day${refundableDays === 1 ? '' : 's'}`;
    }
  }, [refundableDays]);

  return (
    <>
      {isMoneyBackGuarantee && (
        <p>
          <strong className="font-bold">
            {refundableDays} day money-back guarantee.
          </strong>{' '}
          Try it out for {refundTime} to make sure it&apos;s right for you!
        </p>
      )}
      {hasFreeIntervalPlan && (
        <p>
          <strong>{intervalText}</strong> Simply redeem the product within 60
          days.
        </p>
      )}
      {isRedeemBy && (
        <p>
          <strong>Get lifetime access.</strong> Just download and save within 60
          days
          {!isFree && ' of purchase'}.
        </p>
      )}
    </>
  );
});
