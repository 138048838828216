import { FieldValues } from 'react-hook-form';
import * as yup from 'yup';
import {
  QUESTION_COMMENT_MAX_LENGTH,
  QUESTION_TITLE_MAX_LENGTH,
  REPLY_MAX_LENGTH,
  REVIEW_TITLE_MAX_LENGTH,
} from '~/lib/discussions/constants';
import { DiscussionComment } from '~/lib/discussions/types';
import { Deal } from '~/types/deal';

export const YupReplySchema: yup.ObjectSchema<FieldValues> = yup
  .object()
  .shape({
    comment: yup.string().max(REPLY_MAX_LENGTH).required(),
    notify: yup.boolean(),
  });

export const YupQuestionSchema: yup.ObjectSchema<FieldValues> = yup
  .object()
  .shape({
    comment: yup.string().required(),
    notify: yup.boolean(),
  });

export const YupReviewSchema: yup.ObjectSchema<FieldValues> = yup
  .object()
  .shape({
    title: yup.string().required(),
    rating: yup.number().min(1).max(5).required(),
    comment: yup.string().required(),
    notify: yup.boolean(),
  });

export const YupLeaveReviewSchema: yup.ObjectSchema<FieldValues> = yup
  .object()
  .shape({
    title: yup.string().required().max(REVIEW_TITLE_MAX_LENGTH),
    rating: yup.number().min(1).max(5).required(),
    comment: yup.string().required().max(REPLY_MAX_LENGTH),
  });

export const YupAskQuestionSchema: yup.ObjectSchema<FieldValues> = yup
  .object()
  .shape({
    title: yup.string().required().max(QUESTION_TITLE_MAX_LENGTH),
    comment: yup.string().max(QUESTION_COMMENT_MAX_LENGTH),
    notify: yup.boolean(),
  });

export interface QuestionSchema
  extends yup.InferType<typeof YupQuestionSchema> {
  title?: never;
  rating?: never;
}

export interface ReviewSchema extends yup.InferType<typeof YupReviewSchema> {}

export type QuestionOrReviewSchema = QuestionSchema | ReviewSchema;

export type ReviewStatsResponse = {
  needs_reply: number;
  needs_moderation: number;
  review_details: {
    review_count: number;
    average_rating: string;
    would_recommend: number;
    review_count_1_tacos: number;
    review_count_2_tacos: number;
    review_count_3_tacos: number;
    review_count_4_tacos: number;
    review_count_5_tacos: number;
  };
};

export interface AggregateProps {
  deal: Deal;
  comments: DiscussionComment;
}
