import { memo } from 'react';
import Image from 'next/image';

import { DealListingType } from '~/components/sku/types';
import { Popover } from '~/components/ui';

import select from '~/public/appsumo-select.svg';
import originals from '~/public/originals.svg';
import question from '~/public/question-mark.svg';
import plusExclusive from '~/public/appsumo-plus-exclusive.svg';

export const ListingTypeLogo = memo(function ListingTypeLogo({
  type,
  className,
}: {
  type: DealListingType;
  className?: string;
}) {
  if (
    ![
      DealListingType.ORIGINAL,
      DealListingType.PLUS_EXCLUSIVE,
      DealListingType.SELECT,
    ].includes(type)
  ) {
    return null;
  }

  return (
    <div className={`my-1 flex gap-x-2 max-lg:mx-auto md:mt-0 ${className}`}>
      {type === DealListingType.ORIGINAL && (
        <Image src={originals} height="15" alt="AppSumo Originals" />
      )}

      {type === DealListingType.PLUS_EXCLUSIVE && (
        <>
          <Image src={plusExclusive} height="15" alt="AppSumo Select" />
          <Popover
            content={`Exclusive offers on the hottest software, expertly tested, vetted, and brought to you by the AppSumo team.`}
          >
            <div>
              <Image
                src={question}
                height="16"
                alt=""
                className="cursor-pointer"
              />
            </div>
          </Popover>
        </>
      )}
      {type === DealListingType.SELECT && (
        <>
          <Image src={select} height="15" alt="AppSumo Select" />
          <Popover
            content={`Exclusive offers on the hottest software, expertly tested, vetted, and brought to you by the AppSumo team.`}
          >
            <div>
              <Image
                src={question}
                height="16"
                alt=""
                className="cursor-pointer"
              />
            </div>
          </Popover>
        </>
      )}
    </div>
  );
});
