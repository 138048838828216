import { TacoRating } from '@appsumo/dorado-react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { memo, useContext, useMemo } from 'react';

import ToggleText from '~/components/ui/ToggleText';
import { DealCommentsContext } from '~/contexts/deal-comments';
import { highlightText } from '~/lib/util';

export interface IDiscussionReviewInfo {
  rating: number;
  userMemberDate: Date | null;
  userDealsBought: number | null;
  publishDate: Date;
  title: string;
  content: string;
  showText?: boolean;
  isCollapsed?: boolean;
}

function ReviewInfo({
  rating,
  userMemberDate,
  userDealsBought,
  publishDate,
  title,
  content,
  showText,
  isCollapsed = false,
}: Readonly<IDiscussionReviewInfo>) {
  const { query } = useContext(DealCommentsContext);

  const highlightTitle = useMemo(() => {
    if (!query || !title) {
      return title;
    }

    return highlightText(title, query);
  }, [query, title]);

  return (
    <div className="w-full space-y-2" data-testid="discussion-review-info">
      <TacoRating averageRating={rating} size="md" />

      <div className="mt-2 flex flex-wrap items-center gap-x-2 gap-y-1 text-sm text-grace">
        {!!userMemberDate && (
          <>
            <span>
              Member since: {dayjs(userMemberDate).format('MMM YYYY')}
            </span>
            <span
              className="h-1.5 w-1.5 rounded-full bg-gravel"
              aria-hidden="true"
            />
          </>
        )}
        {!!userDealsBought && (
          <>
            <span>Deals bought: {userDealsBought}</span>
            <span
              className="h-1.5 w-1.5 rounded-full bg-gravel"
              aria-hidden="true"
            />
          </>
        )}
        <span>Posted: {dayjs(publishDate).format('MMM D, YYYY')}</span>
      </div>

      <div className="space-y-1">
        {highlightTitle && (
          <p
            className={clsx(
              'mt-2 font-header text-xl font-bold leading-6 text-black-pearl',
              { 'line-clamp-1': isCollapsed },
            )}
          >
            <span dangerouslySetInnerHTML={{ __html: highlightTitle }} />
          </p>
        )}
        {showText && (
          <ToggleText
            initExpanded
            text={content}
            highlight={query}
            maxLength={450}
            className="text-midnight"
          />
        )}
      </div>
    </div>
  );
}

export default memo(ReviewInfo);
