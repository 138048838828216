import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { memo } from 'react';
import clsx from 'clsx';

import { Popover } from './Popover';

function InformationBox({
  text,
  helpText,
  children,
  className,
}: Readonly<{
  text: string;
  helpText?: string;
  children?: React.ReactNode;
  className?: string;
}>) {
  return (
    <div
      data-testid="information-box"
      className={clsx(
        'flex flex-row items-center gap-4 rounded border border-gravel bg-white p-4',
        children ? 'justify-between' : 'justify-center',
        className ?? '',
      )}
    >
      <div>
        {text}
        {helpText && (
          <Popover content={helpText} positions={['top']}>
            <FontAwesomeIcon
              icon={faQuestionCircle}
              className="ml-0.5 cursor-pointer px-1.5 text-grace"
            />
          </Popover>
        )}
      </div>
      {!!children && <div className="flex shrink-0">{children}</div>}
    </div>
  );
}

export default memo(InformationBox);
