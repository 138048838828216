import clsx from 'clsx';
import dayjs from 'dayjs';
import Image from 'next/image';
import { memo, useContext, useMemo } from 'react';

import { useRouter } from 'next/router';
import { useMediaQuery } from 'usehooks-ts';
import ToggleText from '~/components/ui/ToggleText';
import { DealCommentsContext } from '~/contexts/deal-comments';
import {
  DISCUSSION_STATUS_APPROVED,
  DiscussionStatus,
} from '~/lib/discussions/types';
import { isIndexablePage } from '~/lib/util/constants';
import { CommentV2Type } from '~/static/discussions/enums';
import DiscussionOptions from './DiscussionOptions';
import { DISCUSSION_TOGGLE_TEXT_MAX_LENGTH } from '~/lib/discussions/constants';
import { getAvatarProps } from '~/lib/discussions/utils';

export interface ICreatorReplyProps {
  id: number;
  avatarUrl: string;
  creatorUsername: string;
  replyDate: string;
  replyText: string;
  status: DiscussionStatus;
  allowOptions: boolean;
  deleteReplyUrl: string;
  className?: string;
  onDelete?: () => void;
}

/**
 * Creator Reply Component
 *
 * @param avatarUrl - The avatar url of the creator
 * @param creatorUsername - The username of the creator
 * @param replyDate - The date the reply was posted
 * @param replyText - The text of the reply
 */

function CreatorReply({
  avatarUrl,
  creatorUsername,
  replyDate,
  replyText,
  status,
  allowOptions,
  deleteReplyUrl,
  className = '',
  onDelete,
}: Readonly<ICreatorReplyProps>): JSX.Element {
  const { asPath } = useRouter();
  const isDesktop = useMediaQuery('(min-width: 768px)');

  const { query } = useContext(DealCommentsContext);

  const { alt: alt, ...creatorAvatarProps } = useMemo(
    () => getAvatarProps(avatarUrl, creatorUsername, 24),
    [avatarUrl, creatorUsername],
  );

  return (
    <div
      className={clsx([
        'flex flex-col items-start gap-2 self-stretch rounded-lg  p-4',
        status === DISCUSSION_STATUS_APPROVED ? 'bg-sky-100' : 'bg-iceberg',
      ])}
      data-testid="discussion-creator-reply"
    >
      <div className="flex w-full items-center justify-between gap-4">
        <span
          className={clsx([
            'text-xs font-semibold',
            status === DISCUSSION_STATUS_APPROVED ? 'text-bolt' : 'text-dollar',
          ])}
          data-testid="creator-reply-label"
        >
          {status === DISCUSSION_STATUS_APPROVED ? 'Founder Team' : 'Sumo'}
        </span>
        {allowOptions && (
          <div className="shrink-0">
            <DiscussionOptions
              deleteUrl={deleteReplyUrl}
              discussionType={CommentV2Type.Reply}
              deletedCallback={onDelete}
            />
          </div>
        )}
      </div>
      <div className="flex w-full items-center gap-2">
        {/* Truncate username needs an specific width to work,
         so we set the parent to be full width minus the avatar image width */}
        <div className="w-[calc(100%_-_55px)]">
          <div className={'flex flex-row items-center gap-x-2'}>
            <Image
              className="h-[24px] w-[24px] shrink-0 rounded-full object-cover"
              alt={alt}
              {...creatorAvatarProps}
              data-testid="creator-avatar"
            />
            <p
              className="-mb-1 truncate font-bold"
              data-testid="creator-username"
            >
              {creatorUsername}
            </p>

            <span
              className="h-1.5 w-1.5 rounded-full bg-gravel"
              aria-hidden="true"
            />
            <span
              className="shrink-0 text-sm text-grace"
              data-testid="creator-posted-date"
            >
              {dayjs(replyDate).format('MMM D, YYYY')}
            </span>
          </div>
        </div>
      </div>

      <ToggleText
        initExpanded={isDesktop || isIndexablePage(asPath.split('?')[0])}
        className={className}
        text={replyText}
        maxLength={
          isDesktop || isIndexablePage(asPath.split('?')[0])
            ? replyText.length + 1
            : DISCUSSION_TOGGLE_TEXT_MAX_LENGTH
        }
        highlight={query}
      />
    </div>
  );
}

export default memo(CreatorReply);
