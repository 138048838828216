import Flicking from '@egjs/react-flicking';
import Image from 'next/image';
import { memo, useContext, useMemo } from 'react';

import { AlignType } from '@appsumo/dorado-react';
import ShareModal from '~/components/global/ShareModal';
import { PDPContext } from '~/lib/product/context';
import type { Deal } from '~/types/deal';

const DEFAULT_THUMBNAIL_WIDTH = 320;
const DEFAULT_THUMBNAIL_HEIGHT = 180;

export interface GalleryImageProps {
  src: string;
  alt?: string | null;
}

export const MobileGallery = memo(function MobileGallery({
  deal,
}: {
  deal: Deal;
}) {
  const product = deal?.products[0];
  const { story } = product;
  const { galleryRef }: { galleryRef?: React.RefObject<HTMLDivElement> } =
    useContext(PDPContext);

  const images: GalleryImageProps[] = useMemo(() => {
    if (deal.media_url) {
      return [
        {
          src: deal.media_url,
          alt: 'Featured image',
        },
        ...story.images.map((image) => ({
          src: image.image,
          alt: image.alt_text,
        })),
      ];
    }

    return story.images.map((image) => ({
      src: image.image,
      alt: image.alt_text,
    }));
  }, [deal, story]);

  const panels = useMemo(
    () =>
      images.map((image, index) => (
        <div
          key={image.src + image.alt}
          className="relative mr-5 h-auto cursor-pointer rounded"
        >
          <Image
            // Request 30% more of width and height to avoid downloading
            // the full image but still preserve the quality,
            // width on md is 640px and height on md is 360 (note the className attr).
            src={`${image.src}?width=832&height=468&aspect_ratio=16:9&optimizer=gif`}
            alt={image.alt || 'Gallery image'}
            width={DEFAULT_THUMBNAIL_WIDTH}
            height={DEFAULT_THUMBNAIL_HEIGHT}
            unoptimized
            className="h-[180px] w-[320px] max-w-[75vw] rounded md:h-[360px] md:w-[640px]"
            priority={index === 0}
            onClick={() => {
              (galleryRef?.current as any)?.showImageAt(index);
            }}
          />
          {index === 0 && (
            <div className="absolute top-0 right-0 m-2 rounded-full bg-gray-300">
              <ShareModal
                deal={deal}
                showButtonText={false}
                className="m-2 lg:hidden"
              />
            </div>
          )}
        </div>
      )),
    [images, deal, galleryRef],
  );

  return (
    <Flicking
      align={AlignType.PREV}
      bound
      resizeOnContentsReady
      preventClickOnDrag
    >
      {panels}
    </Flicking>
  );
});
