import clsx from 'clsx';
import { createElement } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const statusActionBgVariant = {
  dollar: 'bg-dollar-60',
  brick: 'bg-brick-light-60',
};

const statusActionColorVariant = {
  dollar: 'text-dollar',
  brick: 'text-brick',
};

type statusActionVariant = 'dollar' | 'brick';

export interface StatusActionProps {
  label: string;
  variant?: statusActionVariant;
  faIcon?: IconProp;
  enableAction?: boolean;
  onActionClick?: () => void;
  enableUndo?: boolean;
  undoLabel?: string;
  onUndoClick?: () => void;
}

function StatusAction({
  label,
  variant = 'dollar',
  faIcon,
  enableAction = false,
  onActionClick,
  enableUndo = false,
  undoLabel,
  onUndoClick,
}: StatusActionProps) {
  return createElement(
    enableAction && !enableUndo ? 'button' : 'div',
    {
      className: clsx(
        'flex items-center gap-4 rounded text-sm text-black-pearl',
        enableAction && !enableUndo
          ? `w-full justify-center bg-iceberg px-4 py-3 transition-colors hover:bg-midnight/10`
          : `justify-between px-3 py-2 ${statusActionBgVariant[variant]}`,
      ),
      onClick: enableAction && !enableUndo ? onActionClick : undefined,
    },
    <>
      <div className="flex items-center gap-1 font-medium">
        {faIcon && (
          <FontAwesomeIcon
            icon={faIcon}
            className={`text-base ${statusActionColorVariant[variant]}`}
          />
        )}
        {label}
      </div>

      {enableUndo && undoLabel && (
        <button type="button" className="hover:underline" onClick={onUndoClick}>
          {undoLabel}
        </button>
      )}
    </>,
  );
}

export default StatusAction;
