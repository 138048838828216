import { useCallback, useState } from 'react';
import clsx from 'clsx';
import { useInterval } from 'usehooks-ts';

interface CountdownTime {
  total: number;
  days: number;
  daysStr: string;
  hours: string;
  minutes: string;
  seconds: string;
}

const CountdownColumn = ({
  value,
  label,
  showLabel,
  useSeparator = true,
  // Using this to determine font size of the timer
  useWrapperFontSize = false,
  noPadding = false,
}: {
  value: string;
  label: string;
  showLabel: boolean;
  useSeparator?: boolean;
  useWrapperFontSize?: boolean;
  noPadding?: boolean;
}) => (
  <>
    <div
      className={clsx(
        'flex flex-col items-center',
        noPadding ? 'px-0' : 'px-1',
        showLabel ? 'w-11' : 'w-auto',
      )}
    >
      <div className={clsx(!useWrapperFontSize && 'text-2xl font-semibold')}>
        {value}
      </div>
      {showLabel && <div className="text-sm">{label}</div>}
    </div>
    {useSeparator && (
      <div className="flex flex-col items-center">
        <div>:</div>
        {showLabel && <div>&nbsp;</div>}
      </div>
    )}
  </>
);

const formatTime = (value: number | string): string => `0${value}`.slice(-2);

export function Countdown({
  deadline,
  displayLabels = true,
  hideHours = false,
  className = '',
  useWrapperFontSize = false,
  inline = false,
  noPadding = false,
}: {
  deadline: Date;
  displayLabels?: boolean;
  hideHours?: boolean;
  className?: string;
  useWrapperFontSize?: boolean;
  inline?: boolean;
  noPadding?: boolean;
}) {
  const parsedDate = Date.parse(deadline.toString());
  const [currentTime, setCurrentTime] = useState<CountdownTime>({
    total: 0,
    days: 0,
    daysStr: '',
    hours: '00',
    minutes: '00',
    seconds: '00',
  });

  const countdownCallback = useCallback(() => {
    const diff = parsedDate - Date.parse(new Date().toString());

    if (diff <= 0) return;

    const seconds = Math.floor((diff % (1000 * 60)) / 1000);
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    let hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let days = Math.floor(diff / (1000 * 60 * 60 * 24));

    // convert last day to hours
    if (days === 1) {
      hours += 24;
      days = 0;
    }

    if (diff > 0) {
      setCurrentTime({
        total: diff,
        days,
        daysStr: formatTime(days),
        hours: formatTime(hours),
        minutes: formatTime(minutes),
        seconds: formatTime(seconds),
      });
    } else {
      setCurrentTime({
        total: 0,
        days: 0,
        daysStr: '',
        hours: '00',
        minutes: '00',
        seconds: '00',
      });
    }
  }, [parsedDate]);

  // Start countdown interval
  useInterval(countdownCallback, 1000);

  return (
    <div
      className={clsx(
        'flex-row items-center justify-center',
        inline ? 'inline-flex' : 'flex',
        className,
      )}
    >
      {/* Countdown Days */}
      {!!currentTime.days && (
        <CountdownColumn
          value={currentTime.daysStr}
          label="Days"
          showLabel={displayLabels}
          useWrapperFontSize={useWrapperFontSize}
          noPadding={noPadding}
        />
      )}
      {/* Countdown Hours */}
      {!hideHours && (
        <CountdownColumn
          value={currentTime.hours}
          label="Hours"
          showLabel={displayLabels}
          useWrapperFontSize={useWrapperFontSize}
          noPadding={noPadding}
        />
      )}
      {/* Countdown Minutes */}
      <CountdownColumn
        value={currentTime.minutes}
        label="Mins"
        showLabel={displayLabels}
        useWrapperFontSize={useWrapperFontSize}
        noPadding={noPadding}
      />
      {/* Countdown Seconds */}
      <CountdownColumn
        value={currentTime.seconds}
        label="Secs"
        showLabel={displayLabels}
        useSeparator={false}
        useWrapperFontSize={useWrapperFontSize}
        noPadding={noPadding}
      />
    </div>
  );
}
