import {
  CSSProperties,
  memo,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import Image from 'next/image';

import { GlobalContext } from '~/contexts/global';
import { PDPContext } from '~/lib/product/context';
import { BannerDetails } from '~/types/deal';
import question from '~/public/question-mark.svg';
import { Countdown } from '~/components/common/Countdown';
import { Popover } from '~/components/ui/Popover';

dayjs.extend(isSameOrAfter);

export interface DealBannerProps {
  banner: BannerDetails;
  showCampaignBanner?: boolean;
  showEndingSoon?: boolean;
  showTimer?: boolean;
  className?: string;
  style?: CSSProperties;
  wrapperClassName?: string;
  isPricingBanner?: boolean;
}

const endsWithin = (
  deadline: string,
  num: number,
  metric: dayjs.ManipulateType,
) => {
  if (!deadline) return false;

  return dayjs(dayjs().add(num, metric)).isSameOrAfter(deadline);
};

export const PricingBanner = memo(function PricingBanner(
  props: DealBannerProps,
) {
  const { className } = props;

  const pricingClassName: string = useMemo(() => {
    return clsx('ml-auto mr-6 rounded-b-md px-2 pb-2 pt-1', className);
  }, [className]);

  return <DealBanner {...props} isPricingBanner className={pricingClassName} />;
});

export const DealBanner = memo(function DealBanner({
  banner,
  showCampaignBanner = true,
  showEndingSoon = false,
  showTimer = false,
  className,
  style,
  wrapperClassName,
  isPricingBanner = false,
}: DealBannerProps) {
  const { deal } = useContext(PDPContext);
  const {
    campaigns: { activeAutoCampaign },
  } = useContext(GlobalContext);
  const [bannerText, setBannerText] = useState<string>('');

  // this is a campaign banner if the banner text is 'Campaign badge' (the campaign is the only 'status' for this deal currently)
  //   OR if the banner svg is 'campaign' (the campaign is one of the 'status' for this deal) and this deal banner is specifically for
  //   the pricing card (isPricingBanner is true). This is because we want to show the other 'status' on mobile.
  const isCampaignBanner =
    banner.text === 'Campaign badge' ||
    (isPricingBanner && banner?.svg === 'campaign');
  const isEndingSoonBanner = banner.text === 'Ending soon';
  const svgAltText = isCampaignBanner ? activeAutoCampaign?.name : banner?.text;

  const SVGIcon = useMemo(() => {
    if (banner?.svg === 'campaign' && showCampaignBanner) {
      return activeAutoCampaign?.campaign_config?.banner_badge;
    }

    return null;
  }, [banner.svg, activeAutoCampaign, showCampaignBanner]);

  const endsWithinDay = useMemo(() => {
    return !!deal?.dates?.end_date && endsWithin(deal.dates.end_date, 1, 'day');
  }, [deal?.dates?.end_date]);

  const endsWithin5Mins = useMemo(() => {
    return (
      !!deal?.dates?.end_date && endsWithin(deal.dates.end_date, 5, 'minute')
    );
  }, [deal?.dates?.end_date]);

  useEffect(() => {
    if (isEndingSoonBanner) {
      if (endsWithinDay) {
        setBannerText('Hurry, deal ends in');
      } else {
        setBannerText(`Deal ends ${dayjs(deal?.dates?.end_date).fromNow()}`);
      }
    } else {
      setBannerText(banner.text);
    }
  }, [isEndingSoonBanner, banner.text, deal?.dates?.end_date, endsWithinDay]);

  const endDate = useMemo(() => {
    if (deal?.dates?.end_date) return new Date(deal?.dates?.end_date);
    return null;
  }, [deal?.dates?.end_date]);

  const displayTimer = useMemo(() => {
    if (isEndingSoonBanner) {
      return showTimer && endsWithinDay;
    }
    return showTimer;
  }, [isEndingSoonBanner, showTimer, endsWithinDay]);

  // Don't show ending soon banner only if there's no campaign for this deal
  if (!showEndingSoon && isEndingSoonBanner && !SVGIcon) return null;

  let bannerStyles = {} as CSSProperties;
  if (activeAutoCampaign && isCampaignBanner) {
    bannerStyles = {
      background: activeAutoCampaign.campaign_config.banner_color,
    };
  }

  if (!banner || (isCampaignBanner && !showCampaignBanner)) return <></>;

  return (
    <div className={clsx('justify-center', wrapperClassName)} style={style}>
      <div
        className={
          // eslint-disable-next-line tailwindcss/no-custom-classname
          clsx(
            `bg-${banner.bg_color} relative flex`,
            banner.text_color,
            className,
          )
        }
        style={bannerStyles}
      >
        {SVGIcon ? (
          <Image
            src={`${SVGIcon}?width=320`}
            alt={svgAltText || 'Banner icon'}
            className="!relative !h-4 !w-auto"
            unoptimized
            width={80}
            height={16}
          />
        ) : (
          <div>{bannerText}</div>
        )}
        {/* Tooltip popover */}
        {banner.tooltip && (
          <Popover
            content={banner.tooltip}
            className="border-forest bg-forest text-white"
          >
            <div className="my-auto ml-2">
              <Image src={question} height="16" alt="" />
            </div>
          </Popover>
        )}
      </div>
      {displayTimer && endDate && (
        <Countdown
          deadline={endDate}
          className={clsx('text-base', banner.text_color)}
          displayLabels={false}
          hideHours={endsWithin5Mins}
          useWrapperFontSize={true}
        />
      )}
    </div>
  );
});
