import {
  faCommentDots,
  faExclamationTriangle,
  faRedoAlt,
} from '@fortawesome/free-solid-svg-icons';
import { memo } from 'react';
import Tag from '~/components/ui/Tag';
import { DISCUSSION } from '~/lib/discussions/constants';
import {
  DISCUSSION_STATUS_APPROVED,
  DISCUSSION_STATUS_UNMODERATED,
  DiscussionStatus,
  DiscussionType,
} from '~/lib/discussions/types';

function DiscussionTag({
  status,
  isOwner = false,
  type,
}: Readonly<{
  status: DiscussionStatus;
  isOwner?: boolean;
  type?: DiscussionType;
}>) {
  return (
    <div
      data-testid="discussion-tag"
      className="flex flex-wrap items-center gap-2"
    >
      {status === DISCUSSION_STATUS_UNMODERATED && !isOwner && (
        <Tag label="Pending moderation" faIcon={faRedoAlt} />
      )}
      {status === DISCUSSION_STATUS_UNMODERATED && isOwner && (
        <Tag label="Needs moderation" faIcon={faExclamationTriangle} />
      )}
      {status === DISCUSSION_STATUS_APPROVED && (
        <Tag
          label={type === DISCUSSION.QUESTIONS ? 'Needs answer' : 'Needs reply'}
          variant="brick-light"
          faIcon={faCommentDots}
        />
      )}
    </div>
  );
}

export default memo(DiscussionTag);
