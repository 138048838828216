import { ReactElement, useState } from 'react';
import { useEventDispatch } from '~/lib/events';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

export default function BToBuy(): ReactElement {
  const dispatchEvent = useEventDispatch();
  const [userClosedBtoBuy, setUserClosedBtoBuy] = useState<boolean>(false);

  if (userClosedBtoBuy) return <></>;

  return (
    <div className="relative hidden md:block">
      <button
        className="fixed top-[205px] right-4 z-[999] rounded border border-gravel bg-iceberg py-2 px-4 text-black-pearl shadow-md"
        onClick={() => {
          dispatchEvent('clicked:bToBuy');
        }}
        data-testid="b-to-buy-btn"
      >
        Press&nbsp;<span className="font-medium">B</span>&nbsp;anytime to buy
      </button>
      <button
        className="fixed top-[197px] right-2 z-[1000] inline-flex rounded-full bg-white"
        onClick={() => setUserClosedBtoBuy(true)}
        data-testid="b-to-buy-close"
      >
        <FontAwesomeIcon icon={faTimesCircle} className="text-slate" />
      </button>
    </div>
  );
}
