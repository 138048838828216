import { memo } from 'react';
import Image from 'next/image';
import { Link } from '@appsumo/dorado-react';

import plusCheck from '~/public/plus-ready-red-check.png';
import { PLUS_PERKS_ARRAY } from '~/constants/checkout';

export default memo(function getPlusPerksList({
  refString,
}: {
  refString?: string;
}) {
  const plusLink = refString ? `/plus?ref=${refString}` : '/plus';

  return (
    <ul
      className={`mt-2${
        refString?.includes('checkout') ? ' flex flex-col gap-2' : ''
      }`}
      data-testid="plus-perks-list-ul"
    >
      {PLUS_PERKS_ARRAY.map((perk, index) => (
        <li key={index} className="flex">
          <Image
            src={plusCheck}
            alt="Check"
            height={90}
            width={80}
            className="mt-[2px] h-[18px] w-4"
          />
          <span className="ml-2">
            {!!perk.bold && (
              <span className="font-bold">{perk.bold}&nbsp;</span>
            )}
            {perk.description}
            {perk.cta && (
              <Link href={plusLink} target="_blank" styled>
                {perk.cta}
              </Link>
            )}
          </span>
        </li>
      ))}
    </ul>
  );
});
