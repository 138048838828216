import { memo, useState, useMemo, useRef, useEffect } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useWindowSize } from 'usehooks-ts';

import { Heading, Link } from '@appsumo/dorado-react';
import clsx from 'clsx';

type FounderLink = {
  title: string;
  url: string;
};

type Founder = {
  avatar: string;
  email_verified: boolean;
  first_name: string;
  is_staff: boolean;
  last_name: string;
  title: string;
  username: string;
};

type FounderPost = {
  message: string;
  title: string;
  user: Founder;
};

const FoundersProfile = memo(function FoundersProfile({
  user,
  productUrl,
}: {
  productUrl: string;
  user: Founder;
}) {
  const founderName = useMemo(
    () => [user.first_name, user.last_name].join(' ').trim() || user.username,
    [user],
  );
  const url = useMemo(() => {
    if (!productUrl) return null;

    return {
      label: productUrl
        .replace(/^(?:https?:\/\/)?(?:www\.)?/i, '')
        .split('/')[0],
      value: productUrl,
    };
  }, [productUrl]);

  return (
    <div className="flex gap-x-6 grid-in-founder">
      <div>
        <Link href={`/profile/${user.username}/`} target="_blank">
          <Image
            src={`${user.avatar}?width=120`}
            width={90}
            height={90}
            alt={user.username}
            className="h-[90px] w-[90px] rounded-full object-cover max-md:h-16 max-md:w-16"
            unoptimized
          />
        </Link>
      </div>
      <div className="flex flex-col">
        <Link href={`/profile/${user.username}/`} target="_blank">
          <Heading.H4 className="font-header font-semibold md:text-2xl">
            {founderName}
          </Heading.H4>
        </Link>
        <div className="text-sm md:text-base">{user.title}</div>
        {url && (
          <div className="text-sm md:text-base">
            <Link href={url.value} styled external target="_blank">
              {url.label}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
});

const FoundersLinks = memo(function FoundersLinks({
  links,
}: {
  links: FounderLink[];
}) {
  if (!links.length) return null;

  return (
    <div className="border-t border-t-gravel py-4">
      <h3 className="mb-4 font-header text-xl font-semibold">Helpful links</h3>
      <ul>
        {links.map((link) => (
          <li key={link.url}>
            <Link href={link.url} styled target="_blank">
              {link.title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
});

const FoundersPosts = memo(function FoundersPosts({
  posts,
  activePostIndex,
  onClick,
}: {
  posts: FounderPost[];
  activePostIndex: number;
  onClick: (index: number) => void;
}) {
  const foundersLink = (index: number) => {
    return `#founder-post-${index + 1}`;
  };

  const linkClasses = (index: number) =>
    clsx(activePostIndex === index && 'font-semibold');

  return (
    <div className="border-t border-t-gravel py-4">
      <h3 className="mb-4 font-header text-xl font-semibold">All posts</h3>
      <ul>
        {posts.map((post, index) => (
          <li key={post.title}>
            <Link
              styled
              href={foundersLink(index)}
              onClick={() => onClick(index)}
              className={linkClasses(index)}
            >
              {post.title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
});

interface RichHtmlContentProps {
  htmlContent: string;
  mobileWidth: number;
  maxMobileHeight: number;
  maxDesktopHeight: number;
  className?: string;
}

const RichHtmlContent = ({
  htmlContent,
  mobileWidth,
  maxMobileHeight,
  maxDesktopHeight,
  className = '',
}: RichHtmlContentProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [maxHeight, setMaxHeight] = useState(`${maxDesktopHeight}px`);
  const contentRef = useRef<HTMLDivElement>(null);
  const { width, height } = useWindowSize();

  useEffect(() => {
    const isMobile = width < mobileWidth;
    const calculateMaxHeight = () => {
      const maxHeight = isMobile ? maxMobileHeight : maxDesktopHeight;
      if (contentRef.current) {
        const el = contentRef.current;
        const children = el.querySelectorAll(':scope > *');
        let currentHeight = 0;

        const linesToShow = Array.from(children).reduce((acc, child) => {
          if (currentHeight < maxHeight) {
            const style = getComputedStyle(child);
            const marginTop = parseFloat(style.marginTop);
            const elementHeight =
              (child as HTMLElement).offsetHeight + marginTop;
            currentHeight += elementHeight;

            if (currentHeight < maxHeight) {
              return acc + elementHeight;
            }
          }
          return acc;
        }, 0);

        if (linesToShow < maxHeight && currentHeight < maxHeight) {
          setIsExpanded(true);
          setMaxHeight('none');
        } else {
          setMaxHeight(`${linesToShow}px`);
        }
      }
    };

    calculateMaxHeight();
  }, [width, height, maxMobileHeight, maxDesktopHeight, mobileWidth]);

  const contentStyle = {
    maxHeight: isExpanded ? 'none' : maxHeight,
    overflow: 'hidden',
  };

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      <div
        ref={contentRef}
        style={contentStyle}
        className={className}
        dangerouslySetInnerHTML={{ __html: htmlContent }}
      ></div>
      {maxHeight !== 'none' && (
        <Link styled onClick={toggleExpanded} href="javascript:void(0)">
          {isExpanded ? 'Read less' : 'Read more'}
        </Link>
      )}
    </div>
  );
};

const FoundersPost = memo(function FoundersPost({
  post,
}: {
  post: FounderPost;
}) {
  return (
    <div className="flex flex-col gap-y-6 grid-in-body">
      <h3 className="text-sm text-slate md:text-base">{post.title}</h3>
      <RichHtmlContent
        className="prose-sm mb-4 md:prose"
        mobileWidth={768}
        maxMobileHeight={200}
        maxDesktopHeight={400}
        htmlContent={post.message}
      />
    </div>
  );
});

interface FoundersProps {
  productUrl: string;
  founders: {
    links: FounderLink[];
    posts: FounderPost[];
  };
}

export const Founders = memo(function Founders({
  founders,
  productUrl,
}: FoundersProps) {
  const router = useRouter();
  const foundersRef = useRef<HTMLDivElement>(null);
  const [postIndex, setPostIndex] = useState<number>(0);
  const currentPost = useMemo(
    () => founders.posts[postIndex],
    [postIndex, founders.posts],
  );

  useEffect(() => {
    const hash = router.asPath.split('#')[1];
    if (hash?.includes('founder-post-')) {
      const postNumber = Number(hash.charAt(hash.length - 1)) || 1;

      if (postNumber >= 1 && postNumber <= founders?.posts?.length) {
        // Set index from hash Post number as index zero-based
        setPostIndex(postNumber - 1);
        // Scroll to founders section
        foundersRef.current?.scrollIntoView();
      }
    }
  }, [router.asPath, founders.posts]);

  if (!founders?.posts?.length) {
    return null;
  }

  return (
    <section
      id="from-the-founders"
      className="appsumo-breakout relative mt-16 -ml-2 bg-iceberg py-8"
      ref={foundersRef}
    >
      <div className="mx-auto max-w-7xl px-8">
        <h2 className="mb-6 font-header text-3xl font-bold">
          From the founders
        </h2>
        <div className="grid grid-cols-founders grid-rows-founders gap-x-8 gap-y-4 grid-areas-founders md:grid-cols-desktop-founders md:grid-rows-desktop-founders md:grid-areas-desktop-founders">
          <FoundersProfile user={currentPost.user} productUrl={productUrl} />
          <div className="flex flex-col gap-y-4 grid-in-links">
            <FoundersPosts
              posts={founders.posts}
              activePostIndex={postIndex}
              onClick={(index) => setPostIndex(index)}
            />
            <FoundersLinks links={founders.links} />
          </div>
          <FoundersPost post={currentPost} />
        </div>
      </div>
    </section>
  );
});
