import { useState } from 'react';

type CopyFn = (text: string) => Promise<boolean>;
type CopiedValue = string | null;

function documentCopyToClipboard(text: string): boolean {
  let copied = false;
  if (document) {
    const el = document.createElement('textarea');

    try {
      el.value = text;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';

      document.body.appendChild(el);
      const selection = document.getSelection();
      if (!selection) return false;
      const selected =
        selection.rangeCount > 0 ? selection.getRangeAt(0) : false;

      el.select();
      document.execCommand('copy');

      if (selected) {
        selection.removeAllRanges();
        selection.addRange(selected);
      }
      copied = true;
    } catch (e) {
      // failed to copy the created element
      console.warn('Failed to copy text to clipboard', e);
      copied = false;
    } finally {
      document.body.removeChild(el);
    }
  }
  return copied;
}

function useCopyToClipboard(): [CopiedValue, CopyFn] {
  const [copiedVal, setCopiedVal] = useState<CopiedValue>(null);

  const copy: CopyFn = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopiedVal(text);
      return true;
    } catch (e) {
      // navigator doesn't exist, copy hidden elem
      // This is just for local envs, as navigator only works on https/localhost
      const copied = documentCopyToClipboard(text);
      if (copied) setCopiedVal(text);
      return copied;
    }
  };

  return [copiedVal, copy];
}

export default useCopyToClipboard;
